import { ReactNode } from "react";
import Modal from "../../Modal";
import styles from "../Modals.module.css";
import Button from "../../../../components/Button/Button";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    send?: () => void;
    back: () => void;
    email: string;
    teste?: ReactNode;
    buttonClose?: boolean;
    onClickButtonClose?: () => void
}

function SuccessModal(props:IProperties): JSX.Element {

    return(
        <div className={styles.cardSuccess}>
            <Modal forwardRef={ props.reference } title="Cadastrado com sucesso!" buttonClose={props.buttonClose} onClickButtonClose={props.onClickButtonClose}>
                <p className={ `${styles.modalParagraph} ${styles.paragraphLeft}` }>Enviamos um link de confirmação de e-mail para <span className={ styles.email }>{props.email}</span>. Verifique a sua caixa de entrada, incluindo a pasta de spam, para localizar o e-mail com o link.</p>
                {/* <p className={ `${styles.modalParagraph} ${styles.paragraphLeft} ${styles.paddingParagraph}` }>Não chegou? <span className={ styles.modalUnderline } onClick={props.send}>Reenviar link</span>.</p>               */}
                <p className={ `${styles.modalParagraph} ${styles.paragraphLeft} ${styles.paragraphPaddingBottom}` }>{props.teste}</p>
                <Button placeholder="Fazer login" variant="filled-primary" onClick={props.back} />
            </Modal>
        </div>
    )
}

export default SuccessModal;