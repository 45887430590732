import { Route, Routes } from "react-router-dom";
import AdminRoutesMiddleware from "./AdminRoutesMiddleware";
import LegalCustomers from "../../pages/admin/view/LegalCustomers/LegalCustomers";
import PrivateCustomers from "../../pages/admin/view/PrivateCustomers/PrivateCustomers";
import LAdmin from "../../layouts/admin/LAdmin";

function AdminRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/admin" element={ <AdminRoutesMiddleware redirectPath="/" /> }>
                <Route element={ <LAdmin /> }>
                    <Route path="view/legal" element={ <LegalCustomers /> } />
                    <Route path="view/private" element={ <PrivateCustomers /> } />
                </Route>
            </Route>
        </Routes>
    )
}

export default AdminRoutes;