import { useContext, useEffect, useRef, useState } from "react";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
import styles from "./Sidebar.module.css";
import SidebarProfile from "../SidebarProfile/SidebarProfile";
import SidebarItem from "../SidebarItem/SidebarItem";
import { SessionContext, SessionContextType } from "../../../contexts/SessionContext/SessionContext";
import LoginModal from "../../../modules/Modal/components/Login/LoginModal";
import RecoveryPasswordModal from "../../../modules/Modal/components/RecoveryPassword/RecoveryPasswordModal";
import Auth from "../../../services/auth/Auth.service";
import RecoveryPasswordSendModal from "../../../modules/Modal/components/RecoveryPassword/RecoveryPasswordSendModal";
import OpenModal from "../../../modules/Modal/components/Login/OpenModal";
import { useLocation, useNavigate } from "react-router-dom";

interface IState {
    isCollapsed: boolean;
}

function Sidebar(): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();
    const [messageError, setMessageError] = useState(false)
    
    const [state, setState] = useState<IState>({ isCollapsed: true });
    const [loginInfo, setLoginInfo] = useState<{ email: string, password: string }>({ email: "", password: "" })
    const [recoveryEmail, setRecoveryEmail] = useState<string>("");

    const loginRef = useRef<HTMLDialogElement | null>(null);
    const openModalRef = useRef<HTMLDialogElement | null>(null);
    const forgotPwdRef = useRef<HTMLDialogElement | null>(null);
    const forgotPwdSentRef = useRef<HTMLDialogElement | null>(null);
    const recoverySentParagraphRef = useRef<HTMLParagraphElement | null>(null);

    const { session, login, logout } = useContext(SessionContext) as SessionContextType;

    const toggleVisibility = () => setState((previousState) => ({ isCollapsed: !previousState.isCollapsed }));

    const handleLogin = async (email: string, password: string) => {
        const success: boolean = await login(email, password);

        if (!success) {
            setMessageError(true)
            return;
        }
        if(success) {
            navigate('/home')
        }
    }

    const backToModalLoginFunc = () => {
        forgotPwdRef.current?.close();
        loginRef.current?.showModal();
    }

    const sendRecoveryEmail = async () => {
        await Auth.RecoveryEmail(recoveryEmail);
        recoverySentParagraphRef.current!.textContent = `Email de recuperação para ${ recoveryEmail } reenviado com sucesso!`;
        recoverySentParagraphRef.current!.style.textAlign = "center";
    }

    const handleForgotPwd = async (email: string) => {
        setRecoveryEmail(email);
        await Auth.RecoveryEmail(email);

        openModal(forgotPwdSentRef, forgotPwdRef);
    }

    const openModal = async (
        toOpen: React.MutableRefObject<HTMLDialogElement | null>,
        toClose?: React.MutableRefObject<HTMLDialogElement | null>
    ) => {
        if (toClose !== undefined) toClose.current!.close();
        toOpen.current!.showModal();
    }

    const ModalFunc = () => {
        loginRef.current?.close();
        openModalRef.current?.showModal();
    }

    const openLoginModal = () => {
        openModal(loginRef, openModalRef);
    };

    useEffect(() => {
        setState({ isCollapsed: true });
    }, [location]);

    return (
        <aside
            data-collapsed={ state.isCollapsed }
            className={`${ styles.sidebar } ${ state.isCollapsed ? styles.collapsed : styles.expanded } `}
        >
            <button onClick={ toggleVisibility }  className={ styles.toggleVisibilityButton }>
                { !state.isCollapsed ? <FaAngleLeft /> : <FaAngleRight /> }
            </button>
            {!state.isCollapsed ? (
                <SidebarProfile isCollapsed={ state.isCollapsed } onClickLogin={ () => loginRef.current?.showModal() } />
            ) : ('')}
            {session.isLogged &&
                <>
                    <hr />
                    {!state.isCollapsed ? (
                        <>
                        <div>
                            <p className={`${styles.titleGroup} ${state.isCollapsed ? styles.titleCollapsed : ''}`}>Geral</p>
                            <div className={styles.infoGroup}>
                                <SidebarItem isCollapsed={state.isCollapsed} placeholder="Home" to={'/home'} icon="home"/>
                            </div>
                        </div>
                        <div>
                            <p className={`${styles.titleGroup} ${state.isCollapsed ? styles.titleCollapsed : ''}`}>Configurações</p>
                            <div className={styles.infoGroup}>
                            { session.user!.type === 'Admin' &&
                                <>
                                    <SidebarItem isCollapsed={state.isCollapsed} placeholder="Usuários PJ" to={'/admin/view/legal'} icon="admin"/>
                                </>
                            } 
                                <SidebarItem isCollapsed={state.isCollapsed} placeholder="Alterar dados" to={'/settings/change-info'} icon="user"/>
                                <SidebarItem isCollapsed={state.isCollapsed} placeholder="Alterar senha" to={'/settings/change-password'} icon="gear"/>
                            { !(session.user!.type === 'Admin') ? (
                                <SidebarItem isCollapsed={state.isCollapsed} placeholder="Assinatura" to={'/settings/subscription'} icon="paper"/>
                                ) : ('') 
                            }
                                <SidebarItem isCollapsed={state.isCollapsed} placeholder="Sair" to={'/home'} icon="logout" click={logout}/>
                            </div>
                        </div>
                        </>
                    ) : ('')}
                </>
            }
            {!session.isLogged &&
                <>
                    <LoginModal
                        reference={ loginRef }
                        login={ () => handleLogin(loginInfo.email, loginInfo.password) }
                        email={ (e) => setLoginInfo({ ...loginInfo, email: e.currentTarget.value }) }
                        password={ (e) => setLoginInfo({ ...loginInfo, password: e.currentTarget.value }) }
                        recoverPassword={ () => openModal(forgotPwdRef, loginRef) }
                        register={ModalFunc}
                        message={messageError ? 'Erro ao fazer o login' : ''}
                    />
                    <OpenModal 
                        reference={ openModalRef } 
                        entry={openLoginModal}
                    />
                    <RecoveryPasswordModal
                        reference={ forgotPwdRef }
                        send={ (email) => handleForgotPwd(email) }
                        back={backToModalLoginFunc}
                    />
                    <RecoveryPasswordSendModal
                        forwardRef={ recoverySentParagraphRef }
                        reference={ forgotPwdSentRef }
                        email={ recoveryEmail }
                        resend={ sendRecoveryEmail }
                    />
                </>
            }
        </aside>
    )
}

export default Sidebar;