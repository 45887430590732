import TCompanySignUp from "../../@types/auth/TCompanySignUp";
import TProducerSignUp from "../../@types/auth/TProducerSignUp";
import THttpRequestError from "../../@types/http/THttpRequestError";
import API from "../API";
import URLMapping from "../URLMapping";

abstract class Auth {
    public static async Login(email: string, password: string) {
        return await API.Post<{ id: number } | THttpRequestError>(URLMapping.Auth.Login, JSON.stringify({ email, password }));
    }

    public static async SignUp(type: "Producer" | "Company", body: TProducerSignUp | TCompanySignUp) {
        switch (type) {
            case "Company":
                return await API.Post<object | THttpRequestError>(URLMapping.Auth.SignUpCompany, JSON.stringify(body));
            case "Producer":
                return await API.Post<object | THttpRequestError>(URLMapping.Auth.SignUpProducer, JSON.stringify(body));
            default:
                return {
                    statusCode: '503',
                    timestamp: new Date().toISOString()
                } as THttpRequestError;
        }
    }

    public static async RecoveryEmail(email: string) {
        return await API.Post<object | THttpRequestError>(URLMapping.Auth.RecoveryEmail, JSON.stringify({ email }));
    }

    public static async RecoveryPassword(password: string, token: string) {
        return await API.Put<object | THttpRequestError>(URLMapping.Auth.RecoveryPassword, JSON.stringify({ password }), undefined, token)
    }

    public static async EmailConfirm (token: string) {
        return API.Get<{ message: string } | THttpRequestError>(URLMapping.Auth.EmailConfirm, token);
    }
}

export default Auth;