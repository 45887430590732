import { Link } from "react-router-dom";
import styles from "./Footer.module.css";
import Logo from "../../../components/Logo/Logo";

function Footer(): JSX.Element {
    return (
        <footer className={ styles.footer }>
            <Logo />
            <div className={ styles.links }>
                <Link to={ "/terms-and-conditions" }>Termos e Condições</Link>
                <Link to={ "/privacy-policy" }>Política de Privacidade</Link>
                <Link to={ "/cookie-policy" }>Política de Cookies</Link>
                <p>© SpotSat { new Date().getFullYear() }</p>
            </div>
        </footer>
    );
}

export default Footer;