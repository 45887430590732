import { FaUserAlt } from "react-icons/fa";
import styles from "./SidebarProfile.module.css";
import { useContext } from "react";
import { SessionContext, SessionContextType } from "../../../contexts/SessionContext/SessionContext";

interface IProperties {
    isCollapsed: boolean;
    onClickLogin: () => void;
}

function SidebarProfile(properties: IProperties): JSX.Element { 
    const { session } = useContext(SessionContext) as SessionContextType;
        
    return (
        session.isLogged ? (
            <div className={ styles.profile }>
                <div className={styles.icon}>
                    <FaUserAlt />
                </div>
                { !properties.isCollapsed &&
                    <span className={styles.infoLogged}>
                        <p>{ session.user?.name }</p>
                        <small>{ session.user?.Emails[0].email }</small>
                    </span>
                }
            </div>
        ) : (
            <div className={ styles.profile }>
                <div className={ styles.icon }>
                    <FaUserAlt onClick={ properties.onClickLogin } />
                </div>
                { !properties.isCollapsed &&
                    <span className={ styles.info }>
                        <p onClick={ properties.onClickLogin }>Entrar</p>
                        <small>Navegando como anônimo</small>
                    </span>
                }
            </div>
        )
    );
}

export default SidebarProfile;