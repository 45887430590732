import { FaAngleRight } from "react-icons/fa";
import styles from "./Breadcrumb.module.css";

interface IProperties {
    head: string;
    pages: string[];
}

function Breadcrumb(properties: IProperties): JSX.Element {
    return (
        <ul className={ styles.breadcrumb }>
            <li>{ properties.head }</li>
            {properties.pages.map((page, key) => (
                <>
                    <FaAngleRight />
                    <li key={ key }>{ page }</li>
                </>
            ))}
        </ul>
    );
}

export default Breadcrumb;