import { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import styles from "./PrivateCustomers.module.css";
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaEllipsisV } from "react-icons/fa";
import StatusTag from "../../../../components/StatusTag/StatusTag";
import TStatus from "../../../../@types/TStatus";
import { GrFormClose } from "react-icons/gr";
import Users from "../../../../services/users/Users.service";
import TUser from "../../../../@types/users/TUser";
import THistory from "../../../../@types/users/THistory";
import { maskCPF } from "../../../../@utils/Masks/Masks";
import THttpRequestError from "../../../../@types/http/THttpRequestError";

function PrivateCustomers(): JSX.Element {
    const [users, setUsers] = useState<TUser[]>([]);
    const [history, setHistory] = useState<THistory[]>([]);
    const [filter, setFilter] = useState<{ initialValue: TUser[], name?: string, status?: string}>({ initialValue: [], name: undefined, status: undefined });
    const [pages, setPages] = useState<TUser[][]>([])

    const historyRef = useRef<HTMLElement | null>(null);

    const openRow = (e: React.MouseEvent<SVGElement>) => {
        const rowId: string | undefined = e.currentTarget.dataset.row;

        if (rowId === undefined) return;

        const selectedRow: HTMLTableRowElement | null = document.querySelector<HTMLTableRowElement>(`[data-id="${ rowId }"]`);
        const hiddenContent: HTMLTableRowElement | null = document.querySelector<HTMLTableRowElement>(`[data-id="${ rowId }"] + tr`);

        if (selectedRow === null || hiddenContent === null) return;

        if (hiddenContent.style.display !== "table-row") {
            selectedRow.style.fontWeight = "bold";
            hiddenContent.style.display = "table-row";
            e.currentTarget.dataset.selected = "true"
        } else {
            selectedRow.style.fontWeight = "normal";
            hiddenContent.style.display = "none";
            e.currentTarget.dataset.selected = "false"
        };
    }

    const openDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
        const dropdown: HTMLUListElement | null = document.querySelector<HTMLUListElement>(`[data-button="${ e.currentTarget.dataset.id }"]`);

        if (dropdown === null) return;

        const isOpen = dropdown.dataset.open;

        if (isOpen === "false") dropdown.dataset.open = "true";
        else dropdown.dataset.open = "false";
    }

    const openHistory = async (i: TUser) => {
        await getHistory(i.id);
        historyRef.current!.style.right = "0%";

        const nameElement = document.querySelector<HTMLParagraphElement>('[data-name]');

        if (nameElement === null) return;

        nameElement.textContent = i.name;
    }

    const closeHistory = () => historyRef.current!.style.right = "-100%";

    const getHistory = async (id: number) => {
        const fetch: THistory[] = await Users.History(id);
        setHistory(fetch);
    }

    const getUsers = async () => {
        const fetch: TUser[] | THttpRequestError = await Users.List();

        if ('statusCode' in fetch) return;

        const filteredFetch: TUser[] = fetch.filter(x => x.type === "Private Customer");
        
        setFilter({ ...filter, initialValue: filteredFetch });
        genPagination(filteredFetch);
    }

    const genPagination = (data: TUser[]) => {
        const pageSize = 10;
        const allPages: TUser[][] = [];
        
        for (let i = 0; i < data.length; i += pageSize) {
            const newPage = data.slice(i, i + pageSize);
            allPages.push(newPage);
        }

        setPages(allPages);
        setUsers(allPages[0]);
    }

    const handlePages = (action: "next" | "previous") => {
        const currentIndex = pages.indexOf(users);
        const numberOfPages = pages.length - 1;

        if (action === 'next') {
            if (currentIndex >= numberOfPages) return;
            else setUsers(pages[currentIndex + 1]);
        } else {
            if (currentIndex <= 0) return;
            else setUsers(pages[currentIndex - 1]);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        let isNameUndefined = (filter.name === undefined) || (filter.name === "");
        let isStatusUndefined = (filter.status === undefined) || (filter.status === "default");
        let initialData = filter.initialValue;

        if (isNameUndefined && isStatusUndefined) {
            if (initialData.length === 0) return;
            genPagination(initialData);  
        } else if (!isNameUndefined && isStatusUndefined) {
            let filteredData: TUser[] = initialData.filter(x =>
                x.name.toLocaleLowerCase().includes(filter.name!.toLocaleLowerCase())  
            );
            genPagination(filteredData);
        } else if (isNameUndefined && !isStatusUndefined) {
            // let filteredData: TUser[] = users.filter(x =>
            //     x.status === filter.status
            // );
            // setUsers(filteredData);
        } else if (!isNameUndefined && !isStatusUndefined) {
            // let filteredData: TUser[] = users.filter(x =>
            //     x.name.toLocaleLowerCase().includes(filter.name!.toLocaleLowerCase()) &&
            //     x.status === filter.status
            // );
            // setUsers(filteredData);
        }
    }, [filter.name, filter.status]);

    return (
        <>
            <nav className={ styles.heading }>
                <h1>Checkout</h1>
                <Breadcrumb head="Listagem" pages={ ["Usuários"] } />
            </nav>
            <section className={ styles.listagem }>
                <div className={ styles.table__filters }>
                    <label>
                        Nome
                        <input
                            type="text"
                            placeholder="E.g. John Doe"
                            onChange={ (e) => setFilter({ ...filter, name: e.target.value }) }
                        />
                    </label>
                    <label>
                        Status
                        <select
                            defaultValue="default"
                            onChange={ (e) => setFilter({ ...filter, status: (e.target.value as TStatus) }) }
                        >
                            <option value="default"></option>
                            <option value="Aprovado">Aprovado</option>
                            <option value="Pendente">Pendente</option>
                            <option value="Cancelado">Cancelado</option>
                        </select>
                    </label>
                </div>
                <div className={ styles.table__wrapper }>
                    <table className={ styles.table }>
                        <colgroup>
                            <col span={ 1 } className={ styles.col__expand } />
                            <col span={ 1 } className={ styles.col__id } />
                            <col span={ 1 } className={ styles.col__name } />
                            <col span={ 1 } className={ styles.col__actions } />
                        </colgroup>
                        <thead className={ styles.table__head }>
                            <tr>
                                <th>&#10240;</th>
                                <th>Id</th>
                                <th>Nome</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody className={ styles.table__body }>
                            {users.map((i, k) => (
                                <Fragment key={ k }>
                                    <tr className={ styles.table__row } data-id={ i.id }>
                                        <td><FaAngleDown onClick={ (e) => openRow(e) } data-row={ i.id } data-selected={ false } /></td>
                                        <td>{ i.id }</td>
                                        <td id="nome">{ i.name }</td>
                                        <td>
                                            <button className={ styles.dropdown } data-id={ i.id } onClick={ (e) => openDropdown(e) }>
                                                <FaEllipsisV />
                                                <ul className={ styles.dropdown__links } data-button={ i.id } data-open={ false }>
                                                    <li className={ styles.dropdown__link } onClick={ () => openHistory(i) }>Histórico</li>
                                                </ul>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr className={ styles.table__row__hidden }>
                                        <td colSpan={ 4 }>
                                            <div className={ styles.row__hidden__content }>
                                                <h3>Dados complementares</h3>
                                                <div className={ styles.hidden__content__user__info }>
                                                    <div className={ styles.user__address__info }>
                                                        <p>
                                                            <span>CEP</span>
                                                            <span>{ i.Addresses[0].zip_code }</span>
                                                        </p>
                                                        <p>
                                                            <span>Cidade</span>
                                                            <span>{ i.Addresses[0].city }</span>
                                                        </p>
                                                        <p>
                                                            <span>Localização</span>
                                                            <span>{ i.Addresses[0].location }</span>
                                                        </p>
                                                        <p>
                                                            <span>Número</span>
                                                            <span>{ i.Addresses[0].number }</span>
                                                        </p>
                                                        <p>
                                                            <span>Bairro</span>
                                                            <span>{ i.Addresses[0].neighborhood }</span>
                                                        </p>
                                                        <p>
                                                            <span>Complemento</span>
                                                            <span>{ i.Addresses[0].complement }</span>
                                                        </p>
                                                    </div>
                                                    <div className={ styles.user__general__info }>
                                                        <p>
                                                            <span>Nome</span>
                                                            <span>{ i.name }</span>
                                                        </p>
                                                        <p>
                                                            <span>CPF</span>
                                                            <span>{ maskCPF(i.Document.document) }</span>
                                                        </p>
                                                        <p>
                                                            <span>Telefone</span>
                                                            <span>{ `${ i.PhoneNumbers[0].country_code } (${ i.PhoneNumbers[0].area_code }) ${ i.PhoneNumbers[0].number }` }</span>
                                                        </p>
                                                        <p>
                                                            <span>Email</span>
                                                            <span>{ i.Emails[0].email }</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <h3>Contrato</h3>
                                                <div className={ styles.hidden__content__contract__info }>
                                                    <div className={ styles.contract__general__info }>
                                                        <p>
                                                            <span>Início</span>
                                                            <span>{ new Date().toString() }</span>
                                                        </p>
                                                        <p>
                                                            <span>Fim</span>
                                                            <span>{ new Date().toString() }</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment >
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className={ styles.table__pagination }>
                    <button onClick={ () => handlePages("previous") }><FaAngleLeft /> Anterior</button>
                    <button onClick={ () => handlePages("next") }>Próximo <FaAngleRight /></button>
                </div>
            </section>
            <aside ref={ historyRef } className={ styles.history }>
                <span className={ styles.history__title }>
                    <GrFormClose onClick={ closeHistory } />
                    <h3>Histórico</h3>
                </span>
                <span className={ styles.history__user__name }>
                    Usuário: <p data-name></p>
                </span>
                <ul className={ styles.history__data }>
                    {history.map((i, k) => (
                        <li key={ k } data-type={ 'Success' }>
                            <p>{ i.action }</p>
                            <p>{ i.when }</p>
                        </li>
                    ))}
                </ul>
            </aside>
        </>
    );
}

export default PrivateCustomers;