import Button from "../../../components/Button/Button";
import Logo from "../../../components/Logo/Logo";
import styles from "./Landing.module.css";
import image_landing_foreground from "../../../assets/landing_background.jpg";
import { useEffect, useReducer, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import about_1 from "../../../assets/area.png"
import about_2 from "../../../assets/prodes_area_indigena_600dpi.png";
import about_3 from "../../../assets/prodes_unidades_conservacao_600DPI.png";
import spot_sat_logo_color from "../../../assets/spot_sat_logo_color.svg";
import radar from "../../../assets/radar.png";
import desmatamento_1 from "../../../assets/desmatamento_1.png";
import desmatamento_2 from "../../../assets/desmatamento_2.png";
import desmatamento_3 from "../../../assets/desmatamento_3.png";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import Auth from "../../../services/auth/Auth.service";
import ConfirmAccessLoginModal from "../../../modules/Modal/components/Login/ConfirmAccessLoginModal";
import NewPasswordModal from "../../../modules/Modal/components/RecoveryPassword/NewPasswordModal";
import RecoveryPasswordModal from "../../../modules/Modal/components/RecoveryPassword/RecoveryPasswordModal";
import RecoverPasswordLoginModal from "../../../modules/Modal/components/RecoveryPassword/RecoveryPasswordLoginModal";
import OpenModal from "../../../modules/Modal/components/Login/OpenModal";
import LoginModal from "../../../modules/Modal/components/Login/LoginModal";
import { SessionContext, SessionContextType } from "../../../contexts/SessionContext/SessionContext";
import RecoveryPasswordSendModal from "../../../modules/Modal/components/RecoveryPassword/RecoveryPasswordSendModal";
import SwitchPlanSendModal from "../../../modules/Modal/components/SwitchPlan/SwitchPlanSendModal";
import bannerWeb from '../../../assets/bannerWeb.jpg'
import bannerTablet from '../../../assets/bannerTablet.jpg'
import bannerCelular from '../../../assets/bannerCelular.jpg'

interface IState {
    current: number;
    next: number;
    previous: number;
    images: string[];
    titles?: string[];
    texts?: string[];
}

type TSlideAction = { type: 'previous' | "next" };

function slideReducer(state: IState, action: TSlideAction) {
    let newState;

    switch (action.type) {
        case "next":
            newState = {
                ...state,
                current: state.current >= state.images.length - 1 ? 0 : state.current + 1,
                next: state.next >= state.images.length - 1 ? 0 : state.next + 1
            };

            return {
                ...newState,
                previous: newState.current === 0 ? state.images.length - 1 : newState.current - 1
            };
        case "previous":
            newState = {
                ...state,
                current: state.current === 0 ? state.images.length - 1 : state.current - 1,
                next: state.next === 0 ? state.images.length - 1 : state.next - 1
            };

            return {
                ...newState,
                previous: newState.current === 0 ? state.images.length - 1 : newState.current - 1
            };
        default:
            return state;
    }
}

function Landing(): JSX.Element {
    const [slideAbout, dispatchAbout] = useReducer(slideReducer, {
        current: 0, next: 1, previous: 0,
        images: [ about_1, about_2, about_3 ],
        titles: [ "Terras Indígenas e Unidades de conservação", "Terras Indígenas", "Unidades de conservação"],
        texts: [
            "As Terras Indígenas na Amazônia Legal ocupam área total de 115 milhões de hectares. As Unidades de Conservação contam com área de aproximadamente 123 milhões de hectares. Essas áreas são protegidas por lei, contra desmatamento.",
            "Somente das áreas demarcadas como Terra Indígena, o acumulado desde o início do monitoramento até o ano de 2007, foram registrados desmatamentos na ordem de um milhão e trezentos mil hectares. De 2008 até 2022, foi registrado desmatamento de aproximadamente 394063,26 hectares.",
            "Nas Unidades de Conservação foi registrado um acumulado de aproximadamente três milhões e setecentos mil hectares em áreas de desmatamento. De 2008 em diante foi registrado área de 8429,87 hectares desmatados."
        ]
    });
    const [slideAreas, dispatchAreas] = useReducer(slideReducer, { current: 0, next: 1, previous: 0, images: [ desmatamento_1, desmatamento_2, desmatamento_3 ] });
    const [loginInfo, setLoginInfo] = useState<{ email: string, password: string }>({ email: "", password: "" })

    const navigate = useNavigate();
    const [messageError, setMessageError] = useState(false)

    const openModalRef = useRef<HTMLDialogElement>(null);
    const loginModal = useRef<HTMLDialogElement>(null);
    const loginRef = useRef<HTMLDialogElement | null>(null);
    const emailConfirmRef = useRef<HTMLDialogElement>(null);
    const newPwdRef = useRef<HTMLDialogElement>(null)
    const recoveryPwdConfirmation = useRef<HTMLDialogElement>(null);
    const forgotPwdRef = useRef<HTMLDialogElement | null>(null);
    const forgotPwdSentRef = useRef<HTMLDialogElement | null>(null);
    const recoverySentParagraphRef = useRef<HTMLParagraphElement | null>(null);
    const confirmChangeRef = useRef<HTMLDialogElement>(null);
    const location = useLocation();

    const { session, login } = useContext(SessionContext) as SessionContextType;
    const [recoveryEmail, setRecoveryEmail] = useState<string>("");

    const handleLogin = async (email: string, password: string) => {
        const success: boolean = await login(email, password);

        if (!success) {
            setMessageError(true)
            return;
        }
        if(success) {
            navigate('/home')
        }
    }

    const sendRecoveryEmail = async () => {
        await Auth.RecoveryEmail(recoveryEmail);
        recoverySentParagraphRef.current!.textContent = `Email de recuperação para ${ recoveryEmail } reenviado com sucesso!`;
        recoverySentParagraphRef.current!.style.textAlign = "center";
    }

    const handleForgotPwd = async (email: string) => {
        setRecoveryEmail(email);
        await Auth.RecoveryEmail(email);

        openModal(forgotPwdSentRef, forgotPwdRef);
    }

    const backToModalLoginFunc = () => {
        forgotPwdRef.current?.close();
        loginRef.current?.showModal();
    }

    const OpenToLoginFunc = () => {
        recoveryPwdConfirmation.current?.close();
        loginRef.current?.showModal();
    }

    const OpenToLoginConfirmEmailFunc = () => {
        emailConfirmRef.current?.close();
        loginRef.current?.showModal();
    }   

    const openModal = async (
        toOpen: React.MutableRefObject<HTMLDialogElement | null>,
        toClose?: React.MutableRefObject<HTMLDialogElement | null>
    ) => {
        if (toClose !== undefined) toClose.current!.close();
        toOpen.current!.showModal();
    }

    const ModalFunc = () => {
        loginModal.current?.close();
        loginRef.current?.close();
        openModalRef.current?.showModal();
    }

    const ModalLogin = () => {
        openModal(loginRef, openModalRef);
    };

    const toCompany = () => window.location.href = "/signup/company";

    const checkForToken = async () => {
        const urlSearchString: string = window.location.search;
        const params: URLSearchParams = new URLSearchParams(urlSearchString);

        const token: string | null = params.get('token');
        const currentURL: string = location.pathname;

        if (token === null) return;

        if (currentURL === '/email-confirm') {
            const confirm = await Auth.EmailConfirm(token);

            if ('statusCode' in confirm) return;

            emailConfirmRef.current!.showModal();
        } else if (currentURL === '/recovery-password') {
            newPwdRef.current!.showModal();    
        }

        return;
    }

    const changePwd = async (password: string) => {
        const urlSearchString: string = window.location.search;
        const params: URLSearchParams = new URLSearchParams(urlSearchString);

        const token: string | null = params.get('token');

        if (token === null) return;

        const change = await Auth.RecoveryPassword(password, token);

        if ('statusCode' in change) {
            confirmChangeRef.current!.showModal();
            return;
        };

        newPwdRef.current!.close();
        recoveryPwdConfirmation.current!.showModal();
    }

    useEffect(() => {
        checkForToken();

        const slideInterval = setInterval(() => {
            dispatchAbout({ type: "next" });
            dispatchAreas({ type: "next" });
        }, 5000)

        return () => {
            clearInterval(slideInterval);
        }
    }, []);

    return (
        <>
            <section className={`${ styles.sctHero } ${ styles.container }`}>
                <div className={ styles.sctHero__content }>
                    <div>
                        <Logo />
                        <div className={`${ styles.sct__content } ${ styles.sctHero__content__info }`}>
                            <h1>Desvendando os segredos da Amazônia!</h1>
                            <p className={styles.marginVertical}>
                                A Amazônia é uma das preciosidades naturais do nosso planeta, abrigando uma rica biodiversidade e desempenhando um 
                                papel crucial na regulação do clima global. No entanto, infelizmente, ela enfrenta sérios desafios, como o desmatamento 
                                ilegal, que ameaça sua sobrevivência. Como parte da solução para esse problema urgente, apresentamos com orgulho a 
                                plataforma SpotGreen, uma ferramenta revolucionária para monitoramento da Amazônia!
                            </p>   
                            <Button placeholder="Cadastrar-se" variant="filled-accent" onClick={ ModalFunc } />
                        </div>
                    </div>
                    <div>
                        <div className={ styles.sctHero__illustration }>
                            <svg id={ styles.sctHero__illustration__background } width="668" height="569" viewBox="0 0 668 569" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_566_764)">
                                    <path d="M125.444 419.2C34.9562 316.404 -32.0731 170.896 60.2431 69.8495C150.158 -28.5683 263.523 114.364 394.103 103.527C493.64 95.2661 598.542 -44.7686 643.909 47.1274C678.507 117.211 588.361 158.248 575.566 235.801C561.873 318.793 615.088 374.029 582.636 451.255C511.374 620.832 245.84 555.974 125.444 419.2Z" fill="#161616"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_566_764" x="0" y="0" width="667.763" height="568.907" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="8"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_566_764"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_566_764" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                            <svg id={ styles.sctHero__illustration__foreground } width="668" height="569" viewBox="0 0 668 569" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_566_764)">
                                    <path d="M125.444 419.2C34.9562 316.404 -32.0731 170.896 60.2431 69.8495C150.158 -28.5683 263.523 114.364 394.103 103.527C493.64 95.2661 598.542 -44.7686 643.909 47.1274C678.507 117.211 588.361 158.248 575.566 235.801C561.873 318.793 615.088 374.029 582.636 451.255C511.374 620.832 245.84 555.974 125.444 419.2Z" fill="url(#pattern1)"/>
                                </g>
                                <defs>
                                    <pattern id="pattern1" patternUnits="userSpaceOnUse" width={ 1000 } height={ 600 }>
                                        <image id={ styles.sctHero__illustration__foreground__image } href={ image_landing_foreground } x="0" y="0" width={ 1000 } height={ 600 } />
                                    </pattern>
                                    <filter id="filter0_d_566_764" x="0" y="0" width="667.763" height="568.907" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                        <feOffset/>
                                        <feGaussianBlur stdDeviation="8"/>
                                        <feComposite in2="hardAlpha" operator="out"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_566_764"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_566_764" result="shape"/>
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                
            </section>
            <section className={`${ styles.sct } ${ styles.sctAbout }`}>
                <div className={ styles.sctAbout__illustration }>
                    {slideAbout.current === 0 ? (
                        <div className={ styles.sctAbout__illustration__image } style={{ ["--_url" as any]: "url('" + about_1 + "')" }}>
                            <button onClick={ () => dispatchAbout({ type: "previous" }) }><FaAngleLeft /></button>
                            <button onClick={ () => dispatchAbout({ type: "next" }) }><FaAngleRight /></button>
                        </div>
                    ) : slideAbout.current === 1 ?  (
                        <>
                            <div className={ styles.sctAbout__illustration__image } style={{ ["--_url" as any]: "url('" + about_2 + "')" }}>
                                <button onClick={ () => dispatchAbout({ type: "previous" }) }><FaAngleLeft /></button>
                                <button onClick={ () => dispatchAbout({ type: "next" }) }><FaAngleRight /></button>
                            </div>
                        </>
                    ) : slideAbout.current === 2 ? (
                        <>
                        <div className={ styles.sctAbout__illustration__image } style={{ ["--_url" as any]: "url('" + about_3 + "')" }}>
                            <button onClick={ () => dispatchAbout({ type: "previous" }) }><FaAngleLeft /></button>  
                            <button onClick={ () => dispatchAbout({ type: "next" }) }><FaAngleRight /></button>
                        </div>
                        </>
                    ) : ('')}
                </div>
                <div className={`${ styles.sct__content } ${ styles.sctAbout__content }`}>
                    <h2>{ slideAbout.titles && slideAbout.titles[slideAbout.current] }</h2>
                    <p>{ slideAbout.texts && slideAbout.texts[slideAbout.current] }</p>
                </div>
            </section>
            <section className={ styles.sctFeatures }>
                <div className={ styles.sctFeatures__features }>
                    <div className={ styles.sctFeatures__feature }>
                        <svg className={styles.svgStyle} viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M140 95V60C140 58.6739 139.473 57.4021 138.536 56.4645C137.598 55.5268 136.326 55 135 55H105V25C105 23.6739 104.473 22.4021 103.536 21.4645C102.598 20.5268 101.326 20 100 20H25C23.6739 20 22.4021 20.5268 21.4645 21.4645C20.5268 22.4021 20 23.6739 20 25V65V100C20 101.326 20.5268 102.598 21.4645 103.536C22.4021 104.473 23.6739 105 25 105H55V135C55 136.326 55.5268 137.598 56.4645 138.536C57.4021 139.473 58.6739 140 60 140H135C136.326 140 137.598 139.473 138.536 138.536C139.473 137.598 140 136.326 140 135V95ZM97.0687 130L30 62.9313V37.0688L122.931 130H97.0687ZM37.0688 30H62.9313L130 97.0687V122.931L37.0688 30ZM130 82.9313L112.069 65H130V82.9313ZM95 47.9313L77.0687 30H95V47.9313ZM30 77.0687L47.9313 95H30V77.0687ZM65 112.069L82.9313 130H65V112.069Z" fill="#8E97CC"/>
                        </svg>
                        <p>Monitoramento de área pré-definida</p>
                    </div>
                    <div className={ `${styles.sctFeatures__feature} ${styles.marginRight}` }>
                        <img src={radar} className={styles.imageRadar} alt=""/>
                        <p>Visualização por satélite e radar</p>
                    </div>
                </div>
            </section>
            <section className={`${ styles.sct } ${styles.sctAreaContainer} ${ styles.sctArea }`}>
                <div className={`${ styles.sct__content } ${ styles.sctArea__content }`}>
                    <h3>Desmatamento rastreado pelo algoritmo</h3>
                    <p className={styles.marginVertical}>
                        Em agosto de 2022, um alerta automático foi emitido para uma área específica, o que levou ao início do monitoramento e acompanhamento rigoroso. 
                        Através da imagem apresentada, podemos observar claramente os impactos devastadores do desmatamento que ocorreu, representados pela linha vermelha. 
                        Preocupados com a preservação da Amazônia e buscando combater esse problema recorrente, estamos oferecendo a oportunidade de acompanhar mais áreas 
                        que enfrentam situações semelhantes. Se você deseja contribuir para a proteção da maior floresta tropical do mundo, cadastre-se em nossa plataforma 
                        agora mesmo! Ao selecionar o botão abaixo, você poderá se juntar a nós nessa importante missão de conservação ambiental.
                    </p>
                    <Button placeholder="Cadastrar-se" variant="filled-primary" onClick={ ModalFunc } />
                </div>
                <div className={ styles.sctArea__illustration }>
                    <button onClick={ () => dispatchAreas({ type: "previous" }) }><FaAngleLeft /></button>
                    <button onClick={ () => dispatchAreas({ type: "next" }) }><FaAngleRight /></button>
                    <div className={ styles.sctArea__illustration__slide } data-previous={ slideAreas.previous === 0 } data-current={ slideAreas.current === 0 } data-next={ slideAreas.next === 0 } style={{ ["--_url" as any]: "url('" + desmatamento_3 + "')" }}></div>
                    <div className={ styles.sctArea__illustration__slide } data-previous={ slideAreas.previous === 1 } data-current={ slideAreas.current === 1 } data-next={ slideAreas.next === 1 } style={{ ["--_url" as any]: "url('" + desmatamento_1 + "')" }}>
                    </div>
                    <div className={ styles.sctArea__illustration__slide } data-previous={ slideAreas.previous === 2 } data-current={ slideAreas.current === 2 } data-next={ slideAreas.next === 2 } style={{ ["--_url" as any]: "url('" + desmatamento_2 + "')" }}></div>
                </div>
            </section>
            <section className={ styles.sctBusiness }>
                <div className={ styles.sctBusiness__title }>
                    <hr />
                    <h2>SpotGreen <span>Business</span></h2>
                    <hr />
                </div>
                <div className={`${ styles.sct } ${styles.sctAreaMargin}`}>
                    <div className={`${ styles.sct__content } ${ styles.sctBusiness__content }`}>
                        <h3>Por que contratar?</h3>
                        <p className={styles.marginVertical}>
                            Com o SpotGreen Business, oferecemos a você a possibilidade de monitorar múltiplas áreas na Amazônia, ampliando sua capacidade de acompanhar a preservação e 
                            combater problemas como o desmatamento. Além disso, nossa plataforma garante um suporte personalizado, onde nossa equipe estará disponível para auxiliá-lo 
                            em todas as etapas do processo. Com a emissão de relatórios completos e detalhados, você terá acesso a informações precisas e atualizadas sobre as áreas 
                            que monitora. Esses relatórios fornecem insights valiosos para que você possa tomar decisões estratégicas e tomar medidas mais efetivas na conservação 
                            ambiental. Ao se cadastrar no SpotGreen Business, você estará contribuindo diretamente para a proteção da Amazônia, uma das regiões mais importantes do 
                            planeta em termos de biodiversidade e equilíbrio ecológico. Junte-se a nós nessa jornada crucial em prol de um futuro sustentável para a Amazônia e para 
                            todo o nosso planeta! Clique no botão abaixo para se cadastrar e fazer parte dessa causa.
                        </p>
                        <Button placeholder="Obter agora mesmo!" variant="filled-accent" onClick={ toCompany }/>
                    </div>
                    <div className={ styles.sctBusiness__illustration }>
                        <div className={styles.sctBusiness__illustration__card__top}>
                            <div className={ styles.sctBusiness__illustration__card }>
                                <svg width="150" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M39 15H21C19.4087 15 17.8826 15.6321 16.7574 16.7574C15.6321 17.8826 15 19.4087 15 21V39C15 40.5913 15.6321 42.1174 16.7574 43.2426C17.8826 44.3679 19.4087 45 21 45H39C40.5913 45 42.1174 44.3679 43.2426 43.2426C44.3679 42.1174 45 40.5913 45 39V21C45 19.4087 44.3679 17.8826 43.2426 16.7574C42.1174 15.6321 40.5913 15 39 15ZM39 39H21V21H39V39ZM75 15H57C55.4087 15 53.8826 15.6321 52.7574 16.7574C51.6321 17.8826 51 19.4087 51 21V39C51 40.5913 51.6321 42.1174 52.7574 43.2426C53.8826 44.3679 55.4087 45 57 45H75C76.5913 45 78.1174 44.3679 79.2426 43.2426C80.3679 42.1174 81 40.5913 81 39V21C81 19.4087 80.3679 17.8826 79.2426 16.7574C78.1174 15.6321 76.5913 15 75 15ZM75 39H57V21H75V39ZM39 51H21C19.4087 51 17.8826 51.6321 16.7574 52.7574C15.6321 53.8826 15 55.4087 15 57V75C15 76.5913 15.6321 78.1174 16.7574 79.2426C17.8826 80.3679 19.4087 81 21 81H39C40.5913 81 42.1174 80.3679 43.2426 79.2426C44.3679 78.1174 45 76.5913 45 75V57C45 55.4087 44.3679 53.8826 43.2426 52.7574C42.1174 51.6321 40.5913 51 39 51ZM39 75H21V57H39V75ZM75 51H57C55.4087 51 53.8826 51.6321 52.7574 52.7574C51.6321 53.8826 51 55.4087 51 57V75C51 76.5913 51.6321 78.1174 52.7574 79.2426C53.8826 80.3679 55.4087 81 57 81H75C76.5913 81 78.1174 80.3679 79.2426 79.2426C80.3679 78.1174 81 76.5913 81 75V57C81 55.4087 80.3679 53.8826 79.2426 52.7574C78.1174 51.6321 76.5913 51 75 51ZM75 75H57V57H75V75Z" fill="#8E97CC"/>
                                </svg>
                                <p>Monitoramento de inúmeras áreas</p>
                            </div>
                            <div className={ styles.sctBusiness__illustration__card }>
                                <svg width="100" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M76.2088 23.4975C72.62 19.8727 68.3515 16.9913 63.6477 15.0184C58.9438 13.0455 53.897 12.0198 48.7962 12H48.5C38.1566 12 28.2368 16.1089 20.9228 23.4228C13.6089 30.7368 9.5 40.6566 9.5 51V72C9.5 74.3869 10.4482 76.6761 12.136 78.364C13.8239 80.0518 16.1131 81 18.5 81H24.5C26.8869 81 29.1761 80.0518 30.864 78.364C32.5518 76.6761 33.5 74.3869 33.5 72V57C33.5 54.6131 32.5518 52.3239 30.864 50.636C29.1761 48.9482 26.8869 48 24.5 48H15.635C16.3835 39.801 20.1697 32.1779 26.2504 26.6273C32.331 21.0767 40.2669 17.9996 48.5 18H48.7513C56.9501 18.0346 64.8403 21.1301 70.8755 26.6797C76.9107 32.2293 80.6556 39.8329 81.3763 48H72.5C70.1131 48 67.8239 48.9482 66.136 50.636C64.4482 52.3239 63.5 54.6131 63.5 57V72C63.5 74.3869 64.4482 76.6761 66.136 78.364C67.8239 80.0518 70.1131 81 72.5 81H78.5C80.887 81 83.1761 80.0518 84.864 78.364C86.5518 76.6761 87.5 74.3869 87.5 72V51C87.5195 45.8984 86.5322 40.8431 84.5947 36.1237C82.6571 31.4043 79.8074 27.1136 76.2088 23.4975ZM24.5 54C25.2957 54 26.0587 54.3161 26.6213 54.8787C27.1839 55.4413 27.5 56.2043 27.5 57V72C27.5 72.7957 27.1839 73.5587 26.6213 74.1213C26.0587 74.6839 25.2957 75 24.5 75H18.5C17.7044 75 16.9413 74.6839 16.3787 74.1213C15.8161 73.5587 15.5 72.7957 15.5 72V54H24.5ZM81.5 72C81.5 72.7957 81.1839 73.5587 80.6213 74.1213C80.0587 74.6839 79.2957 75 78.5 75H72.5C71.7044 75 70.9413 74.6839 70.3787 74.1213C69.8161 73.5587 69.5 72.7957 69.5 72V57C69.5 56.2043 69.8161 55.4413 70.3787 54.8787C70.9413 54.3161 71.7044 54 72.5 54H81.5V72Z" fill="#8E97CC"/>
                                </svg>
                                <p>Suporte personalizado</p>
                            </div>
                        </div>
                        <div className={ styles.sctBusiness__illustration__card }>
                            <svg width="150" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M63 56.9998C63 57.7955 62.6839 58.5585 62.1213 59.1211C61.5587 59.6838 60.7957 59.9998 60 59.9998H36C35.2044 59.9998 34.4413 59.6838 33.8787 59.1211C33.3161 58.5585 33 57.7955 33 56.9998C33 56.2042 33.3161 55.4411 33.8787 54.8785C34.4413 54.3159 35.2044 53.9998 36 53.9998H60C60.7957 53.9998 61.5587 54.3159 62.1213 54.8785C62.6839 55.4411 63 56.2042 63 56.9998ZM60 41.9998H36C35.2044 41.9998 34.4413 42.3159 33.8787 42.8785C33.3161 43.4411 33 44.2042 33 44.9998C33 45.7955 33.3161 46.5585 33.8787 47.1211C34.4413 47.6838 35.2044 47.9998 36 47.9998H60C60.7957 47.9998 61.5587 47.6838 62.1213 47.1211C62.6839 46.5585 63 45.7955 63 44.9998C63 44.2042 62.6839 43.4411 62.1213 42.8785C61.5587 42.3159 60.7957 41.9998 60 41.9998ZM81 17.9998V80.9998C81 82.5911 80.3679 84.1172 79.2426 85.2425C78.1174 86.3677 76.5913 86.9998 75 86.9998H21C19.4087 86.9998 17.8826 86.3677 16.7574 85.2425C15.6321 84.1172 15 82.5911 15 80.9998V17.9998C15 16.4085 15.6321 14.8824 16.7574 13.7572C17.8826 12.632 19.4087 11.9998 21 11.9998H34.5975C36.2831 10.1125 38.3484 8.60254 40.658 7.56867C42.9676 6.53479 45.4695 6.00037 48 6.00037C50.5305 6.00037 53.0324 6.53479 55.342 7.56867C57.6516 8.60254 59.7169 10.1125 61.4025 11.9998H75C76.5913 11.9998 78.1174 12.632 79.2426 13.7572C80.3679 14.8824 81 16.4085 81 17.9998ZM36 23.9998H60C60 20.8172 58.7357 17.765 56.4853 15.5145C54.2348 13.2641 51.1826 11.9998 48 11.9998C44.8174 11.9998 41.7652 13.2641 39.5147 15.5145C37.2643 17.765 36 20.8172 36 23.9998ZM75 17.9998H64.9688C65.6512 19.9266 65.9999 21.9557 66 23.9998V26.9998C66 27.7955 65.6839 28.5585 65.1213 29.1211C64.5587 29.6838 63.7957 29.9998 63 29.9998H33C32.2044 29.9998 31.4413 29.6838 30.8787 29.1211C30.3161 28.5585 30 27.7955 30 26.9998V23.9998C30.0001 21.9557 30.3488 19.9266 31.0312 17.9998H21V80.9998H75V17.9998Z" fill="#8E97CC"/>
                            </svg>
                            <p>Emissão de relatórios completos</p>
                        </div>
                    </div>
                </div>
                <hr className={styles.sctBusiness__content}/>
                <div className={styles.divBanner}>
                    <img src={bannerWeb} className={`${styles.bannerImage} ${styles.webBanner}`}/>
                    <img src={bannerTablet} className={`${styles.bannerImage} ${styles.tabletBanner}`}/>
                    <img src={bannerCelular} className={`${styles.bannerImage} ${styles.mobileBanner}`}/>
                </div>
            </section>
            <section className={`${ styles.sct } ${ styles.sctSpotsat }`}>
                <div className={ styles.sctSpotsat__illustration }>
                    <img src={spot_sat_logo_color} className={styles.imageSpotsat} alt=""/>
                </div>
                <div className={`${ styles.sct__content } ${ styles.sctSpotsat__content }`}>
                    <h2>SpotSat</h2>
                    <p>A Spotsat é uma empresa de tecnologia dedicada ao agronegócio e à preservação do meio ambiente. Do espaço, enxergamos um horizonte mais promissor para as futuras gerações!</p>
                </div>
            </section>
            {!session.isLogged && 
                <>
                    <ConfirmAccessLoginModal reference={ emailConfirmRef } login={OpenToLoginConfirmEmailFunc}/>
                    <NewPasswordModal reference={ newPwdRef } change={ (password) => changePwd(password) } />
                    <RecoverPasswordLoginModal reference={ recoveryPwdConfirmation } login={OpenToLoginFunc} />
                    <RecoveryPasswordModal
                        reference={ forgotPwdRef }
                        send={ (email) => handleForgotPwd(email) }
                        back={backToModalLoginFunc}
                    />
                    <RecoveryPasswordSendModal
                        forwardRef={ recoverySentParagraphRef }
                        reference={ forgotPwdSentRef }
                        email={ recoveryEmail }
                        resend={ sendRecoveryEmail }
                    />
                    <OpenModal reference={ openModalRef } entry={ModalLogin}/>
                    <LoginModal
                        reference={ loginRef }
                        login={ () => handleLogin(loginInfo.email, loginInfo.password) }
                        email={ (e) => setLoginInfo({ ...loginInfo, email: e.currentTarget.value }) }
                        password={ (e) => setLoginInfo({ ...loginInfo, password: e.currentTarget.value }) }
                        recoverPassword={ () => openModal(forgotPwdRef, loginRef) }
                        register={ModalFunc}
                        message={messageError ? 'Erro ao fazer o login' : ''}
                    />
                <SwitchPlanSendModal
                    reference={confirmChangeRef}
                    title={"Erro"}
                    text={"Erro ao cadastrar a nova senha."}
                    />
                </>
            }
        </>
    );
}

export default Landing;