import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "../Unsubscribe/Unsubscribe.module.css";

interface IProperties {
    forwardRef: React.RefObject<HTMLDialogElement>;
    action: () => void;
}

function ToMonitorModal(props: IProperties): JSX.Element {
    return (
        <Modal forwardRef={props.forwardRef} title="Nos ajude a monitorar!">
            <p className={styles.paragraphCenter}>Essa área não está contemplada no seu contrato. Nos ajude a monitorar essa área!</p>
            <div className={styles.containerButtonToMonitor}>
                <Button onClick={() => props.forwardRef.current?.close()} placeholder="Voltar" variant="empty-primary" />
                <Button onClick={props.action} placeholder="Monitorar" variant="filled-primary" />
            </div>
        </Modal>
    )
}

export default ToMonitorModal;