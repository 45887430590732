import React, { useEffect, useRef, useState } from 'react';
import Navigation from '../../../../layouts/components/Navigation/Navigation';
import styles from './Producer.module.css';
import zipCodeSearch from '../../../../services/apis';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import TAddress from '../../../../@types/users/TAddress_DEPRECATED';
import { maskCEP, maskCPF, maskPhone } from '../../../../@utils/Masks/Masks';
import { validateCEP, validateCPF, validateEmail, validatePassword } from '../../../../@utils/Validation/Validation';
import Auth from '../../../../services/auth/Auth.service';
import TProducerSignUp from '../../../../@types/auth/TProducerSignUp';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../layouts/components/Footer/Footer';
import ErrorModal from '../../../../modules/Modal/components/Signup/ErrorModal';
import SuccessModal from '../../../../modules/Modal/components/Signup/SuccessModal';

interface IData {
    name: string,
    address: string,
    addressNumber: string,
    complement: string,
    district: string,
    city: string,
    password: string,
    email: string
}
const Producer = () => {

    const navigate = useNavigate();

    const [info, setInfo] = useState<IData>({
        name: "",
        address: "",
        addressNumber: "",
        complement: "",
        district: "",
        city: "",
        password: "",
        email: ""
    });
    const [document, setDocument] = useState('');
    const [contact, setContact] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [cep, setCep] = useState<TAddress>({
        bairro: "",
        localidade: "",
        logradouro: "",
        uf: "",
    });
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [cpfValid, setCpfValid] =useState(true);
    const [cepValid, setCepValid] = useState(true);

    function handleEmailChange(event:any) {
        const email = event.target.value;
        const validEmail = validateEmail(email);
        if (validEmail === true) {
            setInfo({...info, email: email});
            setEmailValid(true);
        } else {
            setEmailValid(false);
        }
    };

    function handlePasswordChange(event:any) {
        const password = event.target.value;
        const validPassword = validatePassword(password);
        if (validPassword) {
            setInfo({...info, password: password});
            setPasswordValid(true);
        } else {
            setPasswordValid(false);
        }
    }

    function handleDocumentChange(event:any) {
        const document = event.target.value;
        const isValid = validateCPF(document);
        // console.log(isValid);
        const removeCpfMask = document.replace(/\D/g, "");
        if (isValid === true) {
            setDocument(removeCpfMask);
            setCpfValid(true);
        } else {
            setCpfValid(false);
        }
    }

    const [numberError, setNumberError] = useState(false)

    function handleContactChange(event:any) {
        const phoneNumber = event.target.value;
        const finalNumber = phoneNumber.replace(/[^\d]/g, '');

        if (finalNumber.length >= 11) {
            setContact(phoneNumber);
            setNumberError(false);

        } else {
            setNumberError(true);
        }
    }

    const errorModal = useRef<HTMLDialogElement>(null);
    const successModal = useRef<HTMLDialogElement>(null);

    const ErrorModalFunc = () => {
        successModal.current?.close()
        errorModal.current?.showModal();
    }

    const SuccessModalFunc = () => {
        errorModal.current?.close();
        successModal.current?.showModal()
    }

    async function handleZipCodeSearch(event:any) {  
        const zipCode = event.target.value;
        const validZipCode = validateCEP(zipCode);

        if (validZipCode === true) {
            try {
                const response = await zipCodeSearch.get(`${zipCode}/json`);
                setCep(response.data);
                setZipCode(zipCode);
                // console.log(response.data);
                setCepValid(true);
            } catch {
                // console.error(Error);
                setCepValid(false);
            }
        } else {
            setCepValid(false);
        }
    };

    const [consentChecked, setConsentChecked] = useState(false);

    function handleCheckboxChange(event:any) {
        setConsentChecked(event.target.checked);
    };
    
    async function handleSubmit(event:any) {

        event.preventDefault();

        if (cep.uf !== undefined) {
            let body: TProducerSignUp = {
                name: info.name,
                password: info.password,
                cpf: document,
                email: info.email,
                phoneNumber: `+55${contact}`,
                zip_code: zipCode,
                location: cep.logradouro,
                number: +info.addressNumber,
                complement: info.complement,
                neighborhood: cep.bairro,
                city: cep.localidade,
                state: cep.uf,
                country: 'BR',
            };

            const fetch = await Auth.SignUp("Producer", body);
            if ('statusCode' in fetch) {
                ErrorModalFunc();
            }
            if (!('statusCode' in fetch)) {
                SuccessModalFunc();
            }
        }
    }

    // Recarregar modal após fechado
	const [reloadPage, setReloadPage] = useState(false);

    const closeModal = () => {
        successModal.current?.close();
        reloadPageHandler(); 
    };

	const reloadPageHandler = () => {
	    setReloadPage(true);
	};

	useEffect(() => {
		if (reloadPage) {
			setReloadPage(false);
			window.location.reload(); // Recarrega a página
		}
	}, [reloadPage]);

  return (
    <div>
        <div className={styles.divNavigation}>
            <Navigation click={() => navigate('/')}/>
        </div>
        <main className={styles.formContainer}>
            <form className={styles.producerForm}>
                <h1 className={styles.pageTitle}>Cadastro Pessoa Física</h1>
                    <h2 className={styles.loginTitle}>Dados para login</h2>
                    <p className={styles.loginSubtitle}>Insira os dados para efetuar o login.</p>
                    <div className={styles.row1}>
                        <span className={styles.inputItem}>
                            <Input 
                                label={'Email'} 
                                type={'email'}
                                onChange={(e) => [setInfo({...info, email: e.target.value})]}
                                onBlur={handleEmailChange} />
                            {!emailValid && 
                                <span className={styles.error}>Email inválido</span>
                            }
                        </span>
                        <span className={styles.inputItem}>
                            <Input
                                label={'Senha'}
                                type={'password'}
                                onChange={(e) => [setInfo({...info, password: e.target.value})]}
                                onBlur={handlePasswordChange}
                                password={true}
                                iconEyePassword={true}
                            />
                            {!passwordValid && 
                                <span className={styles.error}>
                                    A senha deve conter pelo menos 12 caracteres, 
                                    1 letra maiúscula, 
                                    1 letra minúscula, 
                                    1 número e 1 caractere especial
                                </span>
                            }
                        </span>
                    </div>
                    <h2 className={styles.loginTitle}>Informações Gerais</h2>
                    <div className={styles.row2}>
                        <Input
                            label={'Nome Completo'}
                            type={'text'}
                            onChange={(e) => [setInfo({...info, name: e.target.value})]}
                        />
                        <span className={styles.inputItem}>
                            <Input
                                label={'CPF'}
                                type={'document'}
                                value={document}
                                onChange={(e) => setDocument(maskCPF(e.target.value))}
                                onBlur={handleDocumentChange}
                                placeholder={'___.___.___-__'}
                            />
                            {!cpfValid &&
                                <div className={styles.errorContainer}>
                                    <span className={styles.error}>
                                        Erro: CPF inválido. Exemplo: 123.456.789-09
                                    </span>
                                </div>
                            }
                        </span>
                        <span className={styles.contactInput}>
                            <Input
                                label={'Contato'}
                                type={'tel'}
                                value={'+55'}
                                disabled
                            />
                            <Input
                                label={''}
                                type={'tel'}
                                value={contact}
                                placeholder={'(__) _____-____'}
                                onChange={(e) => setContact(maskPhone(e.target.value))}
                                onBlur={handleContactChange}
                            />
                            {numberError &&
                                <div className={styles.errorContainer}>
                                    <span className={styles.error}>Número de telefone inválido</span>
                                </div>
                            }
                        </span>
                    </div>
                    <h2 className={styles.loginTitle}>Endereço</h2>
                    <p className={styles.loginSubtitle}>Insira o endereço da sua propriedade ou de sua residência.</p>
                    <div className={styles.row3}>
                        <div className={styles.addressTop}>
                            <span className={styles.inputItem}>
                                <Input
                                    label={'CEP'}
                                    type={'text'}
                                    placeholder={'_____-___'}
                                    value={zipCode}
                                    onChange={(e) => setZipCode(maskCEP(e.target.value))}
                                    onBlur={handleZipCodeSearch}
                                />
                                {!cepValid && 
                                    <span className={styles.error}>CEP inválido.</span>
                                }
                            </span>
                            <Input
                                label={'Cidade'}
                                type={'text'}
                                maxLength={8}
                                value={cep.localidade}
                                onChange={(e) => [setInfo({...info, city: e.target.value})]}
                            />
                            <Input
                                label={'Número'}
                                type={'text'}
                                onChange={(e) => [setInfo({...info, addressNumber: e.target.value})]}
                            />
                        </div>
                        <div className={styles.addressBottom}>
                            <Input
                                label={'Localização'}
                                type={'text'}
                                value={cep.logradouro}
                                onChange={(e) => [setInfo({...info, address: e.target.value})]}
                            />
                            <Input
                                label={'Bairro'}
                                type={'text'}
                                value={cep.bairro}
                                onChange={(e) => [setInfo({...info, district: e.target.value})]}
                            />
                            <Input
                                label={'Complemento'}
                                type={'text'}
                                onChange={(e) => setInfo({...info, complement: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className={styles.consentInput}>
                        <input 
                            type="checkbox" 
                            id="consent" 
                            name="consent"
                            checked={consentChecked}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="consent" className={styles.marginLeft}>
                            Ao selecionar esta opção, estou confirmando que li atentamente e concordo
                            integralmente com os <a 
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/terms-and-conditions', '_blank');
                                }}
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className={styles.toTerms}>
                                    termos e condições
                                </a>. 
                            Além disso, certifico que minha idade é maior de 18 anos.
                        </label>
                    </div>
                    <div className={styles.buttons}>
                        <Button onClick={() => navigate("/home")} placeholder='Voltar' variant='transparent-primary'/>
                        <Button 
                            onClick={handleSubmit} 
                            placeholder='Cadastrar' 
                            variant='filled-primary'
                            enabled={!consentChecked} 
                        />
                    </div>
                    <div className={styles.cardError}>
                        <ErrorModal reference={errorModal} title="Ocorreu um erro">
                            <div className={styles.divModal}>
                                <p>Ocorreu um erro nos dados inseridos. Por favor, verifique se as informações inseridas estão corretas ou se já estão cadastradas no sistema.</p>
                            </div>
                        </ErrorModal>
                    </div>
                    <div className={styles.cardSuccess}>
                        <SuccessModal reference={successModal} email={info.email} send={() => alert('oi')} back={() => navigate('/')} buttonClose={true} onClickButtonClose={closeModal}/>
                    </div>
            </form>
        </main>
        <div className={styles.footer}>
            <Footer />
        </div>
    </div>
  )
}

export default Producer;