import { getPagarmeApiKey, getPagarmeBaseUrl, getBackendUrl } from '../config';

const baseUrl: string = getBackendUrl();
const pagarmeBaseUrl: string = getPagarmeBaseUrl();
const apiKey: string = getPagarmeApiKey();

// const sercretKey: string = getSecretKey();

const Auth = {
    Login: `${baseUrl}users/signin`,
    SignUpProducer: `${baseUrl}users/create-private-user`,
    SignUpCompany: `${baseUrl}users/create-legal-user`,
    EmailConfirm: `${baseUrl}users/confirmation`,
    RecoveryEmail: `${baseUrl}users/recover-email`,
    RecoveryPassword: `${baseUrl}users/recover-password`
} as const;

const Users = {
    List: `${baseUrl}users/list`,
    ListHistories: `${baseUrl}users/list/histories/`,
    View: `${baseUrl}users/view/`,
    UpdateUser: `${baseUrl}users/update-user`,
    UpdateAddress: `${baseUrl}users/update-address`,
    UpdatePhone: `${baseUrl}users/update-phone-number`,
    UpdateEmail: `${baseUrl}users/update-email`,
    Coupons: `${baseUrl}coupon`,
    UpdateCoupon: (couponId: string) => `${baseUrl}coupon/${couponId}/update`
} as const;

const Areas = {
    List: `${baseUrl}areas/list`,
    ListPreloaded: `${baseUrl}areas/list/preloaded`,
    Monitoring: (areaId: string) => `${baseUrl}areas/monitoring/${areaId}`,
    Focus: `${baseUrl}areas/focus`,
    Preload: `${baseUrl}areas/create/preloaded`,
    FreeArea: (areaId: string, userId: string) => `${baseUrl}coupon/${userId}/get-free-area/${areaId}`,
} as const;

const Gatherer = {
    Optic: `${baseUrl}gatherer/optic/`,
    Radar: `${baseUrl}gatherer/radar/`
} as const;

const Pagarme = {
    Card: (cusToken: string) => `${pagarmeBaseUrl}customers/${cusToken}/cards`,
    CardToken: `${pagarmeBaseUrl}tokens?appId=${apiKey}`,
    BIN: (cardBIN: string) => `https://api.pagar.me/bin/v1/${cardBIN}`
} as const;

const Checkout = {
    Customer: (userId: string) => `${baseUrl}checkout/${userId}/customer/create`,
    CreditCardPayment: (userId: string) => `${baseUrl}checkout/${userId}/credit_card_payment/create`,
    PixPayment: (userId: string) => `${baseUrl}checkout/${userId}/pix_payment/create`,
    Offers: `${baseUrl}checkout/offers/create`,
    CheckAccess: `${baseUrl}checkout/check-access`,
    VerifyCustomer: (userId: string) => `${baseUrl}checkout/${userId}/customers`,
    VerifySubscription: (userId: string) => `${baseUrl}checkout/${userId}/subscriptions`,
    VerifyPixPayment: (userId: string) => `${baseUrl}checkout/${userId}/check/pix`,
    CreateCard: (userId: string) => `${baseUrl}checkout/${userId}/card/create`,
    AddCreditCardPayment: (userId: string) => `${baseUrl}checkout/${userId}/credit_card_payment/add`,
    AddPixPayment: (userId: string) => `${baseUrl}checkout/${userId}/pix_payment/add`,
    getIP: () => `${baseUrl}users/ip`,
} as const;

const Subscription = {
    Cancel: (userId: string) => `${baseUrl}checkout/${userId}/subscriptions/cancel`
}

const URLMapping = {
    Users,
    Areas,
    Gatherer,
    Auth,
    Checkout,
    Pagarme,
    Subscription
} as const;

export default URLMapping;