import THttpRequestError from "../../@types/http/THttpRequestError";
import TCreateCustomerRequest from "../../@types/checkout/customers/create/TCreateCustomerRequest";
import API from "../API";
import URLMapping from "../URLMapping";
import TCreateCustomerResponse from "../../@types/checkout/customers/create/TCreateCustomerResponse";
import TCreditCardPaymentResponse from "../../@types/checkout/payment/credit/TCreditCardPaymentResponse";
import TPixPaymentResponse from "../../@types/checkout/payment/pix/TPixPaymentResponse";
import TCheckAccess from "../../@types/checkout/TCheckAccess";
import TSubscrition from "../../@types/checkout/TSubscription";
import TPixPaymentRequest from "../../@types/checkout/payment/pix/TPixPaymentRequest";
import TCreditCardPaymentRequest from "../../@types/checkout/payment/credit/TCreditCardPaymentRequest";
import TCreateCardResponse from "../../@types/checkout/card/createCard/TCreateCardResponse";
import TCreateCardRequest from "../../@types/checkout/card/createCard/TCreateCardRequest";

abstract class Checkout {
    public static async Customer(userId: string, body: TCreateCustomerRequest) {
        return await API.Post<TCreateCustomerResponse | THttpRequestError>(
            URLMapping.Checkout.Customer(userId),
            JSON.stringify(body)
        );
    }

    public static async CreateCard(userId: string, body: TCreateCardRequest) {
        return await API.Post<TCreateCardResponse | THttpRequestError>(
            URLMapping.Checkout.CreateCard(userId),
            JSON.stringify(body)
        );
    }

    public static async CreditCardPayment(userId: string, body: TCreditCardPaymentRequest) {
        return await API.Post<TCreditCardPaymentResponse | THttpRequestError>(
            URLMapping.Checkout.CreditCardPayment(userId),
            JSON.stringify(body)
        );
    }

    public static async AddCreditCardPayment(userId: string, body: TCreditCardPaymentRequest) {
        return await API.Post<TCreditCardPaymentResponse | THttpRequestError>(
            URLMapping.Checkout.AddCreditCardPayment(userId),
            JSON.stringify(body)
        );
    }

    public static async PixPayment(userId: string, body: TPixPaymentRequest) {
        return await API.Post<TPixPaymentResponse | THttpRequestError>(
            URLMapping.Checkout.PixPayment(userId),
            JSON.stringify(body)
        );
    }

    public static async AddPixPayment(userId: string, body: TPixPaymentRequest) {
        return await API.Post<TPixPaymentResponse | THttpRequestError>(
            URLMapping.Checkout.AddPixPayment(userId),
            JSON.stringify(body)
        );
    }

    public static async VerifyPixPayment(userId: string) {
        return await API.Get<boolean | THttpRequestError>(
            URLMapping.Checkout.VerifyPixPayment(userId)
        );
    }

    public static async VerifyCustomer(userId: string) {
        return await API.Get<TSubscrition | THttpRequestError>(
            URLMapping.Checkout.VerifyCustomer(userId)
        );
    }

    public static async VerifySubscription(userId: string) {
        return await API.Get<object[] | THttpRequestError>(
            URLMapping.Checkout.VerifySubscription(userId)
        );
    }

    public static async CheckAccess() {
        return await API.Get<TCheckAccess | THttpRequestError>(
            URLMapping.Checkout.CheckAccess
        );
    }

    public static async getIP() {
        return await API.Get <{ip : string}>(
            URLMapping.Checkout.getIP()
        );
    }
}

export default Checkout;
