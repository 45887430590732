import { useRef } from "react";
import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "./ChangePasswordModal.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    send: () => void;
    buttonClose?: boolean
    onClickButtonClose?: () => void
}

function ChangePasswordModal(props:IProperties): JSX.Element {
 
    const confirmAccess = useRef<HTMLDialogElement>(null);
    
    const modal = () => {
        confirmAccess.current?.showModal();
    }

    return(
        <>
        <div className={styles.container}>
            <Modal forwardRef={ props.reference } title="Alteração de senha" buttonClose={props.buttonClose} onClickButtonClose={props.onClickButtonClose}>
                <p className={styles.modalParagraph}>Senha alterada com sucesso!</p>
                <Button variant="filled-primary" onClick={props.send} placeholder="Voltar para home"/>    
            </Modal>
        </div>
        </>
    )
}

export default ChangePasswordModal;