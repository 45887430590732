import Modal from "../../Modal";
import styles from "../Modals.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    email: string;
    resend: () => void;
    forwardRef: React.RefObject<HTMLParagraphElement>;
}

function RecoveryPasswordSendModal(props:IProperties): JSX.Element {
    return(
        <>
            <Modal forwardRef={ props.reference } title="Recuperar Senha">
                <p ref={ props.forwardRef } className={ `${styles.modalParagraph} ${styles.paragraphLeft}` }>Enviamos um link de recuperação para <span className={ styles.modalUnderline }>{ props.email }</span>. Verifique a sua caixa de entrada, incluindo a pasta de spam, para localizar o email com o link.</p>
                <p className={ `${styles.modalParagraph} ${styles.paragraphLeft} ${styles.paragraphPaddingTop}` }>Não chegou? <span className={ styles.modalUnderline } onClick={ props.resend }>Reenviar link</span></p>
            </Modal>
        </>
    )
}

export default RecoveryPasswordSendModal;