import Modal from "../../Modal";
import styles from "./Unsubscribe.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    text: string;
}

function UnsubscribeSendModal(props:IProperties): JSX.Element {
    return(
        <>
            <Modal forwardRef={ props.reference } title="Pedido de cancelamento enviado">
                <p className={styles.paragraphCenter}>{ props.text }</p>
            </Modal>
        </>
    )
}

export default UnsubscribeSendModal;