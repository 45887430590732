import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "../Modals.module.css";
import Input from "../../../../components/Input/Input";
import { useEffect, useRef, useState } from "react";
import { validatePassword } from "../../../../@utils/Validation/Validation";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    change: (password: string) => void;
}

function NewPasswordModal (props:IProperties): JSX.Element {
    const [passwords, setPasswords] = useState<{ pwd1: string, pwd2: string }>({ pwd1: "", pwd2: "" });

    const errorRef = useRef<HTMLDivElement>(null);

    const validate = () => {
        if (passwords.pwd1 !== passwords.pwd2) {
            return false;
        } else if (!validatePassword(passwords.pwd1) || !validatePassword(passwords.pwd2)) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (passwords.pwd1 !== passwords.pwd2) {
            errorRef.current!.innerHTML = "As senhas devem ser idênticas!"
            return;
        } else if (!validatePassword(passwords.pwd1) || !validatePassword(passwords.pwd2)) {
            errorRef.current!.innerHTML = "A senha deve conter pelo menos 12 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial"
            return;
        }
        errorRef.current!.innerHTML = "";
    }, [passwords, setPasswords])

    const [showPasswordPassword, setShowPasswordPassword] = useState(false);
    const [showPasswordRepeatPassword, setShowPasswordRepeatPassword] = useState(false);


    const VisibilityPassword = () => {
        setShowPasswordPassword(!showPasswordPassword);
    };

    const VisibilityRepeatPassword = () => {
        setShowPasswordRepeatPassword(!showPasswordRepeatPassword);
    };

    return(
        <>
            <Modal forwardRef={ props.reference } title="Recuperar a senha">
                <p className={ `${styles.modalParagraph} ${styles.paragraphLeft}` }>Insira sua nova senha.</p>
                <div className={styles.divInputRestorePassword}>
                    {/* <Input label={ "Senha" } type={ "password" } onChange={ (e) => setPasswords({ ...passwords, pwd1: e.target.value }) } password={true} iconEyePassword={true}/>
                    <Input label={ "Confirmar senha" } type={ "password" } onChange={ (e) => setPasswords({ ...passwords, pwd2: e.target.value }) } iconEyePassword={true}/> */}
                    <div className={styles.containerInput}> 
                        <div className={styles.inputGroup}>
                            <label className={styles.textLabelNewPassword} htmlFor="confirm-new-password">Senha</label>
                            <div className={styles.divIconEye}>
                                <input 
                                    type={showPasswordPassword ? 'text' : 'password'}
                                    placeholder="**************"
                                    id="repeatPassword"
                                    onChange={ (e) => setPasswords({ ...passwords, pwd1: e.target.value }) }
                                />
                                <div className={styles.divEyesPasswordRecover}>
                                    {showPasswordPassword ? (
                                        <AiFillEye
                                        className={styles.iconEye}
                                        onClick={VisibilityPassword}
                                        />
                                    ) : (
                                        <AiFillEyeInvisible
                                        className={styles.iconEye}
                                        onClick={VisibilityPassword}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.inputGroup}>
                            <label className={styles.textLabel} htmlFor="confirm-new-password">Confirmar senha</label>
                            <div className={styles.divIconEye}>
                                <input 
                                    type={showPasswordRepeatPassword ? 'text' : 'password'}
                                    placeholder="**************"
                                    id="repeatPassword"
                                    onChange={ (e) => setPasswords({ ...passwords, pwd2: e.target.value }) }
                                />
                                <div className={styles.divEyesPasswordRecover}>
                                    {showPasswordRepeatPassword ? (
                                        <AiFillEye
                                        className={styles.iconEye}
                                        onClick={VisibilityRepeatPassword}
                                        />
                                    ) : (
                                        <AiFillEyeInvisible
                                        className={styles.iconEye}
                                        onClick={VisibilityRepeatPassword}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={ errorRef } className={` ${ styles.passwordHint } ${ !validate() ? styles.invalidNewPassword : '' } `}>As senhas devem ser idênticas!</div>
                <Button placeholder="Alterar senha" variant="filled-primary" enabled={ !validate() } onClick={ () => props.change(passwords.pwd1) } />
            </Modal>
        </>
    )
}

export default NewPasswordModal;