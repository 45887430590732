import React from 'react';
import Logo from '../../../components/Logo/Logo';
import { Link } from 'react-router-dom';
import styles from './Navigation.module.css';


interface IProperties {
  click?: () => void
}

const Navigation = (props:IProperties) => {
  return (
    <div className={ styles.navigation }>
        <Link className={styles.link} to={'/'}>Início</Link>
        <Logo click={props.click}/>
    </div>
  )
}

export default Navigation