import { useContext, useRef, useState } from "react";
import styles from './Subscription.module.css';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { FaCheck } from 'react-icons/fa';
import Button from '../../../components/Button/Button';
import UnsubscribeModal from "../../../modules/Modal/components/Unsubscribe/UnsubscribeModal";
import UnsubscribeSendModal from "../../../modules/Modal/components/Unsubscribe/UnsubscribeSendModal";
import SubscriptionService from "../../../services/subscription/Subscription.service";
import SwitchPlanModal from "../../../modules/Modal/components/SwitchPlan/SwitchPlanModal";
import SwitchPlanSendModal from "../../../modules/Modal/components/SwitchPlan/SwitchPlanSendModal";
import { SessionContext, SessionContextType } from "../../../contexts/SessionContext/SessionContext";
import { getBackendUrl } from "../../../config";

function  Subscription (): JSX.Element {

    const baseUrl: string = getBackendUrl();

    const { session } = useContext(SessionContext) as SessionContextType;

    const unsubscribe = useRef<HTMLDialogElement>(null);
    const unsubscribeSend = useRef<HTMLDialogElement>(null);
    const switchPlan = useRef<HTMLDialogElement>(null);
    const switchMessageError = useRef<HTMLDialogElement>(null);
    const switchMessageSuccess = useRef<HTMLDialogElement>(null);
    const checkRef = useRef<HTMLInputElement>(null);

    const [isChecked, setIsChecked] = useState<boolean>(false);

    const checkRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) setIsChecked(true);
        else setIsChecked(false);
    }

    const UnsubscribeFunc = () => {
        if (checkRef.current?.checked) {
            unsubscribeSend.current?.close();
            unsubscribe.current?.showModal();
        }
    }

    const UnsubscribeSendFunc = async () => {
        let fetch = await SubscriptionService.Cancel(String(session.user!.id));

        if ('message' in fetch) {
            unsubscribe.current?.close();
            unsubscribeSend.current?.showModal();
        }
    }

    const SwitchPlanFunc = () => {
        switchPlan.current?.showModal()
    }

    const SwitchMessageErrorFunc = () => {
        switchPlan.current?.close()
        switchMessageError.current?.showModal()
    }

    const SwitchMessageSuccessFunc = () => {
        switchPlan.current?.close()
        switchMessageSuccess.current?.showModal()
    }

    async function handleSubmit() {

        const switchResult = await fetch(`${baseUrl}users/switch-type/${session.user!.id}`, {
            mode: 'cors',
            method: 'PATCH',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({}),
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if ('statusCode' in data) {
                SwitchMessageErrorFunc();
            }
            return data;
        })
        .catch(error => {
        });
        
        if ('statusCode' in switchResult) {
            return;
        } else {
            
            const couponResult = await fetch(`${baseUrl}coupon/create`, {
                mode: 'cors',
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    status : "pending",
                    user_id: session.user!.id,
                    expiration_date: "2024-08-01",
                    expire_auto: true      
                }),
            })
            .then(response => {
                return response.json();
            })
            .then(data => {
                return data;
            })
            .catch(error => {
            });
            
            const resultCancel = await fetch(`${baseUrl}checkout/${session.user!.id}/subscriptions/cancel`, {
            mode: 'cors',
            method: 'PATCH',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({}),
            })
            .then(response => {
                return response.json();
            })
            .then(data => {
                if ('statusCode' in data) {
                    SwitchMessageErrorFunc();
                }
                if (!('statusCode' in data)) {
                    SwitchMessageSuccessFunc();
                }
                return data;
            })
            .catch(error => {
            });
        }
    }
    
    console.log(session.user!.type)

    return (
        <div className={styles.mainContainer}>
            <nav className={styles.heading }>
                <h1 className={styles.titleNav}>Configurações</h1>
                <Breadcrumb head="Configurações" pages={ ["Assinatura"] } />
            </nav>
            <div className={styles.container}>
                <div className={styles.firstCardGroup}>
                    <h2 className={styles.titleGroup}>Padrão</h2>
                    <div className={styles.checkGroup}>
                        <div className={styles.divIcon}>
                            <FaCheck className={styles.iconGroup}/>
                        </div>
                        <p className={styles.textIcon}>Compartilhamento em redes sociais</p>
                    </div>
                    <div className={styles.checkGroup}>
                        <div className={styles.divIcon}>
                            <FaCheck className={styles.iconGroup}/>
                        </div>
                        <p className={styles.textIcon}>Monitoramento de áreas</p>
                    </div>
                    <div className={styles.containerButton}>
                        { session.user!.type === "Private Customer" ?(
                            <Button onClick={() => ''} variant='transparent-gray' placeholder='Plano Atual'/>
                        ): (
                            <Button onClick={() => ''} variant='transparent-gray' placeholder=''/>
                        )}  
                    </div>
                </div>

                <div className={styles.secondCardGroup}>
                    <h2 className={styles.titleGroup} >Business</h2>
                    <div className={styles.checkGroup}>
                        <div className={styles.divIcon}>
                            <FaCheck className={styles.iconGroup}/>
                        </div>
                        <p className={styles.textIcon}>Benefícios do plano padrão</p>
                    </div>
                    <div className={styles.checkGroup}>
                        <div className={styles.divIcon}>
                            <FaCheck className={styles.iconGroup}/>
                        </div>
                        <p className={styles.textIcon}>Monitoramento de inúmeras áreas</p>
                    </div>
                    <div className={styles.checkGroup}>
                        <div className={styles.divIcon}>
                            <FaCheck className={styles.iconGroup}/>
                        </div>
                        <p className={styles.textIcon}>Emissão de relatório completo</p>
                    </div>
                    <div className={styles.checkGroup}>
                        <div className={styles.divIcon}>
                            <FaCheck className={styles.iconGroup}/>
                        </div>
                        <p className={styles.textIcon}>Suporte personalizado</p>
                    </div>
                    <div className={styles.containerButton}>
                        { session.user!.type === "Legal Customer" ?(
                            <Button onClick={SwitchPlanFunc} variant='filled-primary' placeholder='Plano atual' enabled={true} />
                        ): (
                            <Button onClick={SwitchPlanFunc} variant='filled-primary' placeholder='Trocar plano'/>
                        )}
                    </div>
                </div>
            </div>
            <form className={styles.containerForm} onSubmit={(e) => e.preventDefault()}>
                <div>
                    <input ref={ checkRef } onChange={ checkRadio } type='checkbox' id="check" name="check"/>
                    <label className={styles.textLabel} htmlFor="check">Desejo cancelar a minha assinatura</label>
                    <p className={styles.paragraphForm}>Sua assinatura atual é 
                    { session.user!.type === "Legal Customer" ? <b> Business</b> : <b> Padrão</b>}
                    </p>
                    <div className={styles.divButtonForm}>
                        <Button onClick={UnsubscribeFunc} variant='filled-error' enabled={ !isChecked } placeholder='Cancelar'/>
                    </div>
                </div>
            </form>
            <UnsubscribeModal 
                reference={unsubscribe} 
                title='Tem certeza disso?' 
                paragraph='Cancelar a sua assinatura fará com que você perca o seu acesso'
                textFirstButtom="Voltar"
                textSecondButtom="Solicitar Cancelamento"
                cancel={UnsubscribeSendFunc} 
            />
            <UnsubscribeSendModal 
                reference={unsubscribeSend}
                text={
                    session.user!.type === "Private Customer" ? (
                        "Sua assinatura foi cancelada com sucesso."
                    ): (
                        "Seu pedido de cancelamento foi enviado com sucesso, logo mais entraremos em contato com você pelo e-mail."
                    )
                }
            />
            <SwitchPlanModal 
                reference={switchPlan} 
                title='Deseja mudar para Business?' 
                paragraph='Ao realizar a solicitação, um time de especialistas irão entrar em contato com você em até 5 dias úteis para finalizar a mudança de conta'
                textFirstButtom='Voltar'
                textSecondButtom='Confirmar'
                cancel={handleSubmit} 
                close={() => switchPlan.current?.close()}
            />
            <SwitchPlanSendModal 
                reference={switchMessageError} title="Ocorreu um erro" text="Não foi possível solicitar a troca de plano no momento, por favor, verifique se você já realizou o pedido de troca de plano ou então, tente novamente mais tarde."
            />
            <SwitchPlanSendModal 
                reference={switchMessageSuccess} title="Solicitação realizada com sucesso" text="Dentro de 5 dias, entraremos em contato atráves do e-mail contato@spotsat.com.br para realizar a troca de plano."
            />
        </div>
    )
}

export default Subscription;
