import { Link, LinkProps } from "react-router-dom";
import { IconType } from "react-icons";
import { FaCircleNotch, FaHome, FaPager, FaUser, FaUserLock } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { BsFillGearFill } from "react-icons/bs";
import { GrUserAdmin } from "react-icons/gr";
import styles from "./SidebarItem.module.css";

interface IProperties extends LinkProps {
    isCollapsed: boolean;
    placeholder?: string;
    icon?: string;
    click?: () => void;
}

function SidebarItem({
    to,
    isCollapsed,
    click,
    placeholder = "Sidebar Item",
    icon = "default",

}: IProperties): JSX.Element {

    const mapIcons = (): { [key: string]: IconType } => {
        return { 
            default: FaCircleNotch, 
            home: FaHome,
            user: FaUser,
            gear: BsFillGearFill,
            paper: FaPager,
            logout: TbLogout,
            admin: FaUserLock
        }
    }

    const Icon = mapIcons()[icon];

    return (
        <Link to={ to } onClick={click} className={`${ styles.sidebarItem } ${ isCollapsed ? styles.collapsed : styles.expanded }`}>
            <Icon className={ styles.icon } />
            { isCollapsed ? '' : <span className={ styles.placeholder }>{ placeholder }</span> }
        </Link>
    );
}

export default SidebarItem;