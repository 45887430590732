import ReactDOM from 'react-dom/client';
import SessionContextProvider from './contexts/SessionContext/SessionContext';
import "./styles/global.css";
import Router from './routes/Router.routes';
import React from 'react';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <SessionContextProvider>
            <Router />
        </SessionContextProvider>
    </React.StrictMode>
);