import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import styles from "./LApp.module.css";
import Footer from "../components/Footer/Footer";

function App(): JSX.Element {
    return (
        <div className={ styles.layout }>
            <Sidebar />
            <main>
                <Outlet />
                <Footer />
            </main>
        </div>
    );
}

export default App;