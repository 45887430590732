import Logo from "../../../components/Logo/Logo";
import React, { useEffect } from "react";
import styles from "./PrivacyPolicy.module.css";
import Navigation from "../../../layouts/components/Navigation/Navigation";
import { useNavigate } from "react-router-dom";


function PrivacyPolicy(): JSX.Element {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <>
            <Navigation click={() => navigate('/')}/>
            <section className={`${ styles.sct } ${ styles.sctPolicy } ${ styles.container }`}>
                <div className={ styles.sctPolicy__content }>
                    <div className={`${ styles.sct__content } ${ styles.sctPolicy__content__info }`}>
                        <h1>Política de Privacidade SpotGreen</h1>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Nossa Política de Privacidade define as práticas de privacidade e explica como manuseamos e protegemos os dados de nossos visitantes e usuários coletados em nossa plataforma.
                                Em caso de dúvidas, entre em contato com nossa equipe pelos meios de comunicação disponíveis no site.
                            </p>
                        </ol>
                        <h2>1. Sobre a lei geral de proteção de dados</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                        <p>
                            De acordo com a Lei Geral de Proteção de Dados (LGPD) toda pessoa tem direito à informação, a transparência, a liberdade e a autonomia. 
                            Com relação aos dados pessoais, toda pessoa tem direito ao acesso, a correção, eliminação, limitação do tratamento e sua oposição a publicidade. 
                            Importante esclarecer que os dados pessoais são tratados sob confidencialidade e todas as medidas de segurança são adotadas para garantir a proteção. 
                        </p>
                        </ol>
                        <h2>2. O que podemos coletar</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Coletamos informações pessoais através dos formulários de cadastros, comentários e dúvidas enviados, e seus dados serão salvos para posterior atendimento e retorno. 
                                Terceiros podem enviar alguns dados seus, como, por exemplo, dados recebidos por mídias sociais, do governo ou intermediadoras de pagamentos. 
                                Quando você acessa o SPOTGREEN, podemos coletar e armazenar seu endereço IP, para combater spam e outros abusos. 
                            </p>
                        </ol>
                        <h2>3. Como e porque coletamos seus dados</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                No âmbito da sua experiência como usuário ou visitante, é de suma importância que você compreenda a natureza e o propósito da coleta de dados pessoais durante suas interações com a Plataforma SPOTGREEN.
                                Ao optar por se cadastrar, ao selecionar o botão “Cadastrar-se”, será solicitado que você indique se é uma Pessoa Física ou Jurídica. Nos casos em que a escolha for pela Pessoa Física, serão coletadas as seguintes informações:
                                Pessoa Física: Nome completo, CPF, informações de contato, endereço completo (incluindo CEP, cidade, número, localização, bairro e complemento).
                                Para o caso de Pessoa Jurídica, os dados coletados incluem:
                                Pessoa Jurídica: Nome do contato, nome da empresa, CNPJ, endereço de e-mail, informações de contato e endereço completo (incluindo CEP, cidade, número, localização, bairro e complemento).
                                Durante sua navegação em nosso site, coletamos automaticamente informações como endereço IP, tipo de navegador e páginas acessadas.
                                No contato direto com nossa equipe, seja por meio de e-mail, telefone ou chat, coletamos suas informações de contato, como nome, endereço de e-mail, número de telefone e a mensagem enviada.
                                Adicionalmente, gostaríamos de esclarecer que poderemos utilizar, formatar e divulgar depoimentos que você tenha postado em páginas públicas nas redes sociais. Contudo, ressaltamos que quaisquer dados particulares que possam identificá-lo serão estrategicamente ocultados, permitindo que esses depoimentos sirvam como materiais promocionais para divulgação da nossa plataforma.
                            </p>
                        </ol>
                        <h2>4. Com quem compartilhamos seus dados pessoais</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Como regra geral, não compartilhamos seus dados pessoais com terceiros e nem comercializamos seus dados pessoais
                                Quando solicitados por autoridades governamentais, por ordens judiciais, conforme exigido por lei, será encaminhado uma notificação prévia para que você tenha ciência e possa contestar a ordem judicial expedida, a menos que estejamos proibidos de fazê-lo. 
                            </p>
                        </ol>
                        <h2>5. Alteração nos dados de cadastro</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                É possível alterar seus dados de cadastro a qualquer momento.
                                Para proteger informações de destruição acidental ou maliciosa, não podemos excluir imediatamente as cópias de seus dados de nossos servidores e não podemos remover informações de nossos sistemas de backup, porém, se você excluir sua conta, sua conta e seus dados poderão ser irrecuperáveis.
                            </p>
                        </ol>
                        <h2>6. Segurança de dados</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Usamos criptografia HTTP com TLS para proteger os dados transmitidos .
                                Todas as informações pessoais são armazenados em servidores seguros, protegidos e monitorados, utilizando os mais modernos e avançados sistemas de segurança. 
                                No entanto, nenhuma transmissão de dados pela Internet é 100% segura, apesar de todos os meios utilizados para sua e nossa proteção. Você é responsável  pela proteção de sua conta e manter sua senha segura. 
                                Todos os dados de seu cadastro fornecido através dos formulários da nossa plataforma serão transferidos via HTTP com TLS e armazenados em nosso banco de dados que não podem ser acessados em ambiente externo ao nosso firewall.
                                É permitido a você solicitar a revisão e correção de sesu dados pessoais, entrando em contato com os canais de atendimento disponível.
                                Caso deseje cancelar sua inscrição e excluir seus dados, lembre-se que nós, com o fim de cumprirmos obrigações legais, armazenaremos os dados pelo período e nos lermos da legislação vigente. 
                            </p>
                        </ol>
                        <h2>7. Alterações na política de privacidade</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                O SPOTGREEN pode atualizar, ocasionalmente, a política de privacidade.
                                Qualquer mudança relevante será comunicada a você através da página principal, ou enviaremos um e-mail ou notificaremos o seu dispositivo cadastrado, conforme as circunstâncias.
                            </p>
                        </ol>
                        <h2>DÚVIDAS</h2>
                        <p className={ styles.sctPolicy__help }>
                            <p>Em caso de dúvidas, preocupações ou comentários, entre em contato com os meios de comunicação disponibilizados em nossa plataforma.</p>
                            <b>Entre em Contato Conosco:</b>
                            <p>Email: <a href="mailto:contato@spotsat.com.br">contato@spotsat.com.br</a></p>
                            <p>Telefone: +55 11 91717-7695</p>
                            © Spotgreen 2023
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PrivacyPolicy;
