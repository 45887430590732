import { useState } from "react";
import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "../Modals.module.css";
import Input from "../../../../components/Input/Input";
import isEmailValid from "../../../../@utils/Validation/isEmailValid";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    send: (email: string) => void;
    back: () => void; 
}

function RecoveryPasswordModal(props:IProperties): JSX.Element {
    const [email, setEmail] = useState<string>("");

    return(
        <>
             <Modal forwardRef={ props.reference } title="Recuperar Senha">
                <p className={ `${styles.modalParagraph} ${styles.paragraphLeft}` }>Insira seu e-mail para enviarmos o link para redefinição de senha</p>
                {/* <Input label={ "Email" } type={ "text" } onChange={ (e) => setEmail(e.target.value) } placeholder="Insira seu email" /> */}
                <div className={styles.containerInput}>
                    <div className={styles.inputGroup}>
                        <label className={styles.textLabel} htmlFor="confirm-new-password">Email</label>
                        <input 
                            type={'text'}
                            placeholder="Insira seu email"
                            id="repeatPassword"
                            onChange={ (e) => setEmail(e.target.value) }
                        />
                    </div>
                </div>
                <div className={` ${ styles.emailHint } ${ !isEmailValid(email) ? styles.invalid : '' }`} >Insira um email válido.</div>
                <Button placeholder="Enviar" variant="filled-primary" enabled={ !isEmailValid(email) } onClick={ () => props.send(email) } />
                <Button placeholder="Voltar" variant="transparent-accent" onClick={props.back} />
            </Modal>
        </>
    )
}

export default RecoveryPasswordModal;