import { useState, useRef, useEffect } from 'react';
import styles from './ChangePassword.module.css';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Button from '../../../components/Button/Button';
import { validatePassword } from '../../../@utils/Validation/Validation';
import { useNavigate } from 'react-router-dom';
import ChangePasswordModal from '../../../modules/Modal/components/ChangePassword/ChangePasswordModal';
import { MdHelpCenter } from "react-icons/md";
import PasswordErrorModal from '../../../modules/Modal/components/ChangePassword/PasswordErrorModal';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { getBackendUrl } from '../../../config';

interface IData {
    lastPassword: string;
    newPassword: string;
    repeatNewPassword: string
}

function  ChangePassword (): JSX.Element {

    const baseUrl: string = getBackendUrl();

    const navigate = useNavigate();
   
    //Modal PopUp
    const openModal = useRef<HTMLDialogElement>(null);
    const errorModal = useRef<HTMLDialogElement>(null);
    
    const openModalFunc = () => {
        errorModal.current?.close();
        openModal.current?.showModal();
    }

    const errorModalFunc = () => {
        openModal.current?.close();
        errorModal.current?.showModal();
    }

    //Mostrar hover popup
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    // Mostrar senha
    const [showPasswordLastPassword, setShowPasswordLastPassword] = useState(false);
    const [showPasswordNewPassword, setShowPasswordNewPassword] = useState(false);
    const [showPasswordRepeatPassword, setShowPasswordRepeatPassword] = useState(false);

    const VisibilityLastPassword = () => {
        setShowPasswordLastPassword(!showPasswordLastPassword);
    };

    const VisibilityNewPassword = () => {
        setShowPasswordNewPassword(!showPasswordNewPassword);
    };

    const VisibilityRepeatPassword = () => {
        setShowPasswordRepeatPassword(!showPasswordRepeatPassword);
    };


    //Validações das senhas
    const [info, setInfo] = useState<IData>({
        lastPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    })

    const [currentPassword, setCurrentPassword] = useState(String);
    const [passwordValid, setPasswordValid] = useState(true);
    const [getPasswordValid, setGetPasswordValid] = useState('');
    const [comparePassword, setComparePassword] = useState (false);

    function handlePassword(event: any) {

        const newPassword = event.target.form.newPassword.value;
        const validNewPassword = validatePassword(newPassword);
        
        const repeatPassword = event.target.form.repeatPassword.value;
        const validRepeatPassword = validatePassword(repeatPassword);
        
        if (validNewPassword && validRepeatPassword) {
            if (newPassword === repeatPassword) {
                setInfo({...info, newPassword: newPassword});
                setPasswordValid(true);
                setGetPasswordValid(newPassword);
            } else {
                setPasswordValid(false)
            }
        } else {
            setPasswordValid(false);
        } 
        setIsFormValid(validateForm());
    }

    //Validação do botão disabled
    const [isFormValid, setIsFormValid] = useState(false);

    function validateForm() {
        const { newPassword, repeatNewPassword } = info;
        return currentPassword.trim() !== '' && newPassword.trim() !== '' && repeatNewPassword.trim() !== '';
    }

    //Submit
    async function handleSubmit(event:any) {
        event.preventDefault();

        if (!isFormValid) {
            return;
        }

        if (currentPassword === getPasswordValid) {
            setComparePassword(true);
            return;
        }

        setComparePassword(false);
        const result = await fetch(`${baseUrl}users/update-password`, {
            mode: 'cors',
            method: 'PATCH',
            credentials: 'include',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                lastPassword: currentPassword,
                newPassword: getPasswordValid
            }),
        })
        .then(response => {
            return response.json();
        })
        .then(data => {
            if (!('statusCode' in data)) {
                openModalFunc();
            }
            if ('statusCode' in data) {
                errorModalFunc();
            }
            return data;
        })
        .catch(error => {
        });
    }


    // Recarregar modal após fechado
	const [reloadPage, setReloadPage] = useState(false);

    const closeModal = () => {
        openModal.current?.close();
        reloadPageHandler(); 
    };

	const reloadPageHandler = () => {
	    setReloadPage(true);
	};

	useEffect(() => {
		if (reloadPage) {
			setReloadPage(false);
			window.location.reload(); // Recarrega a página
		}
	}, [reloadPage]);


    return (
    <div className={styles.containerDiv}>
        <nav className={ styles.heading }>
            <h1 className={styles.titleNav}>Configurações</h1>
            <Breadcrumb head="Configurações" pages={ ["Alteração de senha"] } />
        </nav>
        <ChangePasswordModal reference={openModal} send={()=> navigate('/home')} buttonClose={true} onClickButtonClose={closeModal}/>
        <PasswordErrorModal reference={errorModal}/>
        <form className={styles.containerForm}>
            <h1 className={styles.title}>Alterar Senha</h1>
            <p className={styles.password}>Senha</p>
            <div className={styles.container}>
                <div className={styles.inputGroup}>
                    <label className={styles.textLabel} htmlFor="password">Senha atual</label>
                    <div className={styles.divIconEye}>
                        <input 
                            type={showPasswordLastPassword ? 'text' : 'password'}
                            placeholder="**********"
                            id="password"
                            onChange={(e) => setCurrentPassword (e.target.value)}
                            required
                        />
                        <div className={styles.divEyesPassword}>
                            {showPasswordLastPassword ? (
                                <AiFillEye
                                className={styles.iconEye}
                                onClick={VisibilityLastPassword}
                                />
                            ) : (
                                <AiFillEyeInvisible
                                className={styles.iconEye}
                                onClick={VisibilityLastPassword}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <div className={styles.divLabel}>
                        <label className={styles.textLabel} htmlFor="new-password">Insira nova senha</label>
                        <MdHelpCenter className={styles.iconHelp} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                        {isHovering && (
                            <div className={styles.divPopupHover}>
                                <div className={styles.popupHover}>
                                    <p className={styles.modalParagraphTitle}>Digite uma senha com pelo menos:</p>
                                    <p>
                                        <ul>   
                                            <li className={styles.modalParagraph}>1 caractere minúscula</li>
                                            <li className={styles.modalParagraph}>1 caractere maiúscula</li>
                                            <li className={styles.modalParagraph}>1 número</li>
                                            <li className={styles.modalParagraph}>1 caractere especial</li>
                                            <li className={styles.modalParagraph}>12 caracteres no mínimo</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.divIconEye}>
                        <input
                            type={showPasswordNewPassword ? 'text' : 'password'}
                            placeholder="**********"
                            id="newPassword"
                            onChange={(e) => [setInfo({...info, newPassword: e.target.value})]}
                            required
                        />
                        <div className={styles.divEyesPassword}>
                            {showPasswordNewPassword ? (
                                <AiFillEye
                                className={styles.iconEye}
                                onClick={VisibilityNewPassword}
                                />
                            ) : (
                                <AiFillEyeInvisible
                                className={styles.iconEye}
                                onClick={VisibilityNewPassword}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.textLabel} htmlFor="confirm-new-password">Confirmação de nova senha</label>
                    <div className={styles.divIconEye}>
                        <input 
                            type={showPasswordRepeatPassword ? 'text' : 'password'}
                            placeholder="**********"
                            id="repeatPassword"
                            onChange={(e) => [setInfo({...info, repeatNewPassword: e.target.value})]}
                            onMouseLeave={handlePassword}
                            required
                        />
                        <div className={styles.divEyesPassword}>
                            {showPasswordRepeatPassword ? (
                                <AiFillEye
                                className={styles.iconEye}
                                onClick={VisibilityRepeatPassword}
                                />
                            ) : (
                                <AiFillEyeInvisible
                                className={styles.iconEye}
                                onClick={VisibilityRepeatPassword}
                                />
                            )}
                        </div>
                    </div>
                    {comparePassword && <p className={styles.passwordInvalid}>A nova senha não pode ser igual a anterior</p>}
                    {!passwordValid && <p className={styles.passwordInvalid}>Senha inválida</p>}
                </div>
            </div>
            <div className={styles.containerButton}>     
                <Button placeholder="Voltar" variant="neutral" onClick={() => navigate('/home')}/>
                <Button placeholder="Salvar nova senha" onClick={handleSubmit} enabled={!isFormValid}/>
            </div>
        </form>
    </div>
  )
}

export default ChangePassword;
