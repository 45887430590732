import { useContext } from "react";
import { SessionContext, SessionContextType } from "../../contexts/SessionContext/SessionContext";
import { Navigate, Outlet } from "react-router-dom";

interface IProperties {
    redirectPath: string;
}

function AdminRoutesMiddleware({ redirectPath }: IProperties) {
    const { session } = useContext(SessionContext) as SessionContextType;

    if (
        session.isLogged === false ||
        session.user === undefined ||
        session.user.type !== "Admin"
    ) {
        return <Navigate to={ redirectPath } replace />
    }

    return <Outlet />
}

export default AdminRoutesMiddleware;