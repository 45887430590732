import { MapContainer, TileLayer } from "react-leaflet";
import styles from "./Map.module.css";

interface IProperties {
    forwardRef?: React.RefObject<L.Map>;
}

function Map({ forwardRef }: IProperties) {
    return (
        <MapContainer
            ref={ forwardRef }
            className={ styles.mapContainer }
            center={[-3.465305, -62.215881]}
            zoom={5}
            scrollWheelZoom={false}
            dragging={false}
            preferCanvas={true}
            zoomControl={false}
        >
            <TileLayer
                attribution="&copy; Google"
                url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                maxZoom={20}
                minZoom={1}
                updateWhenZooming={false}
            />
        </MapContainer>
    );
}

export default Map;