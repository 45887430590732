import { ReactNode } from "react";
import Modal from "../../Modal";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    children: ReactNode;
    title: string
}

function ErrorModal(props:IProperties): JSX.Element { 
    return(
        <>
            <Modal forwardRef={ props.reference } title={props.title}>
                {props.children}    
            </Modal>
        </>
    )
}

export default ErrorModal;