export function validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function validatePassword(password: string): boolean {
    // Regex para validar a senha
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./])[A-Za-z\d-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./]{12,}$/;
    return passwordRegex.test(password);
  }

export function validateCPF(cpf: string): boolean {
    const cleanedCPF = cpf.replace(/\D/g, '');
    if (cleanedCPF.length !== 11) {
        return false;
      }
    if (/^(\d)\1+$/.test(cleanedCPF)) {
    return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cleanedCPF.charAt(i)) * (10 - i);
    }
    let firstDigit = 11 - (sum % 11);
    if (firstDigit === 10 || firstDigit === 11) {
        firstDigit = 0;
    }
    if (firstDigit !== parseInt(cleanedCPF.charAt(9))) {
        return false;
    }
    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cleanedCPF.charAt(i)) * (11 - i);
    }
    let secondDigit = 11 - (sum % 11);
    if (secondDigit === 10 || secondDigit === 11) {
        secondDigit = 0;
    }
    if (secondDigit !== parseInt(cleanedCPF.charAt(10))) {
        return false;
    }

    return true;
}

export function validateCNPJ(cnpj: string): boolean {
    const cleanedCNPJ = cnpj.replace(/\D/g, '');
    if (/^(\d)\1+$/.test(cleanedCNPJ)) {
        return false;
      }

    let sum = 0;
    let multiplier = 2;
    for (let i = 11; i >= 0; i--) {
        sum += parseInt(cleanedCNPJ.charAt(i)) * multiplier;
        multiplier = multiplier === 9 ? 2 : multiplier + 1;
    }
    const firstDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cleanedCNPJ.charAt(12)) !== firstDigit) {
        return false;
    }

    sum = 0;
    multiplier = 2;
    for (let i = 12; i >= 0; i--) {
        sum += parseInt(cleanedCNPJ.charAt(i)) * multiplier;
        multiplier = multiplier === 9 ? 2 : multiplier + 1;
    }
    const secondDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cleanedCNPJ.charAt(13)) !== secondDigit) {
        return false;
    }

    return true;
}

export function validateCEP(cep: string): boolean {
    const cepRegex = /^\d{5}-?\d{3}$/;
    return cepRegex.test(cep);
  }