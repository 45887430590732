import TAreaPreloaded from "../@types/areas/TAreaPreloaded";
import TArea from "../@types/areas/TArea";
import TCheckAccess from "../@types/checkout/TCheckAccess";

type ConvertType = "Preloaded" | "Monitoring";
type ConvertData = TAreaPreloaded[] | TArea;

export default function toGeoJSON(
    data: ConvertData,
    type: ConvertType,
    boughtAreas?: TCheckAccess,
    detectedAreas?: { unique_area_id: number }[]
): GeoJSON.FeatureCollection<any> {
    const geoJson: GeoJSON.FeatureCollection<any> = {
        type: "FeatureCollection",
        features: [],
    };

    let detectionArray: number[] | undefined;
    if (detectedAreas) {
        detectionArray = detectedAreas.map((area) => area.unique_area_id);
    } else {
        detectionArray = undefined
    }

    switch (type) {
        case "Preloaded": {
            let convertData: TAreaPreloaded[] = data as TAreaPreloaded[];

            convertData.forEach((preloaded) => {
                geoJson.features.push({
                    type: "Feature",
                    properties: {
                        id: preloaded.id,
                        area_id: preloaded.area_id,
                        offer: preloaded.Offer,
                        amount: preloaded.Offer.amount,
                        description: preloaded.description,
                        isPurchased: boughtAreas!.preloadeds.includes(preloaded.id) ? true : false,
                        hasDetections: (detectionArray && detectionArray.includes(preloaded.area_id)) ? true : false,
                        bounds: [[0, 0], [0, 0]],
                        center: [[0, 0], [0, 0]]
                    },
                    geometry: {
                        coordinates: preloaded.Area.geom.coordinates,
                        type: preloaded.Area.geom.type,
                    },
                });
            });
            return geoJson;
        }
        case "Monitoring": {

            let convertPolygn: TArea  = data as TArea ;

            convertPolygn.Greens!.forEach((preloaded) => {
                geoJson.features.push({
                    type: "Feature",
                    properties: {},
                    geometry: {
                        coordinates: preloaded.geom.coordinates,
                        type: preloaded.geom.type,
                    },
                });
            });
            return geoJson;
        }
    }

    return geoJson;
}
