import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./ChangeInfo.module.css";
import Input from "../../../components/Input/Input";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Button from "../../../components/Button/Button";
import { SessionContext, SessionContextType } from "../../../contexts/SessionContext/SessionContext";
import { maskCEP, maskPhone } from "../../../@utils/Masks/Masks";
import TAddress from "../../../@types/users/TAddress";
import TUser from "../../../@types/users/TUser";
import Users from "../../../services/users/Users.service";
import { validateCEP } from "../../../@utils/Validation/Validation";
import zipCodeSearch from "../../../services/apis";
import SwitchPlanSendModal from "../../../modules/Modal/components/SwitchPlan/SwitchPlanSendModal";
import { useNavigate } from "react-router-dom";
import MessageSuccessModal from "../../../modules/Modal/components/SwitchPlan/MessageSuccessModal";


function ChangeInfo() {
	const { session, update } = useContext(SessionContext) as SessionContextType;
	const [hasChanged, setHasChanged] = useState(false);
	const [changes, setChanges] = useState<{ user: boolean, phone: boolean, address: boolean }>({ user: false, phone: false, address: false }); 
	const [newChanges, setNewChanges] = useState<{
		newUser: { id: number, name: string, companyName: string },
		newPhone: string,
		newAddress: Omit<TAddress, "createdAt" | "active">
	}>({
		newUser: {
			id: 0,
			name: "",
			companyName: ""
		},
		newPhone: "",
		newAddress: {
			id: "",
			zip_code: "",
			location: "",
			number: 0,
			neighborhood: "",
			complement: "",
			city: "",
			state: "",
			country: "",
		}
	});

	const navigate = useNavigate();
	const user = session.user!;

	const successRef = useRef<HTMLDialogElement>(null)
	const errorRef = useRef<HTMLDialogElement>(null);

	const saveChanges = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		let newUser = newChanges.newUser;
		let newAddress = newChanges.newAddress;
		let newPhone = newChanges.newPhone;
		let failed: boolean = false;

		await Promise.allSettled([
			changes.user === true ? Users.UpdateUser({ name: newUser.name, companyName: newUser.companyName, id: user.id }) : Promise.reject(new Error('Dado não alterado')),
			changes.phone === true ? Users.UpdatePhone({ id: user.PhoneNumbers[0].id, phoneNumber: newPhone }) : Promise.reject(new Error('Dado não alterado')),
			changes.address === true ? Users.UpdateAddress({ ...newAddress, id: user.Addresses[0].id }) : Promise.reject(new Error('Dado não alterado')),
		]).then((responses) => responses.forEach((_) => {
			if (_.status === 'rejected') return;

			if ('statusCode' in _.value) {
				errorRef.current?.showModal();
				failed = true;
			}
		})).then(() => {
			if (failed === false) {
				successRef.current?.showModal();
				update(user.id)
			}
		});
	};

	const handleAddressChange = async (e: React.ChangeEvent<HTMLInputElement>, nAddress: Partial<Omit<TAddress, "createdAt" | "active">>) => {
		if (e.target.placeholder !== e.target.value && e.target.value !== "") {
			setHasChanged(true);
			setChanges({ ...changes, address: true });
	
			if (nAddress.zip_code) {
				newChanges.newAddress.zip_code = maskCEP(nAddress.zip_code);
				if (validateCEP(nAddress.zip_code)) {
					setCepValid(true);
				} else {
					setCepValid(false);
				}
			}
			
			if (nAddress.location) {
				newChanges.newAddress.location = nAddress.location;
			}
			
			if (nAddress.number) {
				newChanges.newAddress.number = nAddress.number;
			}
			
			if (nAddress.neighborhood) {
				newChanges.newAddress.neighborhood = nAddress.neighborhood;
			}
			
			if (nAddress.complement) {
				newChanges.newAddress.complement = nAddress.complement;
			}
			
			if (nAddress.city) {
				newChanges.newAddress.city = nAddress.city;
			}
			
			if (nAddress.state) {
				newChanges.newAddress.state = nAddress.state;
			}
			
			if (nAddress.country) {
				newChanges.newAddress.country = nAddress.country;
			}
		}
		else {
			setHasChanged(false);
			setChanges({ ...changes, address: false });
		}
	}

	const [phoneValue, setPhoneValue] = useState('')

	const handlePhoneChange = async (e: React.ChangeEvent<HTMLInputElement>, phone: string) => {
		if (e.target.placeholder !== e.target.value && e.target.value !== "") {
			setHasChanged(true);
			setChanges({ ...changes, phone: true });

			setPhoneValue(phone)

			let number: string = phone;
			let areaCode: string = number.substring(1,3);
			let phoneNumber: string = number.substring(4);
			// console.log(phoneNumber)

			newChanges.newPhone = `+55(${areaCode})${phoneNumber.replace('-' , '')}`;
			// console.log(newChanges.newPhone)
		}
		else {
			setHasChanged(false);
			setChanges({ ...changes, phone: false });
		}
	}

	const handleUserChange = async (e: React.ChangeEvent<HTMLInputElement>, nUser: Partial<Pick<TUser, "name" | "company_name">>) => {
		if (e.target.placeholder !== e.target.value && e.target.value !== "") {
			setHasChanged(true);
			setChanges({ ...changes, user: true });

			if (nUser.name) {
				newChanges.newUser.name = nUser.name!;
			}

			if (nUser.company_name) {
				newChanges.newUser.companyName = nUser.company_name!;
			}
		}
		else {
			setHasChanged(false);
			setChanges({ ...changes, user: false });
		}
	}

	const [numberError, setNumberError] = useState(false)
        
        function handleContactChange(event:any) {
        const phoneNumber = event.target.value;
        const finalNumber = phoneNumber.replace(/[^\d]/g, '');

        if (finalNumber.length >= 11) {
            setNumberError(false);
        } else {
            setNumberError(true);
        }
    }

	const [cep, setCep] = useState({
        bairro: "",
        localidade: "",
        logradouro: "",
        uf: "",
    });

	const [zipCode, setZipCode] = useState('');
	const [cepValid, setCepValid] = useState(true);

	async function handleZipCodeSearch(event:any) {  
        const zipCode = event.target.value;
        const validZipCode = validateCEP(zipCode);

        if (validZipCode === true) {
            try {
                const response = await zipCodeSearch.get(`${zipCode}/json`);
                setCep(response.data);
                setZipCode(zipCode);

				if (response.data.erro === true) {
					setCepValid(false);
				} else {
					newChanges.newAddress.city = response.data.localidade;
					newChanges.newAddress.neighborhood = response.data.bairro;
					newChanges.newAddress.location = response.data.logradouro;
					setCepValid(true);
				}
            } catch {
                console.error(Error);
                setCepValid(false);
            }
        } else {
            setCepValid(false);
        }
    };

	useEffect(() => {}, [])

	// Recarregar modal após fechado
	const [reloadPage, setReloadPage] = useState(false);

	const reloadPageHandler = () => {
	setReloadPage(true);
	};

	useEffect(() => {
		if (reloadPage) {
			setReloadPage(false);
			window.location.reload(); // Recarrega a página
		}
	}, [reloadPage]);

	return (
		<div>
			<main>
				<nav className={styles.heading}>
					<h1 className={styles.titleNav}>Configurações</h1>
					<Breadcrumb
						head="Configurações"
						pages={["Alterar Dados Cadastrais"]}
					/>
				</nav>
				<form className={styles.settingsForm}>
					<h2 className={styles.pageTitle}>
						Alterar dados cadastrais
					</h2>
					<h3 className={styles.sectionTitle}>Informações Gerais</h3>
					<div className={styles.row1}>
						<Input
							label={"Nome Completo"}
							type={"text"}
							placeholder={ user.name }
							name="name"
							onChange={(e) => handleUserChange(e, { name: e.target.value })}
							onBlur={handleZipCodeSearch}
						/>
						{session.user!.type === "Legal Customer" &&
							<Input
								label={"Nome Completo"}
								type={"text"}
								placeholder={ user.company_name! }
								name="company_name"
								onChange={(e) => handleUserChange(e, { company_name: e.target.value })}
							/>							
						}
						<span className={styles.contactInput}>
							<Input
								label={'Contato'}
								type={'tel'}
								placeholder={user.PhoneNumbers[0].country_code}
								disabled
							/>
							<Input
								label={''}
								type={'tel'}
								placeholder={"(" + user.PhoneNumbers[0].area_code + ") " + user.PhoneNumbers[0].number.substring(0, 5) + "-" + user.PhoneNumbers[0].number.substring(5, 9)}
								value={maskPhone(phoneValue)}
								onChange={(e) => handlePhoneChange(e, e.target.value)}
								onBlur={handleContactChange}
							/>
							{numberError &&
								<div className={styles.errorNumberContainer}>
									<span className={styles.errorNumber}>Número de telefone inválido</span>
								</div>
							}
						</span>
					</div>
					<h3 className={styles.sectionTitle}>Endereço</h3>
					<h4 className={styles.sectionSubtitle}>
						Insira o endereço da sua propriedade ou de sua
						residência.
					</h4>
					<div className={styles.row2}>
						<span className={styles.inputItem}>
							<Input
								label={"CEP"}
								type={"text"}
								placeholder={maskCEP(user.Addresses[0].zip_code)}
								value={newChanges.newAddress.zip_code}
								onChange={(e) => handleAddressChange(e,{ zip_code: maskCEP(e.target.value) })}
								onBlur={handleZipCodeSearch}
								maxLength={9}
							/>
							{!cepValid && 
								<span className={styles.error}>CEP inválido.</span>
							}
						</span>
						<Input
							label={"Cidade"}
							type={"text"}
							value={cep.localidade}
							placeholder={user.Addresses[0].city}
							// onChange={(e) => handleAddressChange(e, { city: e.target.value })}
						/>
						<Input
							label={"Número"}
							type={"text"}
							placeholder={String(user.Addresses[0].number)}
							onChange={(e) => handleAddressChange(e, { number: Number(e.target.value) })}
						/>
					</div>
					<div className={styles.row3}>
						<Input
							label={"Localização"}
							type={"text"}
							value={cep.logradouro}
							placeholder={user.Addresses[0].location}
							// onChange={(e) => handleAddressChange(e, { location: e.target.value })}
						/>
						<Input
							label={"Bairro"}
							type={"text"}
							value={cep.bairro}
							placeholder={user.Addresses[0].neighborhood}
							// onChange={(e) => handleAddressChange(e, { neighborhood: e.target.value })}
						/>
						<Input
							label={"Complemento"}
							type={"text"}
							placeholder={user.Addresses[0].complement}
							onChange={(e) => handleAddressChange(e, { complement: e.target.value })}
						/>
					</div>
					<div className={styles.buttons}>
						<Button
							onClick={() => window.open('/home')}
							placeholder="Voltar"
							variant="transparent-primary"
						/>
						<Button
							onClick={ (e) => saveChanges(e)}
							placeholder="Salvar Alterações"
							variant="filled-primary"
							enabled={ !hasChanged }
						/>
					</div>
				</form>
			</main>
			<MessageSuccessModal reference={successRef} reloadPageHandler={reloadPageHandler}/>
			<SwitchPlanSendModal reference={errorRef} title={"Erro"} text={"Não foi possível alterar os seus dados cadastrais."} />
		</div>
	);
}

export default ChangeInfo;
