import { ReactNode, createContext, useState } from "react";
import TSession from "../../@types/TSession";
import Auth from "../../services/auth/Auth.service";
import THttpRequestError from "../../@types/http/THttpRequestError";
import Users from "../../services/users/Users.service";
import TUser from "../../@types/users/TUser";
import { useCookies } from "react-cookie";

export type SessionContextType = {
    session: TSession;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => void;
    update: (userId: number) => void
}

export const SessionContext = createContext<SessionContextType | null>(null);

interface IProperties {
    children: ReactNode;
}

function SessionContextProvider(props: IProperties): JSX.Element {
    const [cookies, setCookie, removeCookie] = useCookies(['@spotgreen: user','@spotgreen: Access Token', '@spotgreen: Refresh Token']);
    const [session, setSession] = useState<TSession>({
        user: cookies["@spotgreen: user"] ? (cookies["@spotgreen: user"] as TUser) : undefined,
        isLogged: cookies["@spotgreen: user"] ? true : false
    });

    const login = async (email: string, password: string) => {
        const fetch: { id: number } | THttpRequestError = await Auth.Login(email, password);

        if ('statusCode' in fetch) return false;

        const fetchUser: TUser | THttpRequestError = await Users.View(fetch.id);

        if ('statusCode' in fetchUser) return false;

        setSession({ user: fetchUser, isLogged: true });
        setCookie('@spotgreen: user', fetchUser, { path: '/' });
        return true;
    }

    const update = async (userId: number) => {
        const fetchUser: TUser | THttpRequestError = await Users.View(userId);

        if ('statusCode' in fetchUser) return false;

        setSession({user: fetchUser, isLogged: true});
        removeCookie('@spotgreen: user');
        setCookie('@spotgreen: user', fetchUser, { path: '/'});
        return true;
    }

    const logout = () => {
        setSession({} as TSession);
        removeCookie('@spotgreen: user');
        removeCookie('@spotgreen: Access Token');
        removeCookie('@spotgreen: Refresh Token');
        window.location.href='/';
    }

    return (
        <SessionContext.Provider value={{ session, login, logout, update }}>
            { props.children }
        </SessionContext.Provider>
    );
}

export default SessionContextProvider;