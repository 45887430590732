import Modal from "../../Modal";
import styles from "./SwitchPlan.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    title: string;
    text: string
}

function SwitchPlanSendModal(props:IProperties): JSX.Element {

    return(
        <>
            <Modal forwardRef={ props.reference } title={props.title}>
                <p className={styles.paragraphCenter}>{props.text}</p>
            </Modal>
        </>
    )
}

export default SwitchPlanSendModal;