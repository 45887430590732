import { ReactNode } from "react";
import styles from "./Modal.module.css";
import { GrFormClose } from "react-icons/gr";

interface IProperties {
    title: string;
    subtitle?: string;
    forwardRef: React.RefObject<HTMLDialogElement>;
    children: ReactNode;
    buttonClose?: boolean
    onClickButtonClose?: () => void;
}

function Modal(properties: IProperties): JSX.Element {
    const close = () => {
        properties.forwardRef.current?.close();
    }

    return (
        <dialog ref={ properties.forwardRef } className={ styles.modal }>
            <span className={ styles.close } onClick={ properties.buttonClose === true ? properties.onClickButtonClose : close}><GrFormClose/></span>
            <div className={ styles.modalBody }>
                <div className={ styles.header }>
                    <h3>{ properties.title }</h3>
                    <p>{ properties.subtitle }</p>
                </div>
                { properties.children }
            </div>
        </dialog>
    );
}

export default Modal;