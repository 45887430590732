import { Fragment, useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../../components/Breadcrumb/Breadcrumb";
import styles from "./LegalCustomers.module.css";
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaEllipsisV } from "react-icons/fa";
import StatusTag from "../../../../components/StatusTag/StatusTag";
import TStatus from "../../../../@types/TStatus";
import { GrFormClose } from "react-icons/gr";
import Users from "../../../../services/users/Users.service";
import TUser from "../../../../@types/users/TUser";
import THistory from "../../../../@types/users/THistory";
import { maskCNPJ } from "../../../../@utils/Masks/Masks";
import THttpRequestError from "../../../../@types/http/THttpRequestError";
import TCoupon from "../../../../@types/users/TCoupon";
import formatDate from "../../../../@utils/formatDate";
import Input from "../../../../components/Input/Input";
import Button from "../../../../components/Button/Button";

function LegalCustomers(): JSX.Element {
    const [users, setUsers] = useState<TUser[]>([]);
    const [history, setHistory] = useState<THistory[]>([]);
    const [filter, setFilter] = useState<{ initialValue: TUser[], name?: string, status?: string}>({ initialValue: [], name: undefined, status: undefined });
    const [pages, setPages] = useState<TUser[][]>([])
    const [coupons, setCoupons] = useState<TCoupon[]>([]);
    const [toRender, setToRender] = useState<boolean>(false);
    const [areas, setAreas] = useState<string | undefined>(undefined)

    const historyRef = useRef<HTMLElement | null>(null);

    const openRow = (e: React.MouseEvent<SVGElement>) => {
        const rowId: string | undefined = e.currentTarget.dataset.row;

        if (rowId === undefined) return;

        const selectedRow: HTMLTableRowElement | null = document.querySelector<HTMLTableRowElement>(`[data-id="${ rowId }"]`);
        const hiddenContent: HTMLTableRowElement | null = document.querySelector<HTMLTableRowElement>(`[data-id="${ rowId }"] + tr`);

        if (selectedRow === null || hiddenContent === null) return;

        if (hiddenContent.style.display !== "table-row") {
            selectedRow.style.fontWeight = "bold";
            hiddenContent.style.display = "table-row";
            e.currentTarget.dataset.selected = "true"
        } else {
            selectedRow.style.fontWeight = "normal";
            hiddenContent.style.display = "none";
            e.currentTarget.dataset.selected = "false"
        };
    }

    const openDropdown = (e: React.MouseEvent<HTMLButtonElement>) => {
        const dropdown: HTMLUListElement | null = document.querySelector<HTMLUListElement>(`[data-button="${ e.currentTarget.dataset.id }"]`);

        if (dropdown === null) return;

        const isOpen = dropdown.dataset.open;

        if (isOpen === "false") dropdown.dataset.open = "true";
        else dropdown.dataset.open = "false";
    }

    const openHistory = async (i: TUser) => {
        await getHistory(i.id);
        historyRef.current!.style.right = "0%";

        const nameElement = document.querySelector<HTMLParagraphElement>('[data-name]');

        if (nameElement === null) return;

        nameElement.textContent = i.name;
    }

    const closeHistory = () => historyRef.current!.style.right = "-100%";

    const getHistory = async (id: number) => {
        const fetch: THistory[] = await Users.History(id);
        setHistory(fetch);
    }

    const getUsers = async (coupons: TCoupon[]) => {
        let users: TUser[] = [];

        for (const coupon of coupons) {
            let user: TUser | THttpRequestError = await Users.View(coupon.user_id);
            if ('statusCode' in user) return;
            users.push(user);
        }

        return users;
    }

    const getCoupons = async () => {
        const fetch: TCoupon[] | THttpRequestError = await Users.Coupons();

        if ('statusCode' in fetch) return;

        const users: TUser[] | undefined = await getUsers(fetch);

        if (users === undefined) return;

        setCoupons(fetch);
        setFilter({ ...filter, initialValue: users });
        genPagination(users);
    }

    const genPagination = (data: TUser[]) => {
        const pageSize = 10;
        const allPages: TUser[][] = [];
        
        for (let i = 0; i < data.length; i += pageSize) {
            const newPage = data.slice(i, i + pageSize);
            allPages.push(newPage);
        }

        setPages(allPages);
        setUsers(allPages[0]);
    }

    const handlePages = (action: "next" | "previous") => {
        const currentIndex = pages.indexOf(users);
        const numberOfPages = pages.length - 1;

        if (action === 'next') {
            if (currentIndex >= numberOfPages) return;
            else setUsers(pages[currentIndex + 1]);
        } else {
            if (currentIndex <= 0) return;
            else setUsers(pages[currentIndex - 1]);
        }
    }

    const areasToString = (ids: number[]) => {
        return ids.join(', ');
    }

    const updateCouponAreas = async (coupon: TCoupon) => {
        const areas_ids_string: string = areas ? areas : '';

        const areas_ids: number[] = areas_ids_string.replace(' ', '').split(',').map(value => {
            return value.replace(/\D/g, '');
        }).map(Number).filter(value => Number(value) !== 0);

        let couponBody: Omit<TCoupon, "id" | "user_id" | "metadata" |  "createdAt" | "updatedAt" | "deletedAt" | "offer_id" | "User"> = {
            status: coupon.status,
            areas_ids: {
                preloadedAreas: areas_ids,
                userAreas: coupon.areas_ids ? coupon.areas_ids.userAreas : []
            },
            expiration_date: coupon.expiration_date,
            expire_auto: coupon.expire_auto
        }

        await Users.UpdateCoupon(String(coupon.id), couponBody);
        setToRender(prev => (!prev));
    }

    const updateCouponDate = async (coupon: TCoupon, date: string) => {
        let couponBody: Omit<TCoupon, "id" | "user_id" | "metadata" |  "createdAt" | "updatedAt" | "deletedAt" | "offer_id" | "User"> = {
            status: coupon.status,
            areas_ids: coupon.areas_ids,
            expiration_date: date,
            expire_auto: coupon.expire_auto
        }

        await Users.UpdateCoupon(String(coupon.id), couponBody);
        setToRender(prev => (!prev));
    }

    const updateCoupon = async (coupon: TCoupon, status: "approved" | "canceled") => {
        let couponBody: Omit<TCoupon, "id" | "user_id" | "metadata" |  "createdAt" | "updatedAt" | "deletedAt" | "offer_id" | "User"> = {
            status: status,
            areas_ids: coupon.areas_ids,
            expiration_date: coupon.expiration_date,
            expire_auto: coupon.expire_auto
        }

        await Users.UpdateCoupon(String(coupon.id), couponBody);
        setToRender(prev => (!prev));
    }

    useEffect(() => {
        getCoupons();
    }, [toRender, setToRender]);

    useEffect(() => {
        let isNameUndefined = (filter.name === undefined) || (filter.name === "");
        let isStatusUndefined = (filter.status === undefined) || (filter.status === "default");
        let initialData = filter.initialValue;

        if (isNameUndefined && isStatusUndefined) {
            if (initialData.length === 0) return;
            genPagination(initialData);  
        } else if (!isNameUndefined && isStatusUndefined) {
            let filteredData: TUser[] = initialData.filter(x =>
                x.name.toLocaleLowerCase().includes(filter.name!.toLocaleLowerCase())  
            );
            genPagination(filteredData);
        } else if (isNameUndefined && !isStatusUndefined) {
            let filteredCoupons = coupons.filter(coupon => coupon.status === filter.status);
            let filteredUsers: TUser[] | undefined = []
            let filteredData: TUser[] = []

            for (const coupon of filteredCoupons) {
                filteredUsers = initialData.filter(user => user.id === coupon.user_id);

                if (filteredUsers) {
                    filteredData.push(filteredUsers[0]);
                }
            }

            genPagination(filteredData);
        } else if (!isNameUndefined && !isStatusUndefined) {
            let filteredCoupons = coupons.filter(coupon => coupon.status === filter.status);
            let filteredData: TUser[] = []
            let filteredUsers: TUser[] | undefined = []

            for (const coupon of filteredCoupons) {
                filteredUsers = initialData.filter(user => user.id === coupon.user_id && user.name === filter.name!.toLocaleLowerCase());

                if (filteredUsers) {
                    filteredData.push(filteredUsers[0]);
                }
            }
            
            genPagination(filteredData);
        }
    }, [filter.name, filter.status]);

    return (
        <>
            <nav className={ styles.heading }>
                <h1>Checkout</h1>
                <Breadcrumb head="Listagem" pages={ ["Usuários"] } />
            </nav>
            <section className={ styles.listagem }>
                <div className={ styles.table__filters }>
                    <label>
                        Nome
                        <input
                            type="text"
                            placeholder="E.g. John Doe"
                            onChange={ (e) => setFilter({ ...filter, name: e.target.value }) }
                        />
                    </label>
                    <label>
                        Status
                        <select
                            defaultValue="default"
                            onChange={ (e) => setFilter({ ...filter, status: (e.target.value as TStatus) }) }
                        >
                            <option value="default"></option>
                            <option value="approved">Aprovado</option>
                            <option value="pending">Pendente</option>
                            <option value="canceled">Cancelado</option>
                        </select>
                    </label>
                </div>
                <div className={ styles.table__wrapper }>
                    <table className={ styles.table }>
                        <colgroup>
                            <col span={ 1 } className={ styles.col__expand } />
                            <col span={ 1 } className={ styles.col__id } />
                            <col span={ 1 } className={ styles.col__name } />
                            <col span={ 1 } className={ styles.col__status } />
                            <col span={ 1 } className={ styles.col__actions } />
                        </colgroup>
                        <thead className={ styles.table__head }>
                            <tr>
                                <th>&#10240;</th>
                                <th>Id</th>
                                <th>Nome</th>
                                <th>Status</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody className={ styles.table__body }>
                            {users && users.map((i, k) => {
                                let coupon = coupons.filter(coupon => coupon.user_id === i.id)![0];
                                return (
                                    <Fragment key={k}>
                                        <tr className={styles.table__row} data-id={i.id}>
                                            <td><FaAngleDown onClick={(e) => openRow(e)} data-row={i.id} data-selected={false} /></td>
                                            <td>{i.id}</td>
                                            <td id="nome">{i.name}</td>
                                            <td>
                                                {coupon.status === "approved" && <StatusTag status={'Aprovado'} />}
                                                {coupon.status === "pending" && <StatusTag status={'Pendente'} />}
                                                {coupon.status === "canceled" && <StatusTag status={'Cancelado'} />}
                                            </td>
                                            <td>
                                                <button className={styles.dropdown} data-id={i.id} onClick={(e) => openDropdown(e)}>
                                                    <FaEllipsisV />
                                                    <ul className={styles.dropdown__links} data-button={i.id} data-open={false}>
                                                        {(coupon.status === "pending" || coupon.status === "canceled") && <li className={styles.dropdown__link} onClick={() => updateCoupon(coupon, "approved")}>Ativar</li>}
                                                        <li className={styles.dropdown__link} onClick={() => updateCoupon(coupon, "canceled")}>Cancelar</li>
                                                        <li className={styles.dropdown__link} onClick={() => openHistory(i)}>Histórico</li>
                                                    </ul>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr className={styles.table__row__hidden}>
                                            <td colSpan={5}>
                                                <div className={styles.row__hidden__content}>
                                                    <h3>Dados complementares</h3>
                                                    <div className={styles.hidden__content__user__info}>
                                                        <div className={styles.user__address__info}>
                                                            <p>
                                                                <span>CEP</span>
                                                                <span>{i.Addresses[0].zip_code}</span>
                                                            </p>
                                                            <p>
                                                                <span>Cidade</span>
                                                                <span>{i.Addresses[0].city}</span>
                                                            </p>
                                                            <p>
                                                                <span>Localização</span>
                                                                <span>{i.Addresses[0].location}</span>
                                                            </p>
                                                            <p>
                                                                <span>Número</span>
                                                                <span>{i.Addresses[0].number}</span>
                                                            </p>
                                                            <p>
                                                                <span>Bairro</span>
                                                                <span>{i.Addresses[0].neighborhood}</span>
                                                            </p>
                                                            <p>
                                                                <span>Complemento</span>
                                                                <span>{i.Addresses[0].complement}</span>
                                                            </p>
                                                        </div>
                                                        <div className={styles.user__general__info}>
                                                            <p>
                                                                <span>Nome</span>
                                                                <span>{i.name}</span>
                                                            </p>
                                                            <p>
                                                                <span>CNPJ</span>
                                                                <span>{maskCNPJ(i.Document.document)}</span>
                                                            </p>
                                                            <p>
                                                                <span>Telefone</span>
                                                                <span>{`${i.PhoneNumbers[0].country_code} (${i.PhoneNumbers[0].area_code}) ${i.PhoneNumbers[0].number}`}</span>
                                                            </p>
                                                            <p>
                                                                <span>Email</span>
                                                                <span>{i.Emails[0].email}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h3>Contrato</h3>
                                                    <div className={styles.hidden__content__contract__info}>
                                                        <div className={styles.contract__general__info}>
                                                            <p>
                                                                <span>Início</span>
                                                                <span>{ formatDate(new Date(coupon.createdAt)) }</span>
                                                            </p>
                                                            <p>
                                                                <Input label={"Fim"} type={"date"} onChange={(e) => updateCouponDate(coupon, e.target.value)} value={coupon.expiration_date} />
                                                            </p>
                                                            <p>
                                                                <span className={ styles.buttonSpan }>
                                                                    <Input label={"Áreas"} type="text" onChange={(e) => setAreas(e.target.value)} placeholder="1, 2, 3..." />
                                                                    <Button placeholder="Salvar" variant="empty-primary" onClick={ () => updateCouponAreas(coupon) } />
                                                                </span>
                                                                <small>IDs separado por vírgula.</small>
                                                                { coupon.areas_ids && <small>Áreas atuais: { areasToString(coupon.areas_ids.preloadedAreas) }</small>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment >
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={ styles.table__pagination }>
                    <button onClick={ () => handlePages("previous") }><FaAngleLeft /> Anterior</button>
                    <button onClick={ () => handlePages("next") }>Próximo <FaAngleRight /></button>
                </div>
            </section>
            <aside ref={ historyRef } className={ styles.history }>
                <span className={ styles.history__title }>
                    <GrFormClose onClick={ closeHistory } />
                    <h3>Histórico</h3>
                </span>
                <span className={ styles.history__user__name }>
                    Usuário: <p data-name></p>
                </span>
                <ul className={ styles.history__data }>
                    {history.map((i, k) => (
                        <li key={ k } data-type={ 'Success' }>
                            <p>{ i.action }</p>
                            <p>{ i.when }</p>
                        </li>
                    ))}
                </ul>
            </aside>
        </>
    );
}

export default LegalCustomers;