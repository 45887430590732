import THeadersContentType from "../@types/http/THeadersContentType";
import THttpRequestError from "../@types/http/THttpRequestError";

abstract class API {
    public static async Get<T>(
        url: string,
        token?: string
    ): Promise<T> {
        const response: Promise<T> = await fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => res.json())
            .catch(() => {
                return {
                    statusCode: '503',
                    timestamp: new Date().toISOString(),
                    path: url.slice(21)
                } as THttpRequestError;
            });

        return response;
    }

    public static async Post<T>(
        url: string,
        body?: BodyInit | null,
        contentType: THeadersContentType = 'application/json',
        token?: string
    ): Promise<T> {
        const response: Promise<T> = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': contentType,
                'Authorization': `Bearer ${token}`
            },
            body: body,
        })
            .then((res) => res.json())
            .catch(() => {
                return {
                    statusCode: '503',
                    timestamp: new Date().toISOString(),
                    path: url.slice(21)
                } as THttpRequestError;
            });

        return response;
    }

    public static async Delete<T>(
        url: string
    ): Promise<T> {
        const response: Promise<T> = await fetch(url, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then((res) => res.json())
            .catch(() => {
                return {
                    statusCode: '503',
                    timestamp: new Date().toISOString(),
                    path: url.slice(21)
                } as THttpRequestError;
            });

        return response;
    }

    public static async Put<T>(
        url: string,
        body?: BodyInit | null,
        contentType: THeadersContentType = 'application/json',
        token?: string
    ): Promise<T> {
        const response: Promise<T> = await fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': contentType,
                'Authorization': `Bearer ${token}`
            },
            body: body
        })
            .then((res) => res.json())
            .catch(() => {
                return {
                    statusCode: '503',
                    timestamp: new Date().toISOString(),
                    path: url.slice(21)
                } as THttpRequestError;
            });

        return response;
    }

    public static async Patch<T>(
        url: string,
        body?: BodyInit | null,
        contentType: THeadersContentType = 'application/json'
    ): Promise<T> {
        const response: Promise<T> = await fetch(url, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': contentType
            },
            body: body
        })
            .then((res) => res.json())
            .catch(() => {
                return {
                    statusCode: '503',
                    timestamp: new Date().toISOString(),
                    path: url.slice(21)
                } as THttpRequestError;
            });

        return response;
    }
}

export default API;