import TOpticVisualization from "../../@types/areas/tiles/TOpticVisualization";
import TRadarVisualization from "../../@types/areas/tiles/TRadarVisualization";
import THttpRequestError from "../../@types/http/THttpRequestError";
import API from "../API";
import URLMapping from "../URLMapping";

abstract class Gatherer {
    public static async Optic(areaId: number, date?: string) {
        if (date !== undefined) {
            return await API.Get<TOpticVisualization | THttpRequestError>(URLMapping.Gatherer.Optic + date + '/' + areaId);
        }

        return await API.Get<TOpticVisualization | THttpRequestError>(URLMapping.Gatherer.Optic + areaId);
    }

    public static async Radar(areaId: number, date?: string) {
        if (date !== undefined) {
            return await API.Get<TRadarVisualization | THttpRequestError>(URLMapping.Gatherer.Radar + date + '/' + areaId);
        }

        return await API.Get<TRadarVisualization | THttpRequestError>(URLMapping.Gatherer.Radar + areaId);
    }
}

export default Gatherer;