import { useNavigate } from "react-router-dom";
import Button from "../../../../components/Button/Button";
import Modal from "../../Modal";
import styles from "./SwitchPlan.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    reloadPageHandler: () => void;
}

function MessageSuccessModal(props:IProperties): JSX.Element {

    const navigate = useNavigate ()

    const closeModal = () => {
        props.reference.current?.close();
        props.reloadPageHandler(); 
    };

    return(
        <>
            <Modal forwardRef={ props.reference } title={'Sucesso!'} buttonClose={true} onClickButtonClose={closeModal}>
                <p className={styles.messageSuccess}>Seus dados cadastrais foram alterados com sucesso!</p>
                <Button placeholder="Voltar para home" onClick={() => navigate('/home')} variant="filled-primary"/>
            </Modal>
        </>
    )
}

export default MessageSuccessModal;