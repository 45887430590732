import L, { ImageOverlay, MapOptions, PathOptions } from "leaflet";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MapContainer, TileLayer, ScaleControl, GeoJSON } from "react-leaflet";
import styles from "./Home.module.css";
import Button from "../../../components/Button/Button";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Areas from "../../../services/areas/Areas.service";
import THttpRequestError from "../../../@types/http/THttpRequestError";
import Gatherer from "../../../services/gatherer/Gatherer.service";
import TAreaPreloaded from "../../../@types/areas/TAreaPreloaded";
import toGeoJSON from "../../../@utils/GeoJsonConverter";
import TCheckAccess from "../../../@types/checkout/TCheckAccess";
import Checkout from "../../../services/checkout/Checkout.service";
import ToMonitorModal from "../../../modules/Modal/components/Monitore/ToMonitorModal";
import TOpticVisualization from "../../../@types/areas/tiles/TOpticVisualization";
import TRadarVisualization from "../../../@types/areas/tiles/TRadarVisualization";
import TArea from "../../../@types/areas/TArea";
import flag from "../../../assets/Flag.svg";
import attentionFlag from "../../../assets/AttentionFlag.svg";
import {SessionContext, SessionContextType} from "../../../contexts/SessionContext/SessionContext";
import { MdHelpCenter } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import TOffer from "../../../@types/TOffer";


interface IState {
    purchased: TCheckAccess;
    grid: GeoJSON.FeatureCollection<GeoJSON.Geometry, any>;
    map: MapOptions;
    filters: {
        areas: "all" | "green" | "prode";
        isRadar: boolean;
        date?: string;
        feature?: GeoJSON.Feature<GeoJSON.Geometry, any>;
        layer?: L.Layer;
        geoJsonLayerGroup?: L.LayerGroup<any>;
    };
    tiles: {
        tile1?: L.TileLayer;
        tile1Url: string;
        tile2?: L.TileLayer;
        tile2Url: string;
    },
    offer?: object;
    area_id?: string;
}

function Home(): JSX.Element {
    const navigate = useNavigate();

    const [state, setState] = useState<IState>({
        map: {
            center: [-23.199653049107635, -45.87095632161257],
            zoom: 5,
            maxBounds: [[5.75955, -75.175514], [-17.063375, -47.959368]]
        },
        purchased: { preloadeds: [], users: [] },
        grid: {
            type: "FeatureCollection",
            features: []
        },
        filters: {
            areas: "all",
            isRadar: false,
            date: undefined,
            feature: undefined,
            layer: undefined
        },
        tiles: {
            tile1: undefined,
            tile1Url: '',
            tile2: undefined,
            tile2Url: ''
        },
        offer: undefined,
    });

    const [currentSelection, setCurrentSelection] = useState<{ layer?: L.LayerGroup<any>, image?: L.ImageOverlay}>({ layer: undefined, image: undefined });

    const { session } = useContext(SessionContext) as SessionContextType;

    const [toRender, setToRender] = useState<boolean>(false);

    const mapRef = useRef<L.Map>(null);
    const geoJsonRef = useRef<L.GeoJSON>(null);
    const toMonitorModalRef = useRef<HTMLDialogElement>(null);

    const [loading, setLoading] = useState (false);
    const [checkoutCloudFree, setCheckoutCloudFree] = useState(true)
    const [overlay, setOverlay] = useState<ImageOverlay | undefined>(undefined);

    let clickedPolygon: number | undefined = undefined;

    const getFeatureTiles = async () => {
        let filters = state.filters;
        if (filters.layer === undefined || filters.feature === undefined || mapRef.current === null) return;

        if (state.tiles.tile1 !== undefined) {
            state.tiles.tile1.remove();
            if (state.tiles.tile2 !== undefined) state.tiles.tile2.remove();
            setState({ ...state, filters: { ...state.filters, layer: undefined, feature: undefined } })
        }

        if (filters.isRadar) {
            const fetch: TRadarVisualization | THttpRequestError = await Gatherer.Radar(filters.feature.properties.area_id, filters.date);

            if ('statusCode' in fetch) return;

            let mapLayer = L.tileLayer(fetch.map, {
                bounds: filters.feature.properties.bounds
            });


            setState({ ...state, tiles: { tile1: mapLayer, tile1Url: fetch.map, tile2: undefined, tile2Url: fetch.map } });
            mapLayer.addTo(mapRef.current);
        } else {
            const fetch: TOpticVisualization | THttpRequestError = await Gatherer.Optic(filters.feature.properties.area_id, filters.date);
            if ('statusCode' in fetch) return;
            setCheckoutCloudFree(false) // Habilita botão Cloud free
            let landsatLayer = L.tileLayer(fetch.landsat, {
                bounds: filters.feature.properties.bounds
            });

            let sentinelLayer = L.tileLayer(fetch.sentinel, {
                bounds: filters.feature.properties.bounds
            });

            setState({ ...state, tiles: { tile1: sentinelLayer, tile1Url: fetch.sentinel, tile2: landsatLayer, tile2Url: fetch.landsat } });
            landsatLayer.addTo(mapRef.current);
            sentinelLayer.addTo(mapRef.current);
        }
        setLoading(false)  //Finaliza o loading do poligono e cloud free
    };

    const toMonitorModalAction = async () => {
        if (state.offer === undefined) return;
        if (state.area_id === undefined) return;

        if ((state.offer as TOffer).amount === 0) {
            let getFreeArea: TFreeArea | THttpRequestError = await Areas.FreeArea(state.area_id, `${session.user!.id}`);

            if ('statusCode' in getFreeArea) return;
            else if (getFreeArea.status === "approved") {
                document.location.reload();
            }
        } else {
            navigate("/checkout", {
                state: { offer: state.offer }
            });
        }

    }

    const clearSelection = () => {
        if (mapRef.current === null) return;
        
        if (state.tiles.tile1 !== undefined) {
            state.tiles.tile1.remove();
            if (state.tiles.tile2 !== undefined) state.tiles.tile2.remove();
            setState({ ...state, filters: { ...state.filters, layer: undefined, feature: undefined } })
        }

        if (currentSelection.layer !== undefined) {
            currentSelection.layer.removeFrom(mapRef.current);
        }

        if (currentSelection.image !== undefined) {
            currentSelection.image.addTo(mapRef.current)
        }
        
        setCurrentSelection({ layer: undefined, image: undefined });

        if (overlay) overlay.addTo(mapRef.current);

        mapRef.current.setMaxZoom(8);
        mapRef.current.setMinZoom(5);
        mapRef.current.setZoom(5);
        mapRef.current.setView([-3.465305, -62.215881], 5);
        mapRef.current.setMaxBounds([[ -59.361139531980314, -169.20202018337352], [ 83.02280513238486, 188.14344396406074]]);
    };

    const clearFilter = async () => {
        const dateInput = document.getElementById('date-input') as HTMLInputElement;
    
        if (dateInput) {
            dateInput.value = '';
        }
    
        setState({
            ...state,
            filters: {
                ...state.filters,
                date: undefined
            }
        });

        if (mapRef.current === null) return;

        if (state.tiles.tile1 !== undefined) {
            state.tiles.tile1.remove();
            if (state.tiles.tile2 !== undefined) state.tiles.tile2.remove();
            setState({ ...state, filters: { ...state.filters, layer: undefined, feature: undefined } })
        }

        let filters = state.filters;
        if (filters.layer === undefined || filters.feature === undefined || mapRef.current === null) return;
        
        if(!(filters.isRadar)) {

            setLoading(true)

            const fetch: TOpticVisualization | THttpRequestError = await Gatherer.Optic(filters.feature.properties.area_id, undefined);
                if ('statusCode' in fetch) return;
                setCheckoutCloudFree(false) // Habilita botão Cloud free
                let landsatLayer = L.tileLayer(fetch.landsat, {
                    bounds: filters.feature.properties.bounds
                });
    
                let sentinelLayer = L.tileLayer(fetch.sentinel, {
                    bounds: filters.feature.properties.bounds
                });
    
                setState({ ...state, tiles: { tile1: sentinelLayer, tile1Url: fetch.sentinel, tile2: landsatLayer, tile2Url: fetch.landsat } });
                landsatLayer.addTo(mapRef.current);
                sentinelLayer.addTo(mapRef.current);
        }

        setLoading(false)
        
    }

    const preloadedValidation = async (
        e: L.LeafletMouseEvent,
        layer: L.Layer,
        feature: GeoJSON.Feature<GeoJSON.Geometry, any>
    ) => {
        if (mapRef.current === null) return;
        let map = mapRef.current;
        let minZoom = 10;

        if (e.target.feature.properties.isPurchased) {
            layer.closeTooltip();
        }

        if (clickedPolygon !== e.target.feature.properties.area_id || clickedPolygon === undefined || mapRef.current.getMinZoom() !== minZoom) {
            clickedPolygon = e.target.feature.properties.area_id;
        } else {
            return;
        }

        async function fetchData() {
            if (feature.properties.isPurchased && state.filters.areas) {  
                const area: TArea | THttpRequestError = await Areas.Monitoring(feature.properties.area_id);
    
                if ('statusCode' in area) return;

                feature.properties.imgOvl.setStyle({
                    opacity: 0
                });

                const geoJsonData = toGeoJSON(area, 'Monitoring');
                let geoJsonLayerG = new L.LayerGroup();

                geoJsonLayerG.addTo(map);

                L.geoJSON(geoJsonData, {
                    style: {
                        color: 'red',
                        fillOpacity: .1
                    }
                }).addTo(geoJsonLayerG);
                setCurrentSelection({ layer: geoJsonLayerG, image: feature.properties.imgOvl });
            }
        }

        fetchData();
        
        if (feature.properties.isPurchased) {
            feature.properties.bounds = e.target.getBounds();
            feature.properties.center = e.target.getBounds().getCenter();

            setOverlay(feature.properties.imgOvl);
            map.removeLayer(feature.properties.imgOvl);

            map.setView(e.target.getBounds().getCenter());
            map.setMinZoom(minZoom);
            map.setMaxBounds(e.target.getBounds());
            map.fitBounds(e.target.getBounds());

            setTimeout(() => {
                map.setMinZoom(map.getZoom());
                map.setMaxZoom(20)
            }, 2000);


            setState({ ...state, filters: { ...state.filters, layer: layer, feature: feature } })
            setToRender(prev => (!prev));
        } else {
            if (toMonitorModalRef.current === null) return;
            setState({ ...state, offer: feature.properties.offer, area_id: feature.properties.area_id })
            toMonitorModalRef.current.showModal();
        }
    };

    const eachFeature = (
        feature: GeoJSON.Feature<GeoJSON.Geometry, any>,
        layer: L.Layer,
    ) => {
        if (mapRef.current === null) return;
        let map = mapRef.current;

        layer.bindTooltip(`<b>Valor</b>R$:${ (feature.properties.amount / 100).toFixed(2) }</br><b>Descrição</b>: ${feature.properties.description}`, { sticky: true }).openTooltip();

        layer.on('add', (e) => {
            if (feature.properties.isPurchased) {
                let imgOvl = L.imageOverlay(flag, e.target.getBounds(), {
                    zIndex: 10
                }).addTo(map);
                e.target.feature.properties.imgOvl = imgOvl;
            } else if (feature.properties.hasDetections) {
                let imgOvl = L.imageOverlay(attentionFlag, e.target.getBounds(), {
                    zIndex: 10
                }).addTo(map);
                e.target.feature.properties.imgOvl = imgOvl;
            }
        })

        layer.on("mouseover", (e) => {
            if (e.target.feature.properties.isPurchased) layer.closeTooltip();
        });

        layer.on("click", (e) => preloadedValidation(e, layer, feature));
    };

    const featureStyling = (e: GeoJSON.Feature<GeoJSON.Geometry, any> | undefined) => {
        let boughtAreas = state.purchased.preloadeds;
        let weight = 2;
        let opacity = 1;
        let strokeColor = "#C9E9C9";
        let purchasedColor = "transparent"
        let preloadedColor = "transparent";

        if (e === undefined) return { weight: 1, color: strokeColor, fillColor: preloadedColor, opacity: opacity } as PathOptions;

        if (boughtAreas.includes(e.properties.id)) {
            e.properties.isPurchased = true;
            return { weight: weight, color: strokeColor, fillColor: purchasedColor, }
        }

        return { weight: 1, color: strokeColor, fillColor: preloadedColor, opacity: opacity } as PathOptions;
    }

    const grid = async () => {
        const preloadeds: TAreaPreloaded[] | THttpRequestError = await Areas.ListPreloaded();
        let gridPreloadeds: TAreaPreloaded[];

        if ("statusCode" in preloadeds) {
            gridPreloadeds = []
        } else {
            gridPreloadeds = preloadeds
        };

        const boughtAreas: TCheckAccess | THttpRequestError = await Checkout.CheckAccess();
        let purchasedAreas: TCheckAccess;

        if ("statusCode" in boughtAreas) {
            purchasedAreas = { preloadeds: [], users: [] };
        } else {
            purchasedAreas = boughtAreas;
        }

        const detectedAreasFetch: { unique_area_id: number }[] | THttpRequestError = await Areas.Focus();
        let detectedAreas: { unique_area_id: number }[];

        if ("statusCode" in detectedAreasFetch) {
            detectedAreas = [];
        } else {
            detectedAreas = detectedAreasFetch
        }

        let preloadedGeoJSON = toGeoJSON(gridPreloadeds, "Preloaded", purchasedAreas, detectedAreas);
        setState({ ...state, purchased: purchasedAreas, grid: preloadedGeoJSON });
    };

    useEffect(() => {
        grid();
    }, []);

    useEffect(() => {
        if (geoJsonRef.current === null) return;

        if (geoJsonRef.current.getLayers().length > 0) return;

        geoJsonRef.current.addData(state.grid);
    }, [state.grid, state.purchased]);

    useEffect(() => {
    
        let filters = state.filters;
        if (filters.layer === undefined || filters.feature === undefined) return;

        setLoading(true)

        getFeatureTiles();
        
    }, [state.filters.isRadar, state.filters.date, toRender]);

    const handleClick = () => {
        if (state.filters.feature === undefined) return;
        let interval: NodeJS.Timer | undefined;

        
        if (!state.tiles.tile1) {
            if (!interval) clearInterval(interval);
            interval = setInterval(() => {
                if (state.tiles.tile1) {
                    clearInterval(interval);
                    if (state.filters.feature === undefined) return;
                    navigate(`/report/${state.filters.feature.properties.area_id}`, {
                        state: { tl1: state.tiles.tile1Url, tl2: state.tiles.tile2Url }
                    });
                }
            }, 1000);
        } else {
            navigate(`/report/${state.filters.feature.properties.area_id}`, {
                state: { tl1: state.tiles.tile1Url, tl2: state.tiles.tile2Url }
            });
        }
    };

    //Mostrar div dica
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <>
            <nav className={styles.heading}>
                <h1>Home</h1>
                <Breadcrumb head="Home" pages={["Monitoramento"]} />
            </nav>
            {loading && (
                <div className={styles.containerImageLoading}>
                    <div className={styles.divImageLoading}>
                        <AiOutlineLoading3Quarters className={styles.imageLoading}/>
                    </div>
                </div>
            )}
            <section className={styles.sctMap}>
                <div className={styles.sctMap__title}>
                    <h2>Monitoramento da floresta amazônica</h2>
                    <p>
                        Com o SpotGreen, é possível monitorar diferentes áreas da amazônia. Em nosso mapa, as áreas que você já possui 
                        e monitora são identificadas com uma bandeira e as áreas com focos de desmatamento encontrados pelo nosso 
                        sistema são identificados com um ponto de exclamação. Caso deseje adquirir mais alguma área, basta 
                        selecioná-la no mapa!
                    </p>
                </div>
                <MapContainer className={styles.sctMap__view} id="map" ref={mapRef} center={[-3.465305, -62.215881]} zoom={5} minZoom={4} maxZoom={6} preferCanvas={true} maxBounds={[[ -59.361139531980314, -169.20202018337352], [ 83.02280513238486, 188.14344396406074]]}>
                    <TileLayer
                        attribution="&copy; Google"
                        url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        subdomains={["mt0", "mt1", "mt2", "mt3"]}
                        updateWhenZooming={false}
                    />
                    <ScaleControl />
                    <GeoJSON
                        ref={geoJsonRef}
                        data={state.grid}
                        onEachFeature={(feature, layer) => eachFeature(feature, layer)}
                        style={(e) => featureStyling(e)}
                    />
                </MapContainer>
                <div className={styles.sctMap__controllers}>
                    <div className={styles.sctMap__controllers__periods}>
                        <h3>Período</h3>
                        <div className={styles.periods__wrapper}>
                            <label className={styles.date}>
                                Início
                                <input
                                    id="date-input"
                                    type="date"
                                    min={"2000-01-01"}
                                    max={new Date().toISOString().split('T')[0]}
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            filters: { ...state.filters, date: e.target.value }
                                        })
                                    }
                                    disabled={checkoutCloudFree === true? true : false}
                                />
                            </label>
                        </div>
                        <label className={styles.checkbox}>
                            <input
                                type="checkbox"
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        filters: { ...state.filters, isRadar: e.target.checked }
                                    })
                                }
                                disabled={checkoutCloudFree === true? true : false}
                            />
                            Cloud free
                            <div className={styles.divLabel}>
                                <MdHelpCenter className={styles.iconHelp} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                                {isHovering && (
                                    <div className={styles.divPopupHover}>
                                        <div className={styles.popupHover}>
                                            <p className={styles.modalParagraphTitle}>Visualização de radar</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </label>
                    </div>
                    <hr />
                    <div className={styles.actionButtons}>
                        { session.user!.type === "Legal Customer" || session.user!.type === 'Admin' ? (
                            <Button
                                icon="report"
                                variant="filled-primary"
                                placeholder="Relatório"
                                onClick={handleClick}
                                enabled={checkoutCloudFree === true? true : false}
                            />
                            ): ('')
                        }
                        <Button
                            placeholder="Mapa geral"
                            variant="empty-primary"
                            onClick={clearSelection}
                            enabled={checkoutCloudFree === true? true : false}
                        />
                        <Button
                            placeholder="Limpar filtro"
                            variant="empty-primary"
                            onClick={clearFilter}
                            enabled={checkoutCloudFree === true ? true : false}
                        />
                    </div>
                </div>
            </section >
            <ToMonitorModal
                forwardRef={toMonitorModalRef}
                action={() => toMonitorModalAction()}
            />
        </>
    );
}

export default Home;
