import { IconType } from "react-icons";
import { FaCircleNotch, FaShareAlt, FaChartBar, FaFilePdf } from "react-icons/fa";
import styles from "./Button.module.css";

type ButtonVariants = "filled-primary" | "empty-primary" | "transparent-primary" |
                      "filled-secondary" | "empty-secondary" | "transparent-secondary" |
                      "filled-accent" | "empty-accent" | "transparent-accent" |
                      "filled-gray" | "empty-gray" | "transparent-gray" |
                      "filled-error" | "empty-error" | "transparent-error" |
                      "neutral" ;

interface IProperties {
    placeholder?: string;
    icon?: string;
    variant?: ButtonVariants;
    iconRight?: boolean;
    noPadding?: boolean;
    enabled?: boolean;
    onClick: (event:any) => void;
}

function Button({
    onClick,
    icon,
    placeholder = "Text Here",
    variant = "filled-primary",
    iconRight =  false,
    noPadding = false,
    enabled = false,

}: IProperties): JSX.Element {
    let Icon: IconType | undefined = undefined;

    const mapIcons = (): { [key: string]: IconType } => {
        return {
            default: FaCircleNotch,
            share: FaShareAlt,
            report: FaChartBar,
            pdf: FaFilePdf
        }
    }

    if (icon !== undefined) Icon = mapIcons()[icon];

    return (
        <button onClick={ onClick } disabled={enabled} className={`${ styles.button } ${ styles[variant] } ${ noPadding ? styles.noPadding : styles.padding } ${ iconRight ? styles.right : styles.left } ${enabled ? styles.disabled : ""}`}>
            { Icon !== undefined ? <Icon className={ styles.icon } /> : '' }
            <span>{ placeholder }</span>
        </button>
    );
}

export default Button;