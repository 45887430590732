import { Route, Routes } from "react-router-dom";
import Landing from "../../pages/public/Landing/Landing";
import Company from "../../pages/public/signUp/Company/Company";
import Producer from "../../pages/public/signUp/Producer/Producer";
import PrivacyPolicy from "../../pages/public/PrivacyPolicy/PrivacyPolicy";
import LApp from "../../layouts/app/LApp";
import CookiePolicy from "../../pages/public/CookiePolicy/CookiePolicy";
import TermsAndConditions from "../../pages/public/TermsAndConditions/TermsAndConditions";

function PublicRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={ <LApp /> }>
                <Route index element={ <Landing /> } />
                <Route path="recovery-password" element={ <Landing /> } />
                <Route path="email-confirm" element={ <Landing /> } />
                <Route path="privacy-policy" element={ <PrivacyPolicy /> } />
                <Route path="cookie-policy" element={ <CookiePolicy /> } />
                <Route path="terms-and-conditions" element={ <TermsAndConditions /> } />
            </Route>
            <Route path="signup">
                <Route path="producer" element={ <Producer /> } />
                <Route path="company" element={ <Company /> } />
            </Route>
        </Routes>
    )
}

export default PublicRoutes;