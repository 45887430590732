import { useState, useEffect, useRef, forwardRef } from 'react';
import L, { LatLngBoundsExpression } from "leaflet";
import styles from './Report.module.css';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import * as html2pdf from 'html2pdf.js';
import Button from '../../../components/Button/Button';
import { useLocation, useParams } from 'react-router-dom';
import Areas from '../../../services/areas/Areas.service';
import TArea from '../../../@types/areas/TArea';
import THttpRequestError from '../../../@types/http/THttpRequestError';
import toGeoJSON from '../../../@utils/GeoJsonConverter';
import TOpticVisualization from '../../../@types/areas/tiles/TOpticVisualization';
import Gatherer from '../../../services/gatherer/Gatherer.service';
import Map from '../../../components/Map/Map';
import invertLatLgn from '../../../@utils/InvertLatLng';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function Report(): JSX.Element {
    const [hectares, setHectares] = useState<number>(0);
    const [date, setDate] = useState('');

    const [data, setData] = useState<{
        url1?: string,
        url2?: string,
        monitoring: GeoJSON.FeatureCollection<GeoJSON.Geometry, any>
    }>({
        url1: undefined,
        url2: undefined,
        monitoring: { type: "FeatureCollection", features: [] }
    });

    const { areaId } = useParams();
    const location = useLocation();
    if (!location.state) window.location.href = "/home";

    const map1Ref = useRef<L.Map>(null);
    const map2Ref = useRef<L.Map>(null);

    useEffect(() => {
        async function fetchData() {
            if (areaId !== undefined) {
                const area: TArea | THttpRequestError = await Areas.Monitoring(areaId);
                
                if ('statusCode' in area) return;
                
                setLoading(true)

                const geoJson = toGeoJSON(area, 'Monitoring');

                L.geoJSON(geoJson, {style: { color: 'red', fillOpacity: .1 }}).addTo(map2Ref.current!);

                const areas = area.Greens!;
                let hec: number = 0;

                areas.forEach((area: any) => {
                    hec += parseFloat(area.area_hec);
                });

                const dataObj = new Date(area.createdAt);
                const dia = String(dataObj.getDate()).padStart(2, '0');
                const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
                const ano = dataObj.getFullYear();

                const dataFormatada = `${dia}-${mes}-${ano}`;

                setDate(dataFormatada);
                setHectares(hec);

                const fetch: TOpticVisualization | THttpRequestError = await Gatherer.Optic(Number(areaId), "2023-08-24");
                
                if ('statusCode' in fetch) return;

               // console.log(fetch)

                if (location.state.tl2) {
                    let landsatLayer = L.tileLayer(location.state.tl1);
                    let sentinelLayer = L.tileLayer(location.state.tl2);

                    map1Ref.current!.addLayer(sentinelLayer);
                    map1Ref.current!.addLayer(landsatLayer);

                    map2Ref.current!.addLayer(sentinelLayer);
                    map2Ref.current!.addLayer(landsatLayer);
                }

                const coords = invertLatLgn(fetch.geometry.coordinates_[0]);
                map1Ref.current!.fitBounds(coords as LatLngBoundsExpression);
                map2Ref.current!.fitBounds(coords as LatLngBoundsExpression);
                setLoading(false)
            }
        }

        fetchData();
    }, [areaId]);

    function chamarPDF() {
        const element = document.getElementById('print');
        element?.classList.add('print');
        const opt = {
            margin: [3, 2],
            filename: 'myfile.pdf',
            image: { type: 'jpeg'},
            html2canvas: { scale: 2, useCORS: true},
            jsPDF: { unit: 'mm', format: 'a3', orientation: 'p' },
            pagebreak: { mode: 'avoid-all', after: '.pageBreak' },
        };
        html2pdf().from(element).set(opt);
        html2pdf(element, opt);
    }

    const [loading, setLoading] = useState (false);
 
    return (
        <>
            {loading && (
                <div className={styles.containerImageLoading}>
                    <div className={styles.divImageLoading}>
                        <AiOutlineLoading3Quarters className={styles.imageLoading}/>
                    </div>
                </div>
            )}
            <div className={styles.container} id="print">
                <nav className={styles.headingNav}>
                    <h1 className={styles.titleNav}>Relatório</h1>
                    <Breadcrumb head="Home" pages={["Relatório"]} />
                </nav>
                <div className={styles.containerInfo}>
                    <div className={styles.cardInfo}>
                        <p className={styles.titleInfo}>Início da detecção de desmatamento</p>
                        <p className={styles.paragraphInfo}>{date}</p>
                    </div>
                    <div className={styles.cardInfo}>
                        <p className={styles.titleInfo}>Hectares</p>
                        <p className={styles.paragraphInfo}>{hectares.toFixed(2)} hec.</p>
                    </div>
                    <div className={styles.cardInfo}>
                        <p className={styles.titleInfo}>Satélite</p>
                        <p className={styles.paragraphInfo}>Sentinel-2</p>
                    </div>
                    <div className={styles.cardInfo}>
                        <p className={styles.titleInfo}>Radar</p>
                        <p className={styles.paragraphInfo}>Sentinel-1</p>
                    </div>
                </div>
                <p className={styles.paragraphData}>*Dados aproximados</p>
                <div>
                    <p className={styles.titleMap}>Área monitorada</p>
                    <div className={styles.containerTitleMap}>
                        <p className={styles.paragraphSubtitle}>Legenda:</p>
                        <p className={styles.titleAreaGreen}>Área monitorada</p>
                    </div>
                    <Map forwardRef={ map1Ref } />
                </div>
                <div>
                    {loading && (
                        <div className={styles.containerImageLoading}>
                            <div className={styles.divImageLoading}>
                                <AiOutlineLoading3Quarters className={styles.imageLoading}/>
                            </div>
                        </div>
                    )}
                    <p className={styles.titleMapDeforested}>Área desmatada</p>
                    <div className={styles.containerTitleMap}>
                        <p className={styles.paragraphSubtitle}>Legenda:</p>
                        <p className={styles.titleAreaGreen}>Área monitorada</p>
                        <p className={styles.titleAreaRed}>Área desmatada</p>
                    </div>
                    <Map forwardRef={ map2Ref } />
                </div>
            </div>
            <div className={styles.containerButton}>
                <Button icon="pdf" variant="filled-primary" placeholder="Salvar como PDF" onClick={chamarPDF} />
            </div>
        </>
    );
}

export default Report;
