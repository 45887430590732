import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "./SwitchPlan.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    cancel: () => void;
    close: () => void;
    title: string;
    paragraph: string;
    textFirstButtom: string;
    textSecondButtom: string
}

function SwitchPlanModal(props:IProperties): JSX.Element {
    return(
        <>
            <div className={styles.cardUnsubscribeModal}>
                <Modal forwardRef={ props.reference } title={props.title}>
                    <p className={styles.paragraphCenter}>{props.paragraph}</p>
                    <div className={styles.containerButtonUnsubscribe}>
                        <div className={styles.divButtonSwitch}>
                            <Button onClick={props.close} placeholder={props.textFirstButtom} variant="empty-primary"/>
                        </div>
                        <div className={styles.divButtonSwitch}>
                            <Button onClick={props.cancel} placeholder={props.textSecondButtom} variant="filled-primary"/>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default SwitchPlanModal;