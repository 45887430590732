import THttpRequestError from "../../@types/http/THttpRequestError";
import TAddress from "../../@types/users/TAddress";
import TCoupon from "../../@types/users/TCoupon";
import TEmail from "../../@types/users/TEmail";
import THistory from "../../@types/users/THistory";
import TPhoneNumber from "../../@types/users/TPhoneNumber";
import TUser from "../../@types/users/TUser";
import API from "../API";
import URLMapping from "../URLMapping";

abstract class Users {
    public static async List() {
        return await API.Get<TUser[] | THttpRequestError>(URLMapping.Users.List);
    }

    public static async View(id: number) {
        return await API.Get<TUser | THttpRequestError>(URLMapping.Users.View + id);
    }

    public static async History(id: number) {
        return await API.Get<THistory[]>(URLMapping.Users.ListHistories + id);
    }

    public static async UpdateUser(body: { id: number, name: string, companyName: string }) {
        return await API.Patch<any>(URLMapping.Users.UpdateUser, JSON.stringify(body));
    }

    public static async UpdateAddress(body: Omit<TAddress, "createdAt" | "active">) {
        return await API.Patch<any>(URLMapping.Users.UpdateAddress, JSON.stringify(body));
    }

    public static async UpdatePhone(body: Required<Pick<TPhoneNumber, "id">> & { phoneNumber: string }) {
        return await API.Patch<any>(URLMapping.Users.UpdatePhone, JSON.stringify(body));
    }

    public static async UpdateEmail(body: Required<Pick<TEmail, "id" | "email">>) {
        return await API.Patch<any>(URLMapping.Users.UpdateEmail, JSON.stringify(body));
    }

    public static async Coupons() {
        return await API.Get<TCoupon[] | THttpRequestError>(URLMapping.Users.Coupons);
    }

    public static async UpdateCoupon(couponId: string, body: Omit<TCoupon, "id" | "user_id" | "metadata" | "createdAt" | "updatedAt" | "deletedAt" | "offer_id" | "User">) {
        return await API.Patch<object>(URLMapping.Users.UpdateCoupon(couponId), JSON.stringify(body));
    }
}

export default Users;