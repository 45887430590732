import TStatus from "../../@types/TStatus";
import styles from "./StatusTag.module.css";

type Properties = {
    status: TStatus;
}

function StatusTag(props: Properties): JSX.Element {
    return (
        <span
            className={ styles.statusTag }
            data-status={ props.status }
        >
            { props.status }
        </span>
    );
}

export default StatusTag;