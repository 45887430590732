/**
 * Checks if the credit card expiration date is valid.
 *
 * @param {string} value - The credit card expiration date.
 * @return {boolean} True if the expiration date is valid, false otherwise.
 */
function isCCExpirationValid(value: string) {
    const regex: RegExp = /^\d{2}\/\d{2}$/;
    return regex.test(value);
}

export default isCCExpirationValid;