
interface IProperties {
    click?: () => void
}

function Logo(props:IProperties): JSX.Element {
    return (
        <div onClick={props.click}>
            <svg width="130" height="60" viewBox="0 0 337 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M94.9612 17.1917C94.9612 18.4333 93.9547 19.4398 92.7131 19.4398C91.4714 19.4398 90.4649 18.4333 90.4649 17.1917C90.4649 15.95 91.4714 14.9435 92.7131 14.9435C93.9547 14.9435 94.9612 15.95 94.9612 17.1917Z" fill="#46625F"/>
                <path d="M22.3292 1.17954C-1.7099 7.44962 -4.70739 29.1819 5.60587 44.0671C10.8691 51.6636 12.7422 53.1645 18.5236 57.6587C23.7479 61.72 33.1834 66.1561 33.1834 66.1561C64.9857 81.6392 106.702 83.7426 124.937 70.3983C128.215 68.33 130.183 66.6513 130.183 65.9019C130.183 65.1526 128.684 65.1526 124.939 67.8377C106.202 81.2712 66.4844 74.8946 38.757 62.3672C38.757 62.3672 30.1584 58.2515 25.2681 54.6611C17.3051 48.8149 11.2408 43.4646 8.01141 36.1655C-3.20868 7.44962 29.1007 -4.05269 75.3665 10.6398C81.8841 12.7095 87.4673 15.6929 87.4673 14.9435C87.4673 14.1941 81.6097 10.5477 72.7826 7.35751C53.5893 0.41966 37.2583 -1.54305 22.3292 1.17954Z" fill="#46625F"/>
                <path d="M158.871 43.9249C158.871 38.6314 160.239 34.4873 162.977 31.4927C165.714 28.4829 169.518 26.978 174.388 26.978C177.307 26.978 179.689 27.4847 181.535 28.498C183.38 29.4962 184.733 30.6759 185.595 32.0371C186.125 32.8841 186.117 33.4966 185.573 33.8747L183.871 35.0545C183.312 35.4477 182.752 35.2133 182.192 34.3512C181.739 33.6706 180.884 32.8614 179.629 31.9237C178.374 30.986 176.702 30.5171 174.615 30.5171C170.91 30.5171 168.134 31.6968 166.289 34.0562C164.444 36.4005 163.521 39.6901 163.521 43.9249C163.521 48.1597 164.421 51.4569 166.221 53.8163C168.021 56.1605 170.894 57.3327 174.842 57.3327C176.808 57.3327 178.517 57.0983 179.969 56.6294C181.436 56.1454 182.465 55.4799 183.055 54.633V48.621H176.317C175.485 48.621 175.069 48.2807 175.069 47.6001V45.9213C175.069 45.2407 175.485 44.9004 176.317 44.9004H186.163C186.994 44.9004 187.41 45.2407 187.41 45.9213V56.6294C185.565 57.9755 183.72 59.019 181.875 59.7601C180.03 60.5012 177.61 60.8718 174.615 60.8718C169.624 60.8718 165.752 59.3745 163 56.3798C160.247 53.3701 158.871 49.2184 158.871 43.9249Z" fill="#46625F"/>
                <path d="M202.325 60.1912C201.569 60.1912 201.191 59.7753 201.191 58.9434V28.9064C201.191 28.0745 201.569 27.6586 202.325 27.6586H213.283C217.654 27.6586 220.815 28.4753 222.766 30.1088C224.732 31.7422 225.715 33.8974 225.715 36.5745C225.715 38.9339 224.921 40.9152 223.333 42.5183C221.76 44.1215 219.514 45.0441 216.595 45.2861V45.3315C218.138 46.4809 219.159 47.3581 219.658 47.9631C220.157 48.553 220.717 49.3546 221.337 50.3679L226.464 58.6939C227.084 59.6921 226.948 60.1912 226.056 60.1912H223.469C222.758 60.1912 222.229 59.8963 221.881 59.3064L217.162 51.2073C216.482 50.0427 215.506 48.9008 214.236 47.7816C212.965 46.6624 211.385 46.1028 209.494 46.1028H205.547V58.9434C205.547 59.7753 205.169 60.1912 204.413 60.1912H202.325ZM205.547 42.3822H212.103C215.053 42.3822 217.321 41.9965 218.909 41.2252C220.497 40.4387 221.291 38.7977 221.291 36.3022C221.291 34.3965 220.565 33.1034 219.113 32.4228C217.662 31.7271 215.688 31.3792 213.192 31.3792H205.547V42.3822Z" fill="#46625F"/>
                <path d="M241.152 60.1912C240.396 60.1912 240.018 59.7753 240.018 58.9434V28.9064C240.018 28.0745 240.396 27.6586 241.152 27.6586H261.003C261.835 27.6586 262.251 27.9989 262.251 28.6795V30.3583C262.251 31.0389 261.835 31.3792 261.003 31.3792H244.374V41.4521H259.642C260.474 41.4521 260.889 41.7924 260.889 42.473V44.1518C260.889 44.8324 260.474 45.1727 259.642 45.1727H244.374V56.4706H261.684C262.515 56.4706 262.931 56.8109 262.931 57.4915V59.1703C262.931 59.8509 262.515 60.1912 261.684 60.1912H241.152Z" fill="#46625F"/>
                <path d="M277.166 60.1912C276.41 60.1912 276.031 59.7753 276.031 58.9434V28.9064C276.031 28.0745 276.41 27.6586 277.166 27.6586H297.017C297.848 27.6586 298.264 27.9989 298.264 28.6795V30.3583C298.264 31.0389 297.848 31.3792 297.017 31.3792H280.387V41.4521H295.655C296.487 41.4521 296.903 41.7924 296.903 42.473V44.1518C296.903 44.8324 296.487 45.1727 295.655 45.1727H280.387V56.4706H297.697C298.529 56.4706 298.945 56.8109 298.945 57.4915V59.1703C298.945 59.8509 298.529 60.1912 297.697 60.1912H277.166Z" fill="#46625F"/>
                <path d="M313.179 60.1912C312.423 60.1912 312.045 59.7753 312.045 58.9434V28.9064C312.045 28.0745 312.423 27.6586 313.179 27.6586H316.083C317.142 27.6586 317.898 28.0367 318.352 28.793L332.554 52.5912H332.644C332.614 51.9862 332.599 51.3812 332.599 50.7763V28.9064C332.599 28.0745 332.977 27.6586 333.733 27.6586H335.821C336.577 27.6586 336.955 28.0745 336.955 28.9064V58.9434C336.955 59.7753 336.577 60.1912 335.821 60.1912H333.824C332.765 60.1912 332.009 59.8131 331.555 59.0569L316.446 33.8067H316.355C316.386 34.4117 316.401 35.0166 316.401 35.6216V58.9434C316.401 59.7753 316.023 60.1912 315.267 60.1912H313.179Z" fill="#46625F"/>
                <path d="M79.1536 43.6848C79.1536 38.4669 80.4996 34.3228 83.1918 31.2526C85.8839 28.1823 89.7255 26.6472 94.7166 26.6472C99.7076 26.6472 103.549 28.1823 106.241 31.2526C108.933 34.3228 110.28 38.4669 110.28 43.6848C110.28 48.9028 108.933 53.0468 106.241 56.1171C103.549 59.1873 99.7076 60.7225 94.7166 60.7225C89.7255 60.7225 85.8839 59.1873 83.1918 56.1171C80.4996 53.0468 79.1536 48.9028 79.1536 43.6848ZM86.2318 43.6848C86.2318 47.4659 86.9048 50.3623 88.2509 52.3738C89.6121 54.3702 91.7673 55.3684 94.7166 55.3684C97.6658 55.3684 99.8135 54.3702 101.16 52.3738C102.521 50.3623 103.201 47.4659 103.201 43.6848C103.201 39.9037 102.521 37.015 101.16 35.0186C99.8135 33.007 97.6658 32.0013 94.7166 32.0013C91.7673 32.0013 89.6121 33.007 88.2509 35.0186C86.9048 37.015 86.2318 39.9037 86.2318 43.6848Z" fill="url(#paint0_linear_517_2433)"/>
                <path d="M121.905 33.2263C120.771 33.2263 120.204 32.7348 120.204 31.7517V28.8932C120.204 27.9101 120.771 27.4186 121.905 27.4186H145.227C146.361 27.4186 146.928 27.9101 146.928 28.8932V31.7517C146.928 32.7348 146.361 33.2263 145.227 33.2263H136.878V58.7034C136.878 59.5352 136.5 59.9511 135.744 59.9511H131.388C130.632 59.9511 130.254 59.5352 130.254 58.7034V33.2263H121.905Z" fill="url(#paint1_linear_517_2433)"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M45.091 59.9511C44.0323 59.9511 43.503 59.384 43.503 58.2496V29.1201C43.503 27.9857 44.0323 27.4186 45.091 27.4186H53.5758C59.0206 27.4186 62.7941 28.3033 64.8964 30.0729C67.0138 31.8424 68.0725 34.3531 68.0725 37.6048C68.0725 40.8566 67.0138 43.3672 64.8964 45.1368C62.7941 46.9063 59.0206 47.7911 53.5758 47.7911H50.1274V58.2496C50.1274 59.384 49.5981 59.9511 48.5394 59.9511H45.091ZM53.5531 42.2556H50.1274V32.9541H53.5531C56.578 32.9541 58.6349 33.3171 59.7239 34.043C60.8128 34.7539 61.3573 35.9412 61.3573 37.6048C61.3573 39.2685 60.8128 40.4633 59.7239 41.1893C58.6349 41.9002 56.578 42.2556 53.5531 42.2556Z" fill="url(#paint2_linear_517_2433)"/>
                <path d="M7.01294 57.5237C6.07523 56.8128 5.84836 56.0944 6.33234 55.3684L8.07921 52.7368C8.65394 51.8747 9.49334 51.8293 10.5974 52.6007C11.4444 53.1905 12.5485 53.8031 13.9097 54.4383C15.286 55.0584 16.7304 55.3684 18.2428 55.3684C19.3015 55.3684 20.3224 55.2474 21.3055 55.0055C22.2886 54.7635 23.0372 54.2946 23.5515 53.5989C24.0657 52.9032 24.3228 52.0789 24.3228 51.126C24.3228 50.4454 24.2094 49.848 23.9825 49.3338C23.7556 48.8196 23.3851 48.3961 22.8709 48.0634C22.3566 47.7306 21.8273 47.4508 21.2828 47.224C20.9534 47.088 20.5941 46.9529 20.205 46.8188C20.1369 46.7953 20.074 46.7692 20.0102 46.7357C19.2549 46.3394 14.0729 43.5471 11.9901 40.467C10.0502 37.5982 8.82826 32.6688 8.40747 30.7613C8.29903 30.2697 8.45544 29.7578 8.85588 29.4527C10.1618 28.4579 12.0796 27.601 13.4106 27.2824C15.1801 26.859 16.9648 26.6472 18.7646 26.6472C20.5493 26.6472 22.3869 26.9195 24.2774 27.4639C26.1831 28.0084 27.6199 28.689 28.5879 29.5057C29.5407 30.3073 29.7752 31.0333 29.2912 31.6836L27.4536 34.1565C26.8335 35.0034 26.0016 35.0715 24.958 34.3607C24.2926 33.9069 23.3775 33.4003 22.213 32.8407C21.0635 32.2811 19.8611 32.0013 18.6058 32.0013C17.7134 32.0013 16.8892 32.0769 16.133 32.2281C15.3919 32.3794 14.7339 32.7423 14.1592 33.3171C13.5845 33.8918 13.2971 34.6026 13.2971 35.4496C13.2971 36.2663 13.5089 36.9318 13.9324 37.446C14.3558 37.9451 14.8247 38.3232 15.3389 38.5804C15.8531 38.8224 16.3749 39.0265 16.9043 39.1929C18.5377 39.7223 19.9972 40.1911 21.2828 40.5995C22.5684 40.9927 23.7556 41.4313 24.8446 41.9153C26.357 42.5959 27.5141 43.2765 28.3156 43.9571C29.1172 44.6377 29.7752 45.5678 30.2894 46.7475C30.8187 47.9272 31.0834 49.3338 31.0834 50.9672C31.0834 53.3871 30.3121 55.3911 28.7694 56.9792C27.2267 58.5521 25.5101 59.5655 23.6195 60.0192C21.7441 60.488 19.914 60.7225 18.1294 60.7225C15.4977 60.7225 13.3576 60.4654 11.7091 59.9511C10.0605 59.452 8.49513 58.6429 7.01294 57.5237Z" fill="url(#paint3_linear_517_2433)"/>
                <defs>
                    <linearGradient id="paint0_linear_517_2433" x1="76.5068" y1="26.6472" x2="76.5068" y2="60.7225" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#003B84"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_517_2433" x1="76.5068" y1="26.6472" x2="76.5068" y2="60.7225" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#003B84"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_517_2433" x1="76.5068" y1="26.6472" x2="76.5068" y2="60.7225" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#003B84"/>
                        <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_517_2433" x1="76.5068" y1="26.6472" x2="76.5068" y2="60.7225" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#003B84"/>
                        <stop offset="1"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export default Logo;