import Logo from "../../../components/Logo/Logo";
import React, { useEffect } from "react";
import styles from "./TermsAndConditions.module.css";
import Navigation from "../../../layouts/components/Navigation/Navigation";
import { useNavigate } from "react-router-dom";


function TermsAndConditions(): JSX.Element {

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <Navigation click={() => navigate('/')}/>
            <section className={`${ styles.sct } ${ styles.sctPolicy } ${ styles.container }`}>
                <div className={ styles.sctPolicy__content }>
                    <div className={`${ styles.sct__content } ${ styles.sctPolicy__content__info }`}>
                        <h1>Termos e Condições de Uso</h1>
                        <h2>1. Introdução</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>Seja bem vindo ao SPOTGREEN</li>
                            <li>Leia com atenção o conteúdo abaixo, pois ele se refere ao uso do nosso site.</li>
                            <li>Este documento e todo conteúdo do site regulamenta todos os direitos e obrigações de todos que acessam <b>nosso site</b>, resguardados todos os direitos previstos na legislação.</li>
                            <li>A sua inscrição <b>no site</b> implica automaticamente na leitura e aceitação tácita do presente termo.</li>
                            <li>Os termos e condições aqui apresentados estão sujeitos a alterações adicionais a qualquer momento. Este termo foi atualizado pela última vez em 11 de agosto de 2023.</li>
                        </ol>

                        <h2>2. Sobre o site</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>A Plataforma SPOTGREEN é um canal de disseminação de conhecimento altamente refinado. Nossa missão central é educar e informar, capacitando os indivíduos a compreenderem e agirem de maneira responsável em relação ao meio ambiente.</li>
                            <li>Cada fragmento de conteúdo disponibilizado é submetido a atualizações regulares, visando a manter a precisão e relevância. Contudo, é possível que algumas imagens não correspondam estritamente à realidade atual. Deve-se salientar que o SPOTGREEN não assume responsabilidade por informações desatualizadas ou imagens.</li>
                            <li>É vital compreender que o SPOTGREEN não assume responsabilidade por interrupções do serviço decorrentes de falhas na conexão com a internet, ações governamentais ou eventos alheios ao nosso controle. Qualquer utilização da plataforma deve estar em estrita conformidade com as leis e regulamentos vigentes. Quaisquer ações resultantes dessa utilização são de exclusiva responsabilidade do usuário.</li>
                            <li>Além disso, no tocante a questões de reembolso, é importante observar que o SPOTGREEN não mantém a obrigação de fornecer reembolsos relacionados a interrupções ou falhas no serviço por razões técnicas ou circunstanciais. Nesse sentido, ao utilizar nossa plataforma, você concorda com os termos e condições estabelecidos.</li>
                        </ol>

                        <h2>3. Acesso à plataforma</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>Criação de Conta SPOTGREEN Para aproveitar todas as funcionalidades, é necessário criar uma conta SPOTGREEN por meio do botão correspondente de cadastro. O nome de usuário e a senha atribuídos à sua conta são estritamente pessoais e devem ser mantidos em sigilo. Você reconhece e concorda que é o único responsável por todas as informações fornecidas durante o processo de registro.</li>
                            <li>Restrição de Cadastro Cada indivíduo está autorizado a efetuar somente um cadastro, não sendo permitido a criação de múltiplas contas utilizando o mesmo CPF.</li>
                            <li>Requisito de Maioridade Para realizar o cadastro e adquirir a assinatura em nossa plataforma, é essencial ser maior de idade, ou seja, ter 18 anos completos e capacidade legal plena. No caso de ser menor de idade (abaixo de 18 anos), a representação ou assistência de pais ou responsáveis legais é requerida. Nesse cenário, os pais ou responsáveis devem preencher o formulário de cadastro em nome do menor e assumirão total responsabilidade pelas ações do usuário menor.</li>
                            <li>Confirmação de E-mail e Acesso Após o cadastro, o primeiro login será enviado para o endereço de e-mail que você cadastrou. O acesso completo à plataforma só será concedido após a confirmação do e-mail de cadastro.</li>
                            <li>Proteção das Credenciais Você é responsável por manter a confidencialidade de todas as informações de login e credenciais associadas à sua conta. Caso suspeite de qualquer uso não autorizado, você concorda em informar imediatamente nossa equipe.</li>
                            <li>Perda de Credenciais ou Acesso Não Autorizado Caso perca suas informações de login ou suspeite que houve acesso não autorizado à sua conta, você deve notificar prontamente nossa equipe por meio dos canais de comunicação fornecidos em nosso site.</li>
                            <br />
                            <p>Essas disposições visam garantir a segurança e a conformidade de uso da plataforma SPOTGREEN, proporcionando uma experiência confiável e adequada a todas as partes envolvidas.</p>
                        </ol>

                        <h2>4. Pagamentos</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>Após a conclusão do cadastro, você terá acesso ao ambiente pós-cadastro do SPOTGREEN.</li>
                            <li>Para ter acesso à visualização de uma área selecionada para monitoramento, é necessário realizar uma assinatura paga diretamente com o SPOTGREEN.</li>
                            <li>O valor da assinatura é estabelecido pelo SPOTGREEN e será comunicado no momento da seleção de uma área. Em caso de alteração no valor, você será previamente informado.</li>
                            <li>As opções de pagamento para a aquisição da assinatura incluem cartão de crédito e PIX bancário.</li>
                            <li>O SPOTGREEN não aceitará qualquer outra forma de pagamento além das mencionadas anteriormente.</li>
                            <br />
                            <p>Estas diretrizes visam simplificar e esclarecer os processos relacionados a pagamentos e assinaturas no SPOTGREEN, proporcionando uma experiência transparente e eficiente para nossos usuários.</p>
                        </ol>

                        <h2>5. Cancelamento</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>Você tem a flexibilidade de cancelar sua assinatura a qualquer momento, seguindo estas etapas: acesse Configurações &gt; Assinatura e clique no botão "Cancelar".</li>
                            <li>O cancelamento entrará em vigor no dia subsequente ao término do período de assinatura atual. Após este período, você perderá o acesso à plataforma após 30 dias contados a partir da última data de pagamento. Vale ressaltar que, ao realizar o cancelamento, seus dados serão automaticamente excluídos. Se desejar retomar o uso da plataforma, será necessário preencher um novo cadastro.</li>
                            <li>É importante observar que, em caso de cancelamento, não será realizada restituição do valor pago pela assinatura. A política de cancelamento segue esta diretriz visando manter a clareza e consistência para todos os nossos usuários.</li>
                            <br />
                            <li>Estas orientações foram estabelecidas para proporcionar um processo de cancelamento transparente e eficaz, além de garantir uma experiência harmoniosa para nossos usuários.</li>
                        </ol>

                        <h2>6. Política de reembolso</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>É fundamental ressaltar que, em total consonância com nossos princípios e procedimentos, adotamos uma Política de Não Reembolso. Isso significa que não procedemos com a devolução de valores, seja em espécie, em forma de créditos ou mediante qualquer outro meio de restituição.</li>
                            <li>Essa política é aplicada de maneira consistente e equitativa a todos os nossos usuários, garantindo transparência e uniformidade em nossas interações. A decisão de não oferecer reembolsos é baseada em nossa dedicação contínua em manter os processos eficientes e os serviços de alta qualidade, bem como em nossa busca por proporcionar uma experiência consistente a todos os que utilizam nossa plataforma.</li>
                            <li>Sugerimos que esteja ciente dessa política ao realizar transações ou assinaturas conosco, assegurando-se de que todas as decisões sejam tomadas de forma informada e de acordo com nossos termos estabelecidos. Estamos à disposição para esclarecer quaisquer dúvidas adicionais que possam surgir em relação a esta política.</li>
                        </ol>

                        <h2>7. Propriedade Intelectual</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>Todo o conteúdo disponibilizado no SpotGreen, incluindo textos, gráficos, imagens, vídeos e software, é protegido por direitos autorais e outras leis de propriedade intelectual.</li>
                            <li>Você não está autorizado a reproduzir, modificar, criar trabalhos derivados ou explorar de qualquer forma o conteúdo do Site sem nossa autorização por escrito. </li>
                        </ol>

                        <h2>8. Vigência</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>A autorização concedida para uso da Plataforma tem duração por prazo indeterminado.</li>
                            <li>O SPOTGREEN reserva-se o direito de dar por terminada ou suspender a disponibilização da Plataforma a qualquer tempo, a seu exclusivo critério.</li>
                            <li>O SPOTGREEN se reserva no direito de modificar unilateralmente a apresentação e configuração da Plataforma, assim como as condições ora requeridas para sua utilização. </li>
                        </ol>

                        <h2>9. Dúvidas e litígios</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li>Quaisquer dúvidas a respeito deste Termo e Condições de uso deverão ser encaminhadas a equipe da Plataforma, através dos meios de comunicação disponibilizados. </li>
                            <li>Fica eleito o Foro Central da Comarca do Estado de São Paulo para dirimir quaisquer questões oriundas deste Termo de Uso desistindo as partes de qualquer outro por mais privilegiado que seja ou venha a ser.</li>
                            <li>Toda e qualquer disputa relacionada ao presente Termo de Uso será regulada pela legislação brasileira.</li>
                        </ol>

                        <br />
                        <hr />
                        <br />

                        <p className={ styles.sctPolicy__help }>
                            <b>Entre em Contato Conosco:</b>
                            <p>Email: <a href="mailto:contato@spotsat.com.br">contato@spotsat.com.br</a></p>
                            <p>Telefone: +55 11 91717-7695</p>
                            © Spotgreen 2023
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TermsAndConditions;
