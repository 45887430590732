import Logo from "../../../components/Logo/Logo";
import React, { useEffect } from "react";
import styles from "./CookiePolicy.module.css";
import Navigation from "../../../layouts/components/Navigation/Navigation";
import { useNavigate } from "react-router-dom";


function CookiePolicy(): JSX.Element {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
            <Navigation click={() => navigate('/')}/>
            <section className={`${ styles.sct } ${ styles.sctPolicy } ${ styles.container }`}>
                <div className={ styles.sctPolicy__content }>
                    <div className={`${ styles.sct__content } ${ styles.sctPolicy__content__info }`}>
                        <h1>Política de Cookies</h1>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Esta Política de Cookies tem como objetivo informar aos usuários sobre o uso de cookies e tecnologias semelhantes por parte de nossa empresa ao visitar nosso site (OU PLATAFORMA) ou utilizar nossos serviços. É importante que leia atentamente esta política para entender como utilizamos os cookies e como podemos gerenciar suas preferências.
                            </p>
                            <p>
                                Os cookies são pequenos arquivos de texto que são armazenados em seu dispositivo (computador, telefone móvel, tablet, etc.) quando você visita um site. Esses arquivos permitem que o site da web recupere suas ações e preferências durante um período de tempo, o que facilita sua experiência de navegação e melhora a funcionalidade do site.
                            </p>
                        </ol>
                        <h2>1. Como usamos os cookies?</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Os cookies desempenham um papel fundamental ao otimizar a navegação entre páginas, recordar suas preferências e aprimorar a sua experiência como usuário, dentre outras contribuições essenciais. Eles melhoram a funcionalidade e desempenho do nosso site, facilitam o processo de início de sessão e autenticação em nossas plataformas, coletam informações sobre como os usuários interagem com nosso site e serviços para aprimorá-los, além de personalizarem sua experiência ao lembrar de suas preferências e configurações. Tudo isso é feito visando proporcionar uma navegação mais eficiente, intuitiva e alinhada com suas necessidades.
                            </p>
                        </ol>
                        <h2>2. Tipo de cookies utilizados</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <li><b>Cookies Essenciais</b>: Estes cookies são indispensáveis para o correto funcionamento do site. Eles possibilitam a realização de ações como o início de sessão em sua conta ou a adição de produtos ao carrinho de compras.</li>
                            <li><b>Cookies de Desempenho</b>: Estes cookies nos fornecem insights sobre como os usuários exploram nosso site, revelando quais páginas são visitadas com maior frequência e se ocorrem erros. Tais informações são empregadas para aprimorar tanto a funcionalidade quanto o desempenho do site.</li>
                            <li><b>Cookies de Funcionalidade</b>: Estes cookies permitem que o site recupere suas preferências pessoais, como as configurações utilizadas para fazer o login no site, proporcionando recursos mais avançados e personalizados.</li>
                            <li><b>Cookies de Segurança</b>: Desempenhando um papel crucial, esses cookies garantem a segurança dos dados do usuário, protegendo-os contra possíveis ataques maliciosos de terceiros. As informações são armazenadas de forma criptografada, evitando vulnerabilidades.</li>
                            <br />
                            <p>Esta classificação de cookies nos permite oferecer uma experiência online mais segura, eficiente e adaptada às suas necessidades individuais.</p>
                        </ol>
                        <h2>3. Gestão dos cookies</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Ao utilizar nosso site web, você aceita o uso de cookies de acordo com esta Política de Cookies. No entanto, você pode controlar e gerenciar os cookies através da configuração do seu navegador. A maioria dos navegadores da web permite bloquear ou excluir cookies do seu dispositivo. Se você optar por desativar os cookies, é possível que algumas partes do nosso site não funcionem corretamente.
                            </p>
                            <p>
                                Você também pode ajustar suas preferências de cookies em qualquer momento, por meio da configuração de privacidade de nosso site, quando estiver disponível.
                            </p>
                        </ol>
                        <h2>4. Do tratamento dos cookies</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                A implementação da Política de Cookies se estende aos dispositivos nos quais os usuários expressamente autorizam o acesso aos dados, tanto anonimizados quanto pessoais. Esta autorização é obtida por meio do seu consentimento explícito, permitindo que os recursos de coleta e análise de informações sejam ativados em suas interações com nosso site.
                            </p>
                            <p>
                                Esse processo de consentimento garante que a utilização dos cookies esteja em total conformidade com as normas de privacidade e regulamentações vigentes. Dessa forma, buscamos assegurar uma experiência online confiável e personalizada, na qual os usuários têm o controle sobre a extensão e a natureza dos dados que são acessados em seus dispositivos.
                            </p>
                        </ol>
                        <h2>5. Atualizações na Política de Cookies</h2>
                        <ol className={ styles.sctPolicy__fake_list } type="a">
                            <p>
                                Reservamo-nos o direito de realizar atualizações ou modificações nesta Política de Cookies a qualquer momento. Tais alterações serão prontamente disponibilizadas nesta página, acompanhadas da data da revisão mais recente. Recomendamos que você reveja esta política regularmente para se manter informado acerca do uso de cookies.
                            </p>
                        </ol>

                        <br />
                        <hr />
                        <br />

                        <p>
                            Agradecemos por ter lido nossa Política de Cookies. Caso surjam dúvidas a respeito, não hesite em contatar a SPOTGREEN por meio dos canais de comunicação disponibilizados em nosso site.
                        </p>
                        <p className={ styles.sctPolicy__help }>
                            <b>Entre em Contato Conosco:</b>
                            <p>Email: <a href="mailto:contato@spotsat.com.br">contato@spotsat.com.br</a></p>
                            © Spotgreen 2023
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CookiePolicy;
