import Button from "../../../../components/Button/Button";
import Modal from "../../Modal";
import styles from "../Modals.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    login: () => void; 
}

function ConfirmAccessLoginModal(props:IProperties): JSX.Element { 
    return(
        <>
            <Modal forwardRef={ props.reference } title="Confirmação de e-mail">
                <p className={ `${styles.modalParagraph} ${styles.paragraphLeft} ${styles.paragraphPaddingBottom}` }>Parabéns! Seu e-mail foi confirmado com sucesso! Faça o login agora para acessar o sistema.</p>
                <Button placeholder="Login" variant="filled-primary" onClick={props.login} />
            </Modal>
        </>
    )
}

export default ConfirmAccessLoginModal;