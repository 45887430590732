import { Route, Routes } from "react-router-dom";
import PrivateRoutesMiddleware from "./PrivateRoutesMiddleware";
import ChangePassword from "../../pages/private/ChangePassword/ChangePassword";
import Checkout from "../../pages/private/Checkout/Checkout";
import Home from "../../pages/private/Home/Home";
import ChangeInfo from "../../pages/private/ChangeInfo/ChangeInfo";
import Subscription from "../../pages/private/Subscription/Subscription";
import LApp from "../../layouts/app/LApp";
import Report from "../../pages/private/Report/Report";

function PrivateRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path='/' element={ <PrivateRoutesMiddleware redirectPath="/" /> }>
                <Route element={ <LApp /> }>
                    <Route path='home' element={ <Home /> } />
                    <Route path="settings">
                        <Route path='change-password' element={ <ChangePassword /> } />
                        <Route path="change-info" element={ <ChangeInfo /> } />
                        <Route path='subscription' element={ <Subscription /> } />
                    </Route>
                    <Route path='/checkout' element={ <Checkout /> } />
                    <Route path='/report/:areaId' element={ <Report />} />
                </Route>
            </Route>
        </Routes>
    )
}

export default PrivateRoutes;