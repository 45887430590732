import TArea from "../../@types/areas/TArea";
import TAreas from "../../@types/areas/TArea";
import TAreaPreloaded from "../../@types/areas/TAreaPreloaded";
import THttpRequestError from "../../@types/http/THttpRequestError";
import API from "../API";
import URLMapping from "../URLMapping";

abstract class Areas {
	public static async List() {
		return await API.Get<TAreas[] | THttpRequestError>(
			URLMapping.Areas.List
		);
	}

	public static async ListPreloaded() {
		return await API.Get<TAreaPreloaded[] | THttpRequestError>(
			URLMapping.Areas.ListPreloaded
		);
	}

	public static async Focus() {
		return await API.Get<{ unique_area_id: number }[] | THttpRequestError>(
			URLMapping.Areas.Focus
		);
	}

	public static async Preload() {
		return await API.Get<object | THttpRequestError>(
			URLMapping.Areas.Preload
		);
	}

	public static async Monitoring(areaId: string) {
		return await API.Get<TArea | THttpRequestError>(
			URLMapping.Areas.Monitoring(areaId)
		);
	}

	public static async FreeArea(areaId: string, userId: string) {
		return await API.Post<TFreeArea | THttpRequestError>(
			URLMapping.Areas.FreeArea(areaId, userId)
		)
	}
}

export default Areas;
