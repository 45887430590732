import TBinError from "../../@types/checkout/bin/TBinError";
import TBinResponse from "../../@types/checkout/bin/TBinResponse";
import TCreateCardError from "../../@types/checkout/card/createCard/TCreateCardError";
import TCreateCardRequest from "../../@types/checkout/card/createCard/TCreateCardRequest";
import TCreateCardResponse from "../../@types/checkout/card/createCard/TCreateCardResponse";
import TCreateCardTokenError from "../../@types/checkout/card/createToken/TCreateCardTokenError";
import TCreateCardTokenRequest from "../../@types/checkout/card/createToken/TCreateCardTokenRequest";
import TCreateCardTokenResponse from "../../@types/checkout/card/createToken/TCreateCardTokenResponse";
import URLMapping from "../URLMapping";

abstract class Pagarme {
	public static async CardToken(body: TCreateCardTokenRequest) {
		const response: TCreateCardTokenResponse | TCreateCardTokenError =
			await fetch(URLMapping.Pagarme.CardToken, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			}).then((response) => response.json());

		return response;
	}

	public static async Card(cusToken: string, body: TCreateCardRequest) {
		const response: TCreateCardResponse | TCreateCardError = await fetch(
			URLMapping.Pagarme.Card(cusToken),
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Accept": "application/json",
					"Authorization": `Basic c2tfdGVzdF9EZFlCUEE1UzVOSUxQbUtn`,
				},
				body: JSON.stringify(body),
			}
		).then((response) => response.json());

		return response;
	}

	async BIN(cardBIN: string) {
		const response: TBinResponse | TBinError = await fetch(
			URLMapping.Pagarme.BIN(cardBIN),
			{
				method: "GET",
			}
		).then((response) => response.json());

		//console.log(response);
		return response;
	}
}

export default Pagarme;
