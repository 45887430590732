import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { SessionContext, SessionContextType } from "../../contexts/SessionContext/SessionContext";

interface IProperties {
    redirectPath: string;
}

function PrivateRoutesMiddleware({ redirectPath }: IProperties) {
    const { session } = useContext(SessionContext) as SessionContextType;

    if (
        session.isLogged === false ||
        session.user === undefined
    ) {
        return <Navigate to={ redirectPath } replace />
    }

    return <Outlet />
}

export default PrivateRoutesMiddleware;