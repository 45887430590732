import React, { ChangeEvent, useState } from 'react';
import styles from './Input.module.css';
import { MdHelpCenter} from "react-icons/md";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

type InputProps = {
  label: string;
  type: string;
  maxLength?: number;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?:  (event: ChangeEvent<HTMLInputElement>) => void;
  password?: boolean
  iconEyePassword?: boolean
  max?: string;
};

const Input: React.FC<InputProps> = ({ label, max, type, placeholder, value, onChange, disabled, onBlur, required, maxLength, password, name, iconEyePassword}) => {
  
  //Mostrar hover popup
  const [isHovering, setIsHovering] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseEnterHelp = () => {
      setIsHovering(true);
  };

  const handleMouseLeaveHelp = () => {
      setIsHovering(false);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.inputGroup}>
      <div className={styles.divLabel}>
        <label className={styles.textLabel} >{label}</label>
        {password === true ? (
          <MdHelpCenter className={styles.iconHelp} onMouseEnter={handleMouseEnterHelp} onMouseLeave={handleMouseLeaveHelp}/>
          ) : ('')
        }
        {isHovering && (
            <div className={styles.divPopupHover}>
                <div className={styles.popupHover}>
                  <p className={styles.modalParagraphTitle}>Digite uma senha com pelo menos:</p>
                  <p>
                    <ul>   
                        <li className={styles.modalParagraph}>1 caractere minúscula</li>
                        <li className={styles.modalParagraph}>1 caractere maiúscula</li>
                        <li className={styles.modalParagraph}>1 número</li>
                        <li className={styles.modalParagraph}>1 caractere especial</li>
                        <li className={styles.modalParagraph}>12 caracteres no mínimo</li>
                    </ul>
                  </p>
                </div>
            </div>
          )}
      </div>
      <div className={styles.containerInput}>
        <input 
          type={showPassword ? 'text' : type}
          placeholder={placeholder} 
          value={value} 
          onChange={onChange} 
          disabled={disabled}
          onBlur={onBlur}
          required={required}
          maxLength={maxLength}
          name={name}
          max={max}
        />
        <div className={styles.divEyesPassword}>
          {iconEyePassword === true && (
            <>
              {showPassword ? (
                <AiFillEye
                  className={styles.iconEye}
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <AiFillEyeInvisible
                  className={styles.iconEye}
                  onClick={togglePasswordVisibility}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Input;