import { useRef } from "react";
import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "./PasswordErrorModal.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
}

function PasswordErrorModal(props:IProperties): JSX.Element {
 
    const confirmAccess = useRef<HTMLDialogElement>(null);
    
    const modal = () => {
        confirmAccess.current?.showModal();
    }

    return(
        <>
        <div className={styles.container}>
            <Modal forwardRef={ props.reference } title="Ocorreu um erro!">
                <p className={styles.modalParagraph}>A nova senha não pode ser igual as últimas 5 anteriores</p>
                <p className={styles.modalParagraph}>Novas senhas divergentes</p>  
            </Modal>
        </div>
        </>
    )
}

export default PasswordErrorModal;