import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "../Modals.module.css";
import Input from "../../../../components/Input/Input";
import { ChangeEvent, ReactNode, useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    recoverPassword: () => void; 
    login: () => void; 
    register?: () => void; 
    email: (event: ChangeEvent<HTMLInputElement>) => void;
    password: (event: ChangeEvent<HTMLInputElement>) => void;
    message?: ReactNode;
}

function LoginModal(props:IProperties): JSX.Element {
 
    const [showPasswordRepeatPassword, setShowPasswordRepeatPassword] = useState(false);
    
    const VisibilityRepeatPassword = () => {
        setShowPasswordRepeatPassword(!showPasswordRepeatPassword);
    };

    return(
        <>
        <div className={styles.cardLoginModal}>
            <Modal forwardRef={ props.reference } title="Entrar">
                <div className={styles.containerInput}>
                    {/* <Input label={ "Email" } type={ "text" } onChange={ (e) => props.email(e) } placeholder="Insira seu email" /> */}
                    <div className={styles.inputGroup}>
                        <label className={styles.textLabel} htmlFor="confirm-new-password">Email</label>
                        <input 
                            type={'text'}
                            placeholder="Insira seu email"
                            id="repeatPassword"
                            onChange={ (e) => props.email(e) }
                        />
                    </div>
                    {/* <Input label={ "Senha" } type={ "password" } onChange={ (e) => props.password(e) } placeholder="Insira sua senha"  iconEyePassword={true}/> */}
                    <label className={styles.textLabel} htmlFor="confirm-new-password">Senha</label>
                    <div className={styles.inputGroup}>
                        <input 
                            type={showPasswordRepeatPassword ? 'text' : 'password'}
                            placeholder="**************"
                            id="repeatPassword"
                            onChange={ (e) => props.password(e) }
                        />
                        <div className={styles.divEyesPassword}>
                            {showPasswordRepeatPassword ? (
                                <AiFillEye
                                className={styles.iconEye}
                                onClick={VisibilityRepeatPassword}
                                />
                            ) : (
                                <AiFillEyeInvisible
                                className={styles.iconEye}
                                onClick={VisibilityRepeatPassword}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <p className={styles.msgError}>{props.message}</p>
                <Button placeholder="Entrar" variant="filled-primary" onClick={props.login}/>
                <p className={ `${styles.textPassword} ${styles.paragraphPaddingBottom}` }><span className={`${styles.modalUnderline} ${styles.modalParagraph}`} onClick={props.recoverPassword}>Esqueci a senha</span></p>
                <p className={ styles.modalRegister }>Não possui conta? <span className={ styles.modalUnderline } onClick={props.register}>Cadastre-se</span></p>
            </Modal>
        </div>
        </>
    )
}

export default LoginModal;