import { useRef } from "react";
import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "../Modals.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    entry: () => void; 
}

function OpenModal(props:IProperties): JSX.Element {
    
    const toCompany = () => window.location.href = "/signup/company";
    const toProducer = () => window.location.href = "/signup/producer";
    
    return(
        <>
            <Modal forwardRef={ props.reference } title="Quem você é?">
                <Button placeholder="Eu sou Pessoa Física" variant="empty-primary" onClick={ toProducer } />
                <Button placeholder="Eu sou Pessoa Jurídica" variant="empty-primary" onClick={ toCompany } />
                <p className={styles.paragraphPadding}>Já possui uma conta? <span className={ styles.modalUnderline } onClick={props.entry}>Entre</span></p>
            </Modal>
        </>
    )
}

export default OpenModal;