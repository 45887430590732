function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export default function formatDate(date: Date, inverse?: boolean) {

    if (inverse) {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate() + 1),
        ].join('-')
    }

    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('-')
};