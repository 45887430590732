function getConfig(name, defaulValue = null){
    if(window.ENV !== undefined){
        return window.ENV[name] || defaulValue;
    }

    return process.env[name] || defaulValue;
}

export function getBackendUrl(){
    // return 'https://api.spotgreen.com.br/v0/';
    return getConfig('REACT_APP_BACKEND_BASE_URL') + '/v0/';
}

export function getPagarmeBaseUrl(){
    return getConfig('REACT_APP_PAGARME_BASE_URL');
    // return 'https://api.pagar.me/core/v5/';
}

export function getPagarmeApiKey()
{
    return getConfig('REACT_APP_PAGARME_PUBLIC_KEY');
    // return 'pk_0Kj32P8HWDum2kAN';
}