import React, { useEffect, useRef, useState } from 'react';
import Navigation from '../../../../layouts/components/Navigation/Navigation';
import styles from './Company.module.css';
import zipCodeSearch from '../../../../services/apis';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import TAddress from '../../../../@types/users/TAddress_DEPRECATED';
import { maskCEP, maskCNPJ, maskPhone } from '../../../../@utils/Masks/Masks';
import { validateCEP, validateCNPJ, validateEmail, validatePassword } from '../../../../@utils/Validation/Validation';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../../layouts/components/Footer/Footer';
import TCompanySignUp from '../../../../@types/auth/TCompanySignUp';
import Auth from '../../../../services/auth/Auth.service';
import ErrorModal from '../../../../modules/Modal/components/Signup/ErrorModal';
import SuccessModal from '../../../../modules/Modal/components/Signup/SuccessModal';
import { getBackendUrl } from '../../../../config';

interface IData {
    contactName: string,
    password: string,
    companyName: string,
    address: string,
    addressNumber: string,
    complement: string,
    district: string,
    city: string,
    email: string
}
const Company = () => {

    const baseUrl: string = getBackendUrl();

    const navigate = useNavigate();

    const [info, setInfo] = useState<IData>({
        contactName: "",
        password: "",
        companyName: "",
        address: "",
        addressNumber: "",
        complement: "",
        district: "",
        city: "",
        email: "",
    })

    const [passwordValid, setPasswordValid] = useState(true);
    const [document, setDocument] = useState('');
    const [cnpjValid, setCnpjValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [contact, setContact] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [cep, setCep] = useState<TAddress>({
        bairro: "",
        localidade: "",
        logradouro: "",
        uf: "",
    });
    const [cepValid, setCepValid] = useState(true);

    console.log(contact)

    function handleDocumentChange(event:any) {
        const document = event.target.value;
        const isValid = validateCNPJ(document);
        const removeCnpjMask = document.replace(/\D/g, "");
        // console.log(isValid);
        if (isValid === true) {
            setDocument(removeCnpjMask);
            setCnpjValid(true);
        } else {
            setCnpjValid(false);
        }
    }

    function handleEmailChange(event:any) {
        const email = event.target.value;
        const validEmail = validateEmail(email);
        if (validEmail === true) {
            setInfo({...info, email: email});
            setEmailValid(true);
        } else {
            setEmailValid(false);
        }
    };

    const [numberError, setNumberError] = useState(false)

    function handleContactChange(event:any) {
        const phoneNumber = event.target.value;
        const finalNumber = phoneNumber.replace(/[^\d]/g, '');

        if (finalNumber.length >= 11) {
            setContact(phoneNumber);
            setNumberError(false);

        } else {
            setNumberError(true);
        }
    }

    function handlePasswordChange(event:any) {
        const password = event.target.value;
        const validPassword = validatePassword(password);
        if (validPassword) {
            setInfo({...info, password: password});
            setPasswordValid(true);
        } else {
            setPasswordValid(false);
        }
    }

    async function handleZipCodeSearch(event:any) {  
        const zipCode = event.target.value;
        const validZipCode = validateCEP(zipCode);

        if (validZipCode === true) {
            try {
                const response = await zipCodeSearch.get(`${zipCode}/json`);
                setCep(response.data);
                setZipCode(zipCode);
                // console.log(response.data);
                setCepValid(true);
            } catch {
                console.error(Error);
                setCepValid(false);
            }
        } else {
            setCepValid(false);
        }
    };

    const [consentChecked, setConsentChecked] = useState(false);

    function handleCheckboxChange(event:any) {
        setConsentChecked(event.target.checked);
    };

    const errorModal = useRef<HTMLDialogElement>(null);
    const successModal = useRef<HTMLDialogElement>(null);

    const ErrorModalFunc = () => {
        successModal.current?.close()
        errorModal.current?.showModal();
    }

    const SuccessModalFunc = () => {
        errorModal.current?.close();
        successModal.current?.showModal()
    }

    async function handleSubmit(event:any) {
        event.preventDefault();

        if (cep.uf !== undefined) {
            let body: TCompanySignUp = {
                name: info.contactName,
                companyName: info.companyName, 
                cnpj: document,
                phoneNumber: `+55${contact}`,
                email: info.email,
                password: info.password,
                zip_code: zipCode,
                location: cep.logradouro,
                number: +info.addressNumber,
                complement: info.complement,
                neighborhood: cep.bairro,
                city: cep.localidade,
                state: cep.uf,
                country: 'BR',
            };

            const fetchCompany = await Auth.SignUp("Company", body);
            
            if ('id' in fetchCompany) {
                //console.log(fetchCompany.id)
            }

            if ('statusCode' in fetchCompany) {
                ErrorModalFunc();
            }
            if (!('statusCode' in fetchCompany)) {
                SuccessModalFunc();  
            }

            // Fazer um fetch para o coupon
            if ('statusCode' in fetchCompany) {
                return;
            } else {
                console.log(`${baseUrl}coupon/create`)
                const result = await fetch(`${baseUrl}coupon/create`, {
                    mode: 'cors',
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        status : "pending",
                        user_id: 'id' in fetchCompany ? fetchCompany.id :  '',
                        expiration_date: "2024-08-01",
                        expire_auto: true      
                    }),
                })
                .then(response => {
                    //console.log(response)
                    return response.json();
                })
                .then(data => {
                    //console.log(data)
                    return data;
                })
                .catch(error => {
                    console.error(error);
                });
            }
        }
    }

    // Recarregar modal após fechado
	const [reloadPage, setReloadPage] = useState(false);

    const closeModal = () => {
        successModal.current?.close();
        reloadPageHandler(); 
    };

	const reloadPageHandler = () => {
	    setReloadPage(true);
	};

	useEffect(() => {
		if (reloadPage) {
			setReloadPage(false);
			window.location.reload(); // Recarrega a página
		}
	}, [reloadPage]);

  return (
    <div>
        <div className={styles.divNavigation}>
            <Navigation click={() => navigate('/')}/>
        </div>
        <main className={styles.formContainer}>
            <form className={styles.companyForm}>
                <h1 className={styles.pageTitle}>Cadastro Pessoa Jurídica</h1>
                    <h2 className={styles.loginTitle}>Informações Gerais</h2>
                    <div className={styles.row1}>
                        <div className={styles.infoTop}>
                            <span className={styles.inputItem}>
                                <Input
                                    label={'E-mail'}
                                    type={'email'}
                                    onChange={(e) => [setInfo({...info, email: e.target.value})]}
                                    onBlur={handleEmailChange}
                                />
                                {!emailValid && <span className={styles.error}>Email inválido</span>}
                            </span>
                            <span className={styles.inputItem}>
                                <Input
                                    label={'Senha'}
                                    type={'password'}
                                    onChange={(e) => [setInfo({...info, password: e.target.value})]}
                                    onBlur={handlePasswordChange}
                                    password={true}
                                    iconEyePassword={true}
                                />
                                {!passwordValid &&
                                    <div className={styles.errorPasswordContainer}>
                                        <span className={styles.error}>
                                            A senha deve conter pelo menos 12 caracteres, 
                                            1 letra maiúscula, 
                                            1 letra minúscula, 
                                            1 número e 1 caractere especial
                                        </span>
                                    </div> 
                                }
                            </span>
                            <Input
                                label={'Nome do Contato'}
                                type={'text'}
                                onChange={(e) => [setInfo({...info, contactName: e.target.value})]}
                            />
                        </div>
                        <div className={styles.infoTop}>
                            <Input
                                label={'Nome da Empresa'}
                                type={'text'}
                                onChange={(e) => [setInfo({...info, companyName: e.target.value})]}
                            />
                            <span className={styles.inputItem}>
                                <Input
                                    label={'CNPJ'}
                                    type={'document'}
                                    placeholder={'__.___.___/___-__'}
                                    value={document}
                                    onChange={(e) => setDocument(maskCNPJ(e.target.value))}
                                    onBlur={handleDocumentChange}
                                />
                                {!cnpjValid && 
                                    <span className={styles.error}>
                                        Erro: CNPJ inválido. Exemplo: 12.345.678/0001-12
                                    </span>
                                }
                            </span>
                            <span className={styles.contactInput}>
                                <Input
                                    label={'Contato'}
                                    type={'tel'}
                                    value={'+55'}
                                    disabled
                                />
                                <Input
                                    label={''}
                                    type={'tel'}
                                    placeholder={'(__)_____-____'}
                                    value={contact}
                                    onChange={(e) => setContact(maskPhone(e.target.value))}
                                    onBlur={handleContactChange}
                                />
                                {numberError &&
                                    <div className={styles.errorNumberContainer}>
                                        <span className={styles.errorNumber}>Número de telefone inválido</span>
                                    </div>
                                }
                            </span>
                        </div>
                    </div>
                    <h2 className={styles.loginTitle}>Endereço</h2>
                    <div className={styles.row2}>
                        <div className={styles.addressTop}>
                            <span className={styles.inputItem}>
                                <Input
                                    label={'CEP'}
                                    type={'text'}
                                    placeholder={'_____-___'}
                                    value={zipCode}
                                    onChange={(e) => setZipCode(maskCEP(e.target.value))}
                                    onBlur={handleZipCodeSearch}
                                />
                                {!cepValid && 
                                    <span className={styles.error}>CEP inválido.</span>
                                } 
                            </span>
                            <Input
                                label={'Cidade'}
                                type={'text'}
                                value={cep.localidade}
                                onChange={(e) => [setInfo({...info, city: e.target.value})]}
                            />
                            <Input
                                label={'Número'}
                                type={'text'}
                                onChange={(e) => [setInfo({...info, addressNumber: e.target.value})]}
                            />
                        </div>
                        <div className={styles.addressBottom}>
                            <Input
                                label={'Localização'}
                                type={'text'}
                                value={cep.logradouro}
                                onChange={(e) => [setInfo({...info, address: e.target.value})]}
                            />
                            <Input
                                label={'Bairro'}
                                type={'text'}
                                value={cep.bairro}
                                onChange={(e) => [setInfo({...info, district: e.target.value})]}
                            />
                            <Input
                                label={'Complemento'}
                                type={'text'}
                                onChange={(e) => setInfo({...info, complement: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className={styles.consentInput}>
                        <input 
                            type="checkbox" 
                            id="consent" 
                            name="consent"
                            checked={consentChecked}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="consent" className={styles.marginLeft}>
                            Ao selecionar esta opção, estou confirmando que li atentamente e concordo
                            integralmente com os <a 
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('/terms-and-conditions', '_blank');
                                }}
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className={styles.toTerms}>
                                    termos e condições
                                </a>. 
                            Além disso, certifico que minha idade é maior de 18 anos.
                        </label>
                    </div>
                    <div className={styles.buttons}>
                        <Button onClick={() => navigate("/home")} placeholder='Voltar' variant='transparent-primary'/>
                        <Button 
                            onClick={handleSubmit} 
                            placeholder='Cadastrar' 
                            variant='filled-primary'
                            enabled={!consentChecked}
                        />
                    </div>
            </form>
        </main>
        <div className={styles.cardModal}>
            <ErrorModal reference={errorModal} title="Ocorreu um erro">
                <div className={styles.divModal}>
                    <p>Ocorreu um erro nos dados inseridos. Por favor, verifique se as informações inseridas estão corretas ou se já estão cadastradas no sistema.</p>
                </div>
            </ErrorModal>
            <SuccessModal 
                reference={successModal} 
                email={info.email} 
                back={() => navigate('/home')} 
                teste={"Após confirmado o e-mail, entraremos em contato com você em até 5 dias úteis"}
                buttonClose={true} 
                onClickButtonClose={closeModal}
            />
        </div>
        <div className={styles.footer}>
            <Footer />
        </div>
    </div>
  )
}

export default Company