import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./public/PublicRoutes.routes";
import PrivateRoutes from "./private/PrivateRoutes.routes";
import AdminRoutes from "./admin/AdminRoutes.routes";

function Router(): JSX.Element {
    return (
        <BrowserRouter>
            <PublicRoutes />
            <PrivateRoutes />
            <AdminRoutes />
        </BrowserRouter>
    );
}

export default Router;