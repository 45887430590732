import { useRef } from "react";
import Modal from "../../Modal";
import Button from "../../../../components/Button/Button";
import styles from "../Modals.module.css";

interface IProperties {
    reference: React.RefObject<HTMLDialogElement>;
    login: () => void; 
}

function RecoverPasswordLoginModal (props:IProperties): JSX.Element {
       
    return(
        <>
            <Modal forwardRef={ props.reference } title="Recuperar Senha">
                <p className={ `${styles.modalParagraph} ${styles.paragraphLeft} ${styles.paragraphPaddingBottom}` }>A sua nova senha foi cadastrada com sucesso! Faça o login agora para acessar o sistema.</p>
                <Button placeholder="Login" variant="filled-primary" onClick={props.login} />
            </Modal>
        </>
    )
}

export default RecoverPasswordLoginModal;